import React from "react"
import { Col, Row } from "react-bootstrap"
import GraphReportSection from "../../api/models/GraphReportSection"
import RangeGraph from "./RangeGraph"

export interface RangeSectionProps {
  section: GraphReportSection
}

export default function RangeSection(props: RangeSectionProps) {
  const { name, details } = props.section
   
  return (<>
    <Row><h4>{name}</h4></Row>
    <Row className="mb-3">
      {details.map((detail, index) =>
        <Col xs={12} lg={8} key={index} className="mb-3">
          <RangeGraph detail={detail} />
        </Col>)}
    </Row>
  </>)
}
