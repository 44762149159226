export const RangeColor = {
  active: "#fff",
  normal: "#007b5f",
  borderline: "#ecaa00",
  out: "#ef3340",
  annotation: "#592b8a",
  lightBackground: "#eef7fd",
  line: "#326295",
  lifeAgeWeightHeight: "#dee2e6"
}

export const InverseRangeColor = {
  normal: "#fff",
  borderline: "#fff",
  out: "#fff",
  lifeAgeWeightHeight: "#326295"
}

export const defaultFontSize = "12"
export const defaultFontFamily = 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
