import React from "react"
import { Col, Row } from "react-bootstrap"
import TestHistorySection from "../../api/models/TestHistorySection"
import HistoryChart from "./HistoryChart"

export interface HistorySectionProps {
  section: TestHistorySection
}

export default function HistorySection(props: HistorySectionProps) {
  const { name, details } = props.section

  return (<>
    <Row><h4>{name}</h4></Row>
    <Row className="mb-3">
      {details.map((item, index) =>
        <Col xs={12} lg={6} key={index} className="mb-3">
          <HistoryChart key={item.testCode} history={item} />
        </Col>)}
    </Row>
  </>)
}
