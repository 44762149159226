import rootStore from '../stores/RootStore'

export interface RequestOptions {
  body?: string
  method?: string
  withCredentials?: boolean
  abortController?: AbortController
  retry?: boolean
}

export default function BuildFetchRequest(options: RequestOptions): RequestInit {
  const authStore = rootStore.authStore
  const token = authStore.authToken
  const language = authStore.language || "en"

  return {
    method: options.method ? options.method : (options.body ? "post" : "get"),
    credentials: options.withCredentials ? "include" : "omit",
    headers: new Headers({
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": token ? `Bearer ${token}` : "",
      "Accept-Language": language
    }),
    body: options?.body,
    signal: options.abortController?.signal
  }
}
