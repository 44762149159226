import { observer } from "mobx-react-lite";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TestEvent from "../../api/models/TestEvent";
import { APIError } from "../../api/ObservableFromFetch";
import { getTestEvents } from "../../api/TestEvent";
import FormatDate from "../../formatters/FormatDate";
import { useAuthStore } from "../../stores/StoreProvider";
import DataLoader from "../DataLoader";

const InitialTestEventsNumber = 3

export enum Mode {
  Interactive,
  Interpretive,
  History,
  Clinical
}

interface ReportSelectionProps {
  mode: Mode
  testEvent?: TestEvent
  onChangeReportMode: Dispatch<SetStateAction<Mode>>
  onChangeTestEvent: Dispatch<SetStateAction<TestEvent | undefined>>
}

export function ReportSelection(props: ReportSelectionProps) {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const useInterpretiveReport = authStore.domain?.useInterpretiveReport || false
  const useHistoricalReport = authStore.domain?.useHistoricalReport || false
  const [state, setState] = React.useReducer((state: DataLoader<TestEvent>, newState: Partial<DataLoader<TestEvent>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: [] })
  useEffect(() => {
    setState({ loading: true })
    const subscription = getTestEvents().subscribe({
      next: (result: TestEvent[]) => {
        setState({ loading: false, loaded: true, data: result })
        props.onChangeTestEvent(result[0])
      },
      error: (e: APIError) => {
        setState({ loading: false })
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [])

  const [menuSize, setMenuSize] = useState(InitialTestEventsNumber)
  const menuItems = state.data.slice(0, menuSize)
  const maxMenuSize = state.data.length
  const showMore: boolean = maxMenuSize > menuSize

  const clickMore = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setMenuSize(maxMenuSize)
  }

  const modeTitle = (mode: Mode) => {
    switch (mode) {
      case Mode.Interactive:
        return t("report.graphical")
      case Mode.Interpretive:
        return t("report.text")
      case Mode.History:
        return t("report.history")
      default:
        return ""
    }
  }

  const onChangeReportMode = (mode: Mode) => () => props.onChangeReportMode(mode)
  const displayHistoricalReport = useHistoricalReport && state.loaded && maxMenuSize > 1

  return (
    <div className="d-flex align-self-center">
      <div className="d-none d-sm-block">
        <Button variant={props.mode === Mode.Interactive ? "light" : "primary"} size="sm" className="my-2" onClick={onChangeReportMode(Mode.Interactive)}>{modeTitle(Mode.Interactive)}</Button>
        {useInterpretiveReport && <Button variant={props.mode === Mode.Interpretive ? "light" : "primary"} size="sm" className="m-2" onClick={onChangeReportMode(Mode.Interpretive)}>{modeTitle(Mode.Interpretive)}</Button>}
        {displayHistoricalReport && <Button variant={props.mode === Mode.History ? "light" : "primary"} size="sm" className="my-2" onClick={onChangeReportMode(Mode.History)}>{modeTitle(Mode.History)}</Button>}
      </div>
      <Dropdown className="d-block d-sm-none align-self-center my-2">
        <Dropdown.Toggle size="sm">{modeTitle(props.mode)}</Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item onClick={onChangeReportMode(Mode.Interactive)}>{modeTitle(Mode.Interactive)}</Dropdown.Item>
          {useInterpretiveReport && <Dropdown.Item onClick={onChangeReportMode(Mode.Interpretive)}>{modeTitle(Mode.Interpretive)}</Dropdown.Item>}
          {displayHistoricalReport && <Dropdown.Item onClick={onChangeReportMode(Mode.History)}>{modeTitle(Mode.History)}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
      {props.mode !== Mode.History &&
        <Dropdown className="ms-auto d-flex align-self-center justify-content-center flex-wrap">
          <Dropdown.Header className="px-0">{t("report.reportDate")}</Dropdown.Header>
          <Dropdown.Toggle variant="info">{props.testEvent ? FormatDate(props.testEvent.dateOfRegistration) : <Spinner animation="border" size="sm" />}</Dropdown.Toggle>
          <Dropdown.Menu variant="info">
            {menuItems.map(event => <Dropdown.Item key={event.testEventId} onClick={_ => props.onChangeTestEvent(event)}>{FormatDate(event.dateOfRegistration)}</Dropdown.Item>)}
            {showMore && <Dropdown.Item onClick={clickMore}>{t("more")}</Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>}
    </div>
  )
}

export default observer(ReportSelection)
