import React from "react"
import { Col, Row } from "react-bootstrap"
import LifeAgeValue from "../../api/models/LifeAgeValue"

interface ValuesSectionProps {
  values: LifeAgeValue[]
}

export default function ValuesSection({ values }: ValuesSectionProps) {
  return (
    <Row className="mb-3">
      {values.map((lifeAgeValue, index) =>
        <Col xs={12} md={6} lg={4} key={index}>
          <span className="fw-bold text-uppercase align-middle me-3">{lifeAgeValue.name}:</span>
          <span className="text-dark fs-2 align-middle">{lifeAgeValue.value.toFixed(1)}</span>
        </Col>)}
    </Row>
  )
}
