import React from "react"

interface DescriptionFormatterProps {
  description: string
}

export default function DescriptionFormatter(props: DescriptionFormatterProps) {
  const lines = props.description
    .split(/\r\n|\n\r|\n|\r/)
    .map((line, index) => <div key={index} className="mb-3">{line}</div>)

  return <>
    {lines}
  </>
}
