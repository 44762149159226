import React, { useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Subscription } from "rxjs"
import { getParticipant } from "../../api/ClientOrder"
import ParticipantRequest from "../../api/models/clientorder/ParticipantRequest"
import ParticipantResponse from "../../api/models/clientorder/ParticipantResponse"
import Program from "../../api/models/clientorder/Program"
import { APIError } from "../../api/ObservableFromFetch"
import EscapePhoneNumber from "../../formatters/EscapePhoneNumber"
import LanguageSelector from "../LanguageSelector"
import ParticipantForm from "./ParticipantForm"

interface ParticipantProps {
  program: Program
  onSubmit: (result: ParticipantResponse) => void
}

const emptyParticipant: ParticipantRequest = {
  programId: 0,
  lastName: undefined,
  dateOfBirth: undefined,
  email: undefined,
  mobile: undefined,
  referenceID: undefined,
  offerCode: undefined,
}

export default function ParticipantWraper(props: ParticipantProps) {
  const { t } = useTranslation()
  const [processing, setProcessing] = useState(false)

  const handleSubmit = (request: ParticipantRequest) => {
    request.mobile = EscapePhoneNumber(request.mobile ?? "")
    setProcessing(true)

    const subscriptions = new Subscription()
    subscriptions.add(getParticipant(request).subscribe({
      next: (result: ParticipantResponse) => {
        setProcessing(false)
        if (!result.participantId) {
          toast.error(t(result.message || "clientOrder.programInactive"))
          return
        }
        props.onSubmit(result)
      },
      error: (e: APIError) => {
        toast.error(t(e.message, { data: e.data }))
        setProcessing(false)
      }
    }))

    return () => subscriptions.unsubscribe()
  }

  return <>
    <Row>
      <Col lg={5}>
        <LanguageSelector />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>{props.program.participantInformationMsg || t("clientOrder.participantInformation")}</Col>
      <Col lg={5}>
        <ParticipantForm program={props.program} request={{ ...emptyParticipant, programId: props.program.programId! }} onSubmit={handleSubmit} />
        <Button variant="primary" type="submit" form="participantForm" disabled={processing} className="mt-3">
          {processing && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
          <span>{t("shared.button.continue")}</span>
        </Button>
      </Col>
    </Row>
  </>
}
