import React, { useEffect } from "react";
import { useState, useMemo, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"
import AccountMessage from "../api/models/registration/AccountMessage";
import BarcodeResponse from "../api/models/registration/BarcodeResponse";
import DemographicsDonorData from "../api/models/registration/DemographicsDonorData";
import { ALL_IMPLEMENTATIONS } from "../api/models/registration/Implementation";
import { getBarcode, finalize, getDonor } from "../api/Registration";
import { useQuery } from "../hooks/useQuery";
import Barcode from "./Registration/Barcode";
import Consent from "./Registration/Consent";
import Demographics from "./Registration/Demographics";
import AccountCustomMessage from "./Registration/Modals/AccountCustomMessage";
import AccountMessageModal from "./Registration/Modals/AccountMessageModal";
import ExpiredBarcode from "./Registration/Modals/ExpiredBarcode"
import Questions from "./Registration/Questions";
import Instruction from "./Registration/Instruction";
import StepIndication, { Step, StepType } from "./Registration/StepIndication";
import QuestionAnswer from "../api/models/registration/QuestionAnswer";
import MessageModal, { MessageType } from "./Registration/Modals/MessageModal";
import { useHistory } from "react-router";
import { AppRoute } from "../AppRoute";
import UnsupportedStateModal from "./Registration/Modals/UnsupportedStateModal";
import Shipping from "./Registration/Shipping";
import SaveResponse from "../api/models/registration/SaveResponse";
import Finish from "./Registration/Finish";
import { useAuthStore, useRegistrationStore } from "../stores/StoreProvider";
import PostponeResult from "../api/models/registration/PostponeResult";
import Postpone from "./Registration/Modals/Postpone";
import { observer } from "mobx-react-lite";
import PhysicalMeasurements from "../api/models/registration/PhysicalMeasurements";

const defaultSteps: Step[] = [
  { number: 1, type: StepType.Barcode, enabled: true, current: true },
  { number: 2, type: StepType.Consent, enabled: true },
  { number: 3, type: StepType.Demographics, enabled: true },
  { number: 4, type: StepType.Questions, enabled: false },
  { number: 5, type: StepType.Instruction, enabled: true },
  { number: 6, type: StepType.Shipping, enabled: true },
  { number: 7, type: StepType.Finish, enabled: true }
]

export function Registration() {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const registrationStore = useRegistrationStore()
  const query = useQuery()
  const history = useHistory()

  const fromQuery = useMemo(() => {
    return query.get("impl") || ALL_IMPLEMENTATIONS.find(implementation => query.get(implementation))
  }, [query])

  const implementation = useMemo(() => {
    if (!fromQuery) {
      return registrationStore.implementation
    }
    let result = ALL_IMPLEMENTATIONS.find((implementation => query.get(implementation) === "1"))
    if (result) {
      return result
    }
    result = ALL_IMPLEMENTATIONS.find((implementation => query.get("impl")?.toLowerCase() === implementation))
    return result
  }, [query, fromQuery, registrationStore.implementation])

  const [showExpiredBarcode, setShowExpiredBarcode] = useState(false)
  const [accountMessage, setAccountMessage] = useState<AccountMessage | undefined>(undefined)
  const [labAccountMessage, setLabAccountMessage] = useState<string | undefined>(undefined)
  const [useTwelve, setUseTwelve] = useState<boolean>(false)
  const [barcodeData, setBarcodeData] = useState<BarcodeResponse | undefined>(undefined)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [showUnconformableKit, setShowUnconformableKit] = useState<boolean>(false)
  const [showUnsupportedState, setShowUnsupportedState] = useState<boolean>(false)
  const [postpone, setPostpone] = useState<PostponeResult | undefined>(undefined)
  const answersRef = useRef<QuestionAnswer[]>([])
  const physicalMeasurementsRef = useRef<PhysicalMeasurements | undefined>()
  const barcodeScannedRef = useRef<boolean>(false)
  const originalBarcodeRef = useRef<string>("")
  const consentIdRef = useRef<number | undefined>(undefined)
  const donorRef = useRef<DemographicsDonorData>()
  const saveResponseRef = useRef<SaveResponse | undefined>(undefined)

  const [steps, setSteps] = useState<Step[]>(defaultSteps)
  const currentStep = steps.find(s => s.current)

  useEffect(() => {
    if (fromQuery) {
      registrationStore.setInitial(implementation)
    }
  }, [query, fromQuery, registrationStore, implementation, useTwelve])

  const tuneSteps = (barcodeData: BarcodeResponse) => {
    setSteps((steps) => {
      const updatedSteps = steps.map(step => { return { ...step } })
      updatedSteps.find(step => step.type === StepType.Consent)!.enabled = !barcodeData.requireSuppressConsent
      updatedSteps.find(step => step.type === StepType.Questions)!.enabled = barcodeData.requireSymptomQuestions || barcodeData.displayPhysicalMeasurements

      let number = 1
      updatedSteps.forEach(step => {
        if (step.enabled) {
          step.number = number
          number++
        }
      })
      return updatedSteps
    })
  }

  const getNextStep = () => {
    const currentIndex = steps.findIndex(step => step.current)
    if (currentIndex === -1) {
      return StepType.Barcode
    }

    const currentStep = steps[currentIndex]
    const nextStep = steps.find(step => step.number > currentStep.number && step.enabled)
    if (nextStep) {
      return nextStep.type
    }
    return StepType.Barcode
  }

  const moveToNextStep = () => {
    setSteps((steps) => {
      const currentIndex = steps.findIndex(step => step.current)
      if (currentIndex === -1) {
        return steps
      }
      const currentStep = steps[currentIndex]
      const nextStepIndex = steps.findIndex(step => step.number > currentStep.number && step.enabled)
      if (nextStepIndex === -1) {
        return steps
      }

      const updatedSteps = steps.map((step, index) => { return { ...step, done: index < nextStepIndex } })
      updatedSteps[currentIndex].current = false
      updatedSteps[nextStepIndex].current = true

      return updatedSteps
    })
  }

  const moveToPrevStep = () => {
    setSteps((steps) => {
      const currentIndex = steps.findIndex(step => step.current)
      if (currentIndex === -1) {
        return steps
      }

      let prevStepIndex = 0
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (steps[i].enabled) {
          prevStepIndex = i
          break
        }
      }

      const updatedSteps = steps.map((step, index) => { return { ...step, done: index < prevStepIndex } })
      updatedSteps[currentIndex].current = false
      updatedSteps[prevStepIndex].current = true

      return updatedSteps
    })
  }

  const handleBarcodeNext = (barcode: string, scanned: boolean) => {
    originalBarcodeRef.current = barcode
    barcodeScannedRef.current = scanned

    setSubmitting(true)

    getBarcode({ barcode, twelveDigitBarcode: useTwelve, implementation: implementation }).subscribe({
      next: (response) => {
        setSubmitting(false)
        setUseTwelve(response.twelveDigitBarcodeRequired)
        if (response.twelveDigitBarcodeRequired) {
          return
        }
        if (response.expired) {
          return setShowExpiredBarcode(true)
        }
        if (response.accountMessage) {
          return setAccountMessage(response.accountMessage)
        }
        tuneSteps(response)
        setBarcodeData(response)
        if (response.labAccountMessage) {
          return setLabAccountMessage(response.labAccountMessage)
        }

        if (authStore.authToken) {
          getAuthDonor()
          return
        }
        moveToNextStep()
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  const getAuthDonor = () => {
    setSubmitting(true)
    getDonor().subscribe({
      next: (response => {
        setSubmitting(false)
        setBarcodeData((current) => {
          if (!current) {
            return undefined
          }
          return ({ ...current, ...response })
        })

        moveToNextStep()
      }),
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  const handleCustomMessageNext = () => {
    setLabAccountMessage(undefined)
    moveToNextStep()
  }

  const handleConsentNext = (consentId: number) => {
    consentIdRef.current = consentId
    moveToNextStep()
  }

  const handleConsentBack = () => {
    setBarcodeData(undefined)
    consentIdRef.current = undefined
    moveToPrevStep()
  }

  const handleDemograhicsNext = (donor: DemographicsDonorData) => {
    donorRef.current = donor
    const nextStep = getNextStep()
    save(nextStep !== StepType.Instruction)
  }

  const handleQuestionsNext = (answers: QuestionAnswer[], physicalMeasurements?: PhysicalMeasurements) => {
    answersRef.current = answers
    physicalMeasurementsRef.current = physicalMeasurements
    save(false)
  }

  const save = (validateOnly: boolean) => {
    setSubmitting(true)

    const request = {
      answers: answersRef.current ?? [],
      measurements: physicalMeasurementsRef.current,
      barcode: barcodeData!,
      barcodeScanned: barcodeScannedRef.current,
      consentId: consentIdRef.current ?? 0,
      donor: donorRef.current!,
      implementation: implementation,
      validateOnly: validateOnly
    }

    finalize(request).subscribe({
      next: (response) => {
        setSubmitting(false)
        if (response.unconformableKit) {
          setShowUnconformableKit(true)
          return
        }
        if (response.stateUnsupported) {
          setShowUnsupportedState(true)
          return
        }
        if (response.postpone) {
          setPostpone(response.postpone)
          return
        }
        saveResponseRef.current = response
        moveToNextStep()
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  const goHome = () => {
    history.push(AppRoute.Results)
  }

  const allowKitRegistration = authStore.domain?.allowKitRegistration ?? false
  if (!allowKitRegistration) {
    return <Container>
      <Row>
        <Col sm={12} className="my-3">{t("clientOrder.programInactive")}</Col>
      </Row>
    </Container>
  }

  return (
    <Container>
      {showExpiredBarcode && <ExpiredBarcode onHide={() => setShowExpiredBarcode(false)} />}
      {accountMessage && <AccountMessageModal accountMessage={accountMessage} onHide={() => setAccountMessage(undefined)} />}
      {labAccountMessage && <AccountCustomMessage message={labAccountMessage} onNext={handleCustomMessageNext} />}
      {showUnconformableKit && <MessageModal onHide={goHome} message={{ body: t("registration.questions.basedUponYourResponses"), type: MessageType.Text }} />}
      {showUnsupportedState && <UnsupportedStateModal onHide={goHome} />}
      {postpone && <Postpone postpone={postpone} onHide={goHome} />}
      <Row>
        <Col sm={12}><StepIndication steps={steps} implemenation={implementation} /></Col>
      </Row>
      <Row>
        <Col sm={12}>
          {currentStep?.type === StepType.Barcode && <Barcode title={` ${currentStep.number}. ${t("registration.barcode.scanBarcode")} `} onNext={handleBarcodeNext} implementation={implementation} useTwelve={useTwelve} submitting={submitting} />}
          {currentStep?.type === StepType.Consent &&
            <Consent title={` ${currentStep.number}. ${t([`registration.step.${currentStep.type}_${implementation}`, `registration.step.${currentStep.type}`])} `}
              onPrev={handleConsentBack} onNext={handleConsentNext} barcode={barcodeData!} implementation={implementation}
              initialAccepted={consentIdRef.current !== undefined} />}
          {currentStep?.type === StepType.Demographics &&
            <Demographics title={` ${currentStep.number}. ${t([`registration.step.${currentStep.type}_${implementation}`, `registration.step.${currentStep.type}`])} `}
              onPrev={moveToPrevStep} onNext={handleDemograhicsNext} implementation={implementation} barcode={barcodeData!} submitting={submitting} />}
          {currentStep?.type === StepType.Questions && <Questions title={` ${currentStep.number}. ${t(`registration.step.${currentStep.type}`)} `}
            onNext={handleQuestionsNext} implementation={implementation}
            requireSymptomQuestions={barcodeData!.requireSymptomQuestions} displayPhysicalMeasurements={barcodeData!.displayPhysicalMeasurements}
            accountNumber={barcodeData?.account} testCode={barcodeData?.testCode} submitting={submitting} />}
          {currentStep?.type === StepType.Instruction && <Instruction title={` ${currentStep.number}. ${t(`registration.step.${currentStep.type}`)} `} stepNumber={currentStep.number}
            onPrev={moveToPrevStep} onNext={moveToNextStep}
            collectionType={barcodeData?.collectionType} implementation={implementation} />}
          {currentStep?.type === StepType.Shipping && <Shipping title={` ${currentStep.number}. ${t(`registration.shipping.title`)} `}
            implementation={implementation} businessType={barcodeData?.businessType} originalBarcode={originalBarcodeRef.current}
            firstName={donorRef.current?.firstName} lastName={donorRef.current?.lastName}
            testEventId={saveResponseRef.current!.testEventId!} testEventHash={saveResponseRef.current!.testEventHash!} onNext={moveToNextStep} />}
          {currentStep?.type === StepType.Finish && <Finish title={t(`registration.finish.title`)} businessType={barcodeData?.businessType}
            mobile={donorRef.current?.mobile} phoneCode={donorRef.current?.phoneCode} implementation={implementation} />}
        </Col>
      </Row>
    </Container>
  )
}

export default observer(Registration)
