export default function FormatLocalISODateString(date: Date | null) {
  if (date) {
    const offset = date.getTimezoneOffset()
    const utcDate = new Date(date.getTime() - (offset*60*1000))
    return utcDate.toISOString().substring(0, 10)
  } else {
    return ""
  }
}

export function FormatToLocalDateString(value: string | undefined) {
  if (!value) {
    return value
  }
  if (value.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return `${value}T00:00:00`
  }
  return value
}
