import Consent from "./models/Consent"
import BarcodeRequest from "./models/registration/BarcodeRequest"
import BarcodeResponse from "./models/registration/BarcodeResponse"
import ConsentRequest from "./models/registration/ConsentRequest"
import DemographicsConfigurationRequest from "./models/registration/DemographicsConfigurationRequest"
import DemographicsConfigurationResponse from "./models/registration/DemographicsConfigurationResponse"
import DonorData from "./models/registration/DonorData"
import DonorDataRequest from "./models/registration/DonorDataRequest"
import EmployeeRequest from "./models/registration/EmployeeRequest"
import EmployeeResponse from "./models/registration/EmployeeResponse"
import SaveRequest from "./models/registration/SaveRequest"
import SaveResponse from "./models/registration/SaveResponse"
import VerifyRequest from "./models/registration/VerifyRequest"
import VerifyResponse from "./models/registration/VerifyResponse"
import QuestionRequest from "./models/registration/QuestionRequest"
import QuestionResponse from "./models/registration/QuestionResponse"
import InstructionRequest from "./models/registration/InstructionRequest"
import InstructionResponse from "./models/registration/InstructionResponse"
import { ObservableFromFetch } from "./ObservableFromFetch"
import ActivateRequest from "./models/registration/ActivateRequest"
import ActivateResponse from "./models/registration/ActivateResponse"

export const getBarcode = (params: BarcodeRequest) => ObservableFromFetch<BarcodeResponse>("Registration/Barcode", JSON.stringify(params))
export const getConsent = (params: ConsentRequest) => ObservableFromFetch<Consent>("Registration/Consent", JSON.stringify(params))
export const searchEmployee = (params: EmployeeRequest) => ObservableFromFetch<EmployeeResponse>("Registration/Demographics/Search", JSON.stringify(params))
export const verifyEmployee = (params: VerifyRequest) => ObservableFromFetch<VerifyResponse>("Registration/Demographics/Verify", JSON.stringify(params))
export const getDonor = () => ObservableFromFetch<VerifyResponse>("Registration/Demographics/Donor")
export const getDonorData = (params: DonorDataRequest) => ObservableFromFetch<DonorData>("Registration/Demographics/Donor", JSON.stringify(params))
export const getConfiguration = (params: DemographicsConfigurationRequest) => ObservableFromFetch<DemographicsConfigurationResponse>("Registration/Demographics/Configuration", JSON.stringify(params))
export const getQuestions = (params: QuestionRequest) => ObservableFromFetch<QuestionResponse[]>("Registration/Questions", JSON.stringify(params))
export const getInstructions = (params: InstructionRequest) => ObservableFromFetch<InstructionResponse>("Registration/Instruction", JSON.stringify(params))
export const finalize = (params: SaveRequest) => ObservableFromFetch<SaveResponse>("Registration/Finalize", JSON.stringify(params))
export const activate = (params: ActivateRequest) => ObservableFromFetch<ActivateResponse>("Registration/Shipping", JSON.stringify(params))

