import React, { useEffect, useState } from "react"
import { ArrowUpCircle } from "react-bootstrap-icons"

export default function MoveTopButton() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const scroll = () => {
      setShow(window.pageYOffset > 100)
    }

    window.addEventListener("scroll", scroll)

    return () => window.removeEventListener("scroll", scroll)
  }, [])

  const moveTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"})
  }

  return <div className="move-top">
    <ArrowUpCircle size={24} onClick={moveTop} className={show ? "" : "d-none"}/>
  </div>
}