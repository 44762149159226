import OrderRequest from "./models/clientorder/OrderRequest"
import Participant from "./models/clientorder/Participant"
import ParticipantIdentifier from "./models/clientorder/ParticipantIdentifier"
import ParticipantRequest from "./models/clientorder/ParticipantRequest"
import ParticipantResponse from "./models/clientorder/ParticipantResponse"
import Program from "./models/clientorder/Program"
import ProgramRequest from "./models/clientorder/ProgramRequest"
import ProgramService from "./models/clientorder/ProgramService"
import ProgramServicesRequest from "./models/clientorder/ProgramServicesRequest"
import VerificationRequest from "./models/clientorder/VerificationRequest"
import VerificationResponse from "./models/clientorder/VerificationResponse"
import { ObservableFromFetch } from "./ObservableFromFetch"

export const getProgram = (params: ProgramRequest) => ObservableFromFetch<Program>("ClientOrder/Programs", JSON.stringify(params))
export const getProgramServices = (params: ProgramServicesRequest) => ObservableFromFetch<ProgramService[]>("ClientOrder/ProgramServices", JSON.stringify(params))
export const getParticipant = (params: ParticipantRequest) => ObservableFromFetch<ParticipantResponse>("ClientOrder/Participants", JSON.stringify(params))
export const createOrder = (params: OrderRequest) => ObservableFromFetch<boolean>("ClientOrder/Orders", JSON.stringify(params))
export const verifyParticipant = (params: VerificationRequest) => ObservableFromFetch<VerificationResponse>("ClientOrder/Participants/Verify", JSON.stringify(params))
export const getParticipantData = (params: ParticipantIdentifier) => ObservableFromFetch<Participant>("ClientOrder/Participants/Data", JSON.stringify(params))
