export interface ServiceWorkerStore {
  softReload: boolean
  reloadRequired: boolean
  initializationTimestamp: number
}

export const serviceWorkerStore: ServiceWorkerStore = {
  softReload: false,
  reloadRequired: false,
  initializationTimestamp: Date.now()
}

export default serviceWorkerStore
