import React from "react"
import { useTranslation } from "react-i18next"

interface StatusProps {
  hasResults: boolean
  labReceived?: string
}

export default function Status(props: StatusProps) {
  const { t } = useTranslation()

  if (props.hasResults) {
    return <></>
  }

  return <h5 className="mb-3">{props.labReceived ? t("results.sampleStatus.testing") : t("results.sampleStatus.transit")}</h5>
}
