import React from "react"
import { BLUESTONE, HEALTH_CONFIRM, Implementation } from "../../api/models/registration/Implementation"
import { DBS, SALIVA, SERUM, WELLNESS_DBS } from "../../api/models/registration/KitType"
import BluestoneInstruction from "./ShippingInstructions/BluestoneInstruction"
import DbsInstruction from "./ShippingInstructions/DbsInstruction"
import HealthConfirmInstruction from "./ShippingInstructions/HealthConfirmInstruction"
import SalivaInstruction from "./ShippingInstructions/SalivaInstruction"
import SerumInstruction from "./ShippingInstructions/SerumInstruction"
import WellnessDbsInstruction from "./ShippingInstructions/WellnessDbsInstruction"

interface ShippingInstructionProps {
  implementation?: Implementation
  businessType?: string
}

export default function ShippingInstruction(props: ShippingInstructionProps) {

  const findImplementationInstruction = () => {
    switch (props.implementation) {
      case BLUESTONE:
        if (props.businessType === SALIVA || props.businessType === WELLNESS_DBS) {
          return <BluestoneInstruction />
        }
        break
      case HEALTH_CONFIRM:
        if (props.businessType === WELLNESS_DBS) {
          return <HealthConfirmInstruction />
        }
        break
    }

    return undefined
  }

  const selectInstruction = () => {
    const implementationInstruction = findImplementationInstruction()
    if (implementationInstruction) {
      return implementationInstruction
    }

    switch (props.businessType) {
      case DBS: return <DbsInstruction />
      case SALIVA: return <SalivaInstruction />
      case SERUM: return <SerumInstruction />
      case WELLNESS_DBS: return <WellnessDbsInstruction />
      default: return undefined
    }
  }

  return <>
    {selectInstruction()}
  </>
}