import AuthStore from "./AuthStore"
import RegistrationStore from "./RegistrationStore"
import StripeStore from "./StripeStore"

export interface RootStore {
   authStore: AuthStore
   stripeStore: StripeStore
   registrationStore: RegistrationStore
}

export const createRootStore = (): RootStore => {
  return {
    authStore: new AuthStore(),
    stripeStore: new StripeStore(),
    registrationStore: new RegistrationStore()
  }
}

export default createRootStore()