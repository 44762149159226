import React, { useEffect, useReducer } from "react"
import { Container, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { getHistory } from "../../api/History"
import TestHistorySection from "../../api/models/TestHistorySection"
import { APIError } from "../../api/ObservableFromFetch"
import { useAuthStore } from "../../stores/StoreProvider"
import MoveTopButton from "../Core/MoveTopButton"
import DataLoader from "../DataLoader"
import DonorInfo from "./DonorInfo"
import Footer from "./Footer"
import HistorySection from "./HistorySection"

export default function HistoryReport() {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const showPhysicalFirst = authStore.domain?.showPhysicalFirst || false
  const [history, setHistory] = useReducer((state: DataLoader<TestHistorySection>, newState: Partial<DataLoader<TestHistorySection>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: [] })

  useEffect(() => {
    setHistory({ loading: true })

    const subscription = getHistory(showPhysicalFirst).subscribe({
      next: (result: TestHistorySection[]) => {
        setHistory({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setHistory({ loading: false })
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [showPhysicalFirst])

  return (
    <Container>
      <DonorInfo />
      {history.loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      <Row>
        {!history.loading && history.data.map(section => <HistorySection key={section.name || ""} section={section}/>)}
      </Row>
      <MoveTopButton />
      <Footer />
    </Container>)
}
