import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { AuthenticateLogin, AuthenticateVerify, authenticateTermsOfUse } from "../api/Authenticate"
import VerificationResponse from "../api/models/VerificationResponse"
import { useAuthStore } from "../stores/StoreProvider"
import { APIError } from "../api/ObservableFromFetch"
import { toast } from "react-toastify"
import { AppRoute } from "../AppRoute"
import { useHistory } from "react-router"
import FormatLocalISODateString, { FormatToLocalDateString } from "../formatters/FormatLocalISODateString"
import EscapePhoneNumber from "../formatters/EscapePhoneNumber"
import DateOfBirth from "./Login/DateOfBirth"
import PhoneNumber from "./Login/PhoneNumber"
import { observer } from "mobx-react-lite"
import { useQuery } from "../hooks/useQuery"
import Player from "./Player"
import { useServiceWorkerUpdate } from "../hooks/useServiceWorkerUpdate"

enum Page {
  Login,
  Verification,
  Redirect,
}

interface RedirectResponse {
  redirectURL: string
}

export function Login() {
  const { t } = useTranslation()
  const [phone, setPhone] = useState<string>("")
  const [birthdate, setBirthdate] = useState<Date | null>(null)
  const [country, setCountry] = useState<string>("us")
  const [phoneValid, setPhoneValid] = useState(false)
  const [birthdateValid, setBirthdateValid] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false)
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false)
  const [termsOfUseManualAccepted, setTermsOfUseManualAccepted] = useState(false)
  const [code, setCode] = useState("")
  const [codeTouched, setCodeTouched] = useState(false)
  const [page, setPage] = useState(Page.Login)
  const authStore = useAuthStore()
  const history = useHistory()
  const form = useRef<HTMLFormElement>(null)
  const verificationInputRef = useRef<HTMLInputElement>(null)
  const [redirectUrl, setRedirectUrl] = useState("")
  const autoSubmitRequired = useRef<boolean>(false)

  const handleAPIError = (e: APIError) => {
    toast.error(t(e.message, { data: e.data }))
    setSubmitting(false)
  }

  useEffect(() => {
    setTermsOfUseChecked(false)
    setTermsOfUseAccepted(false)

    if (!phoneValid) {
      return
    }
    const subscription = authenticateTermsOfUse({
      countryAbbreviation: country,
      mobileNumber: EscapePhoneNumber(phone)
    }).subscribe({
      next: (result: boolean) => {
        setTermsOfUseChecked(true)
        setTermsOfUseAccepted(result)
      },
      error: e => handleAPIError(e)
    })

    return () => subscription.unsubscribe()
  }, [phoneValid, phone, country])

  const dobString = useQuery().get("dob")
  useEffect(() => {
    const fixedValue = dobString ? FormatToLocalDateString(dobString) : null
    const dob = fixedValue ? new Date(fixedValue) : null
    if (!!dob) {
      setBirthdate(dob)
      setBirthdateValid(dob !== null)
      setTermsOfUseManualAccepted(true)
      autoSubmitRequired.current = true
    }
  }, [dobString])

  const authenticate = useServiceWorkerUpdate(() => {
    AuthenticateLogin({
      host: window.location.hostname,
      countryAbbreviation: country,
      mobileNumber: EscapePhoneNumber(phone),
      dateOfBirth: FormatLocalISODateString(birthdate),
    }).subscribe({
      next: (result: boolean | RedirectResponse) => {
        setCode("")
        setCodeTouched(false)
        setSubmitting(false)
        if (result === true) {
          setPage(Page.Verification)
          verificationInputRef.current?.focus()
        } else if ('redirectURL' in (result as RedirectResponse)) {
          setPage(Page.Redirect)
          setRedirectUrl((result as RedirectResponse).redirectURL)
        }
      },
      error: e => handleAPIError(e)
    })
  }, [country, phone, birthdate])

  useEffect(() => {
    if (!autoSubmitRequired.current) {
      return
    }
    if (!!country && !!phone && phoneValid && birthdateValid && termsOfUseManualAccepted) {
      autoSubmitRequired.current = false

      setTimeout(() => {
        setSubmitting(true)
        authenticate()
      }, 100)
    }

  }, [autoSubmitRequired, birthdate, birthdateValid, country, phone, phoneValid, authenticate, termsOfUseManualAccepted])

  const verify = () => {
    AuthenticateVerify({
      countryAbbreviation: country,
      mobileNumber: EscapePhoneNumber(phone),
      dateOfBirth: FormatLocalISODateString(birthdate),
      verificationCode: code
    }).subscribe({
      next: (result: VerificationResponse) => {
        authStore.setAuthToken(result.authToken)
        setSubmitting(false)
        history.push(AppRoute.Results)
      },
      error: e => handleAPIError(e)
    })
  }
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)
    page === Page.Login ? authenticate() : verify()
  }
  const validCode = /^\d{6}$/.test(code)

  const userAcceptedTermsOfUse = termsOfUseAccepted || termsOfUseManualAccepted

  const RedirectPage = (url: string) => {
    window.location.replace(url)
    history.push(url)
  }

  const handleBack = () => {
    setCode("")
    setCodeTouched(false)
    setPage(Page.Login)
  }

  const logingHeading = authStore.domain?.loginHeading

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center min-vh-60">
      {page === Page.Login && logingHeading && <h1 className="text-primary text-center mt-1 mb-3 h5">{logingHeading}</h1>}
      <div hidden={page !== Page.Login} className="my-1"><Player fileName={authStore.domain?.welcomeVideo} /></div>
      <Card className="mb-1">
        <Card.Body>
          <Form onSubmit={handleFormSubmit} ref={form}>
            <div hidden={page !== Page.Login}>
              <Form.Group className="mb-3" controlId="language" as={Row}>
                <Form.Label as={Col} className="fw-bold text-uppercase pt-2">{t("login.language")}</Form.Label>
                <Col><Form.Select value={authStore.language} onChange={(e) => authStore.changeLanguage(e.target.value)}>
                  <option value="en">{t("menu.language.en")}</option>
                  <option value="es">{t("menu.language.es")}</option>
                </Form.Select></Col>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <PhoneNumber disableAreaCodeValidation initialPhone={useQuery().get("phone") || ""} onChange={(valid, value, country) => {
                  setPhoneValid(valid)
                  setPhone(value)
                  setCountry(country)
                }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="dateOfBirth">
                <DateOfBirth onChange={date => {
                  setBirthdate(date)
                  setBirthdateValid(date !== null)
                }}
                  initialDate={birthdate || undefined}
                  onSubmit={() => phoneValid && birthdateValid && userAcceptedTermsOfUse && !submitting && form.current && form.current.dispatchEvent(new Event("submit"))}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formTermsOfUse" key="ddd">
                <Form.Check id="termsOfUseAccepted" type="checkbox" className="fw-bold text-uppercase" label={t("login.terms.title")} checked={userAcceptedTermsOfUse}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setTermsOfUseManualAccepted(e.target.checked)} disabled={termsOfUseAccepted} />
              </Form.Group>
            </div>
            <div hidden={page !== Page.Verification}>
              <Form.Group className="mb-3" controlId="verification">
                <p>{t("login.verification.sent")}</p>
                <Form.Label className="fw-bold text-uppercase">{t("login.verification.title")}</Form.Label>
                <Form.Control type="text" ref={verificationInputRef} value={code} minLength={6} maxLength={6}
                  onBlur={() => setCodeTouched(true)} onChange={(e) => { setCodeTouched(true); setCode(e.target.value) }}
                  autoComplete="one-time-code" className={!validCode && codeTouched ? "is-invalid" : ""} />
                {!validCode && codeTouched && <Form.Control.Feedback className="order-last" type="invalid">{t("login.verification.invalid")}</Form.Control.Feedback>}
              </Form.Group>
            </div>
            {!!redirectUrl && <div hidden={page !== Page.Redirect}>
              <Button onClick={() => RedirectPage(redirectUrl)}>{t("login.redirect")} {(new URL(redirectUrl)).hostname}</Button>
            </div>}
            <Form.Group>
              {page === Page.Verification && <Button variant="secondary" className="mx-1" onClick={handleBack}>{t("login.back")}</Button>}
              {page === Page.Login && <Button type="submit" disabled={!phoneValid || !birthdateValid || !termsOfUseChecked || !userAcceptedTermsOfUse || submitting}>{t("login.save")}</Button>}
              {page === Page.Verification && <Button type="submit" className="mx-1" disabled={!validCode || submitting}>{t("login.save")}</Button>}
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default observer(Login)
