import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import Country from "../../api/models/Country"
import BarcodeResponse from "../../api/models/registration/BarcodeResponse"
import DemographicsConfigurationResponse from "../../api/models/registration/DemographicsConfigurationResponse"
import DemographicsDonorData from "../../api/models/registration/DemographicsDonorData"
import DonorData from "../../api/models/registration/DonorData"
import EmployeeResponse from "../../api/models/registration/EmployeeResponse"
import { BLUESTONE, Implementation } from "../../api/models/registration/Implementation"
import InsuranceDonorData from "../../api/models/registration/InsuranceDonorData"
import VerifyResponse from "../../api/models/registration/VerifyResponse"
import { APIError } from "../../api/ObservableFromFetch"
import { getConfiguration, getDonorData } from "../../api/Registration"
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef"
import DonorForm from "./DonorForm"

export interface DonorProps {
  implementation?: Implementation
  barcode: BarcodeResponse
  onSubmit: (donor: DemographicsDonorData) => void
  submitting: boolean
  verifyResponse?: VerifyResponse
  employeeResponse?: EmployeeResponse
  employeeIdCancelled: boolean
  dateOfBirth?: string
  mobile?: string
  countries: Country[]
}

const emptyInsuranceDonorData: InsuranceDonorData = {
  planId: -1,
  otherPlanName: undefined,
  policyGroup: undefined,
  policyIdNumber: undefined,
  name: undefined,
  address: undefined,
  city: undefined,
  stateCodeId: 0,
  zip: undefined,
  phone: undefined,
  relationshipId: 0,
  useInsuranceOtherForPrePopulation: false
}

const emptyDonorData: DonorData = {
  firstName: undefined,
  lastName: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  stateCodeId: 0,
  zip: undefined,
  employeeId: undefined,
  countryAbbreviation: undefined,
  mobile: undefined,
  phoneCode: undefined,
  acceptTextMessages: true,
  email: undefined,
  dateOfBirth: undefined,
  genderId: 0,
  genderIdentityId: 0,
  sexualOrientationId: 0,
  raceId: 0,
  ethnicityId: 0,
  guardianRelationshipId: 0,
  guardianCountryAbbreviation: undefined,
  guardianPhone: undefined,
  guardianPhoneCode: undefined,
  guardianFirstName: undefined,
  guardianLastName: undefined
}

export default function Donor(props: DonorProps) {
  const [translationRef] = useTranslationWithRef()

  const [loading, setLoading] = useState<boolean>(!!props.verifyResponse)
  const [loadingConfiguration, setLoadingConfiguration] = useState<boolean>(true)
  const [donorData, setDonorData] = useState<DonorData | undefined>(undefined)
  const [configuration, setConfiguration] = useState<DemographicsConfigurationResponse | undefined>(undefined)

  useEffect(() => {
    if (!props.verifyResponse) {
      return
    }

    const { donorId, donorHash } = props.verifyResponse
    const { displayInsuranceSection } = props.barcode

    const subscription = getDonorData({ donorId, donorHash, displayInsuranceSection }).subscribe({
      next: (result: DonorData) => {
        const insurance = { ...emptyInsuranceDonorData, ...result.insurance }
        setDonorData({ ...emptyDonorData, ...result, insurance })
        setLoading(false)
      },
      error: (e: APIError) => {
        setLoading(false)
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [props.verifyResponse, props.barcode, translationRef])

  useEffect(() => {
    const subscription = getConfiguration({ account: props.barcode.account }).subscribe({
      next: (result) => {
        setLoadingConfiguration(false)
        setConfiguration(result)
      },
      error: (e: APIError) => {
        setLoadingConfiguration(false)
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    })

    return () => subscription.unsubscribe()
  }, [props.barcode, translationRef])

  const disableEmployeeId = props.barcode.displayEmployeeId && props.barcode.requireEmployeeId && !props.employeeIdCancelled
  const bluestone = props.implementation === BLUESTONE

  return <>
    {(loading || loadingConfiguration) && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
    {!loading && configuration &&
      <DonorForm donorData={donorData || { ...emptyDonorData, employeeId: props.employeeResponse?.employeeId, mobile: props.mobile, dateOfBirth: props.dateOfBirth, insurance: { ...emptyInsuranceDonorData } }}
        verified={!!props.verifyResponse} implementation={props.implementation} configuration={configuration}
        displayEmployeeId={props.barcode.displayEmployeeId} employeeIdLabel={props.barcode.employeeIdLabel} disableEmployeeId={disableEmployeeId && !bluestone}
        requireEmployeeId={props.barcode.employeeIdRequired || bluestone}
        needToSpecifyEmployeeType={props.barcode.needToSpecifyEmployeeType} barcodeType={props.barcode.barcodeType}
        displayInsuranceSection={props.barcode.displayInsuranceSection}
        onSubmit={props.onSubmit} countries={props.countries} />}
  </>
}