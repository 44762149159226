import React, { useMemo, useState } from "react"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import OrderRequest from "../../api/models/clientorder/OrderRequest"
import State from "../../api/models/State"
import PhoneNumber from "../Login/PhoneNumber"
import DateOfBirth from "../Login/DateOfBirth"
import { ConvertToDate } from "../../formatters/ConvertToDate"
import FormatLocalISODateString from "../../formatters/FormatLocalISODateString"
import ProgramService from "../../api/models/clientorder/ProgramService"

interface OrderFormProps {
  order: OrderRequest
  states: State[]
  programServices: ProgramService[]
  onSubmit: (order: OrderRequest) => void
}

function range(start: number, end: number) {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}

export default function OrderForm(props: OrderFormProps) {
  const { t } = useTranslation()
  const initialProgramService = props.programServices.length ? props.programServices[0] : undefined
  const [selectedProgramService, setSelectedProgramService] = useState<ProgramService | undefined>(initialProgramService)

  const validationSchema = useMemo(() => {
    const fieldRequiredMessage = t("shared.validation.fieldRequired")
    return Yup.object().shape({
      firstName: Yup.string().nullable(true).required(fieldRequiredMessage),
      lastName: Yup.string().nullable(true).required(fieldRequiredMessage),
      address1: Yup.string().nullable(true).required(fieldRequiredMessage),
      address2: Yup.string().nullable(true),
      city: Yup.string().nullable(true).required(fieldRequiredMessage),
      stateCodeId: Yup.number().test("stateCodeId", fieldRequiredMessage, (value) => (value ?? 0) > 0),
      zip: Yup.string().nullable(true).required(fieldRequiredMessage)
        .min(5, t("shared.validation.minLength", { count: 5 }))
        .matches(/^\d{5}([-]?\d{4})?$/, t("shared.validation.zip")),
      mobile: Yup.string().nullable(true).test("mobile", t("shared.validation.invalidPhoneNumber"), (value, context) => context.parent.phoneValid && !!value),
      dateOfBirth: Yup.string().nullable(true).required(fieldRequiredMessage),
      programServiceId: Yup.number().test("programServiceId", fieldRequiredMessage, (value) => (value ?? 0) > 0),
      quantity: Yup.number().test("quantity", fieldRequiredMessage, (value) => (value ?? 0) > 0)
    })
  }, [t])

  const formik = useFormik<OrderRequest & { phoneValid: boolean }>({
    initialValues: { ...props.order, programServiceId: initialProgramService?.programServiceId ?? 0, quantity: initialProgramService?.minQuantity || 0, phoneValid: true },
    validationSchema: validationSchema,
    onSubmit: (values) => { props.onSubmit(values) }
  })

  const handlePhoneChange = (valid: boolean, mobileNumber: string, country: string) => {
    formik.setFieldValue("phoneValid", valid)
    formik.setFieldTouched("phoneValid", true, true).then(
      () => {
        formik.setFieldValue("mobile", mobileNumber)
        formik.setFieldTouched("mobile", true, true)
        formik.setFieldValue("countryAbbreviation", country)
      }
    )
  }

  const handleBirthDateChange = (date: Date | null) => {
    formik.setFieldValue("dateOfBirth", FormatLocalISODateString(date)).then(() => {
      formik.setFieldTouched("dateOfBirth", true, true)
    })
  }

  const handleProgramServiceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const programServiceId = +e.target.value
    formik.setFieldValue("programServiceId", programServiceId).then(() => {
      const selected = props.programServices.find(service => service.programServiceId === programServiceId)
      setSelectedProgramService(selected)
      formik.setFieldValue("quantity", selected?.minQuantity)
    })
  }

  return <Form id="orderForm" onSubmit={formik.handleSubmit}>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.firstName")}</Form.Label>
        <Form.Control type="text" name="firstName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName || ""}
          maxLength={20}
          isInvalid={!!formik.errors.firstName && formik.touched.firstName} />
        <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.lastName")}</Form.Label>
        <Form.Control type="text" name="lastName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName || ""}
          maxLength={25}
          isInvalid={!!formik.errors.lastName && formik.touched.lastName} />
        <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.addressLine1")}</Form.Label>
        <Form.Control type="text" name="address1"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address1 || ""}
          maxLength={60}
          isInvalid={!!formik.errors.address1 && formik.touched.address1} />
        <Form.Control.Feedback type="invalid">{formik.errors.address1}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.addressLine2")}</Form.Label>
        <Form.Control type="text" name="address2"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address2 || ""}
          maxLength={60}
          isInvalid={!!formik.errors.address2 && formik.touched.address2} />
        <Form.Control.Feedback type="invalid">{formik.errors.address2}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.city")}</Form.Label>
        <Form.Control type="text" name="city"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city || ""}
          maxLength={40}
          isInvalid={!!formik.errors.city && formik.touched.city} />
        <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.state")}</Form.Label>
        <Form.Select name="stateCodeId" value={formik.values.stateCodeId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("stateCodeId", +e.target.value)}
          isInvalid={!!formik.errors.stateCodeId && formik.touched.stateCodeId} >
          <option key={0} value={0}></option>
          {props.states.map(state => <option key={state.id} value={state.id}>{state.name}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.stateCodeId}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.zip")}</Form.Label>
        <Form.Control type="text" name="zip"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zip || ""}
          maxLength={10} minLength={5}
          isInvalid={!!formik.errors.zip && formik.touched.zip} />
        <Form.Control.Feedback type="invalid">{formik.errors.zip}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <PhoneNumber initialPhone={`${props.order.phoneCode ?? ""}${props.order.mobile ?? ""}`}
          title={t("clientOrder.mobilePhoneNumber")}
          onChange={handlePhoneChange} skipFocus skipFeedback />
        {!!formik.errors.mobile && formik.touched.mobile && <Form.Control.Feedback className="d-block" type="invalid">{formik.errors.mobile}</Form.Control.Feedback>}
        <div>
          {t("clientOrder.whichMayBeUsed")}
        </div>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-2">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.testToOrder")}</Form.Label>
        <Form.Select name="programServiceId" value={formik.values.programServiceId || 0}
          onBlur={formik.handleBlur} onChange={handleProgramServiceChange}
          isInvalid={!!formik.errors.programServiceId && formik.touched.programServiceId} >
          {props.programServices.map(service => <option key={service.programServiceId} value={service.programServiceId}>{service.serviceDescription}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.programServiceId}</Form.Control.Feedback>
        {selectedProgramService && (selectedProgramService.maxQuantity === 0) &&
          <Form.Control.Feedback type="invalid" className="d-block">{selectedProgramService.message || t("clientOrder.maximumMet")}</Form.Control.Feedback>}
      </Col>
      {selectedProgramService && selectedProgramService.maxQuantity !== 0 && <Col sm={12} md={4} lg={2} className="mt-2">
        <Form.Label className="col-form-label fw-bold">{t("clientOrder.numberOfKits")}</Form.Label>
        <Form.Select name="quantity" value={formik.values.quantity || 0}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("quantity", +e.target.value)}
          isInvalid={!!formik.errors.quantity && formik.touched.quantity} >
          {range(selectedProgramService.minQuantity, selectedProgramService.maxQuantity).map(index => <option key={index} value={index}>{index}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.quantity}</Form.Control.Feedback>
      </Col>}
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <Form.Group controlId="dateOfBirth">
          <DateOfBirth title={t("clientOrder.dateOfBirth")}
            initialDate={ConvertToDate(props.order.dateOfBirth)} onChange={handleBirthDateChange} skipFeedback />
        </Form.Group>
        {!!formik.errors.dateOfBirth && formik.touched.dateOfBirth &&
          <Form.Control.Feedback className="d-block" type="invalid">{t("shared.validation.invalidDate")}</Form.Control.Feedback>}
      </Col>
      {selectedProgramService?.moreDetailLink && <Col sm={12} md={6} lg={8} className="mt-2">
        {t("clientOrder.moreDetails")}: &nbsp;<a href={selectedProgramService?.moreDetailLink} target="_blank" rel="noreferrer">{selectedProgramService?.moreDetailLink}</a>
      </Col>}
    </Row>
    <Row className="border border-primary mt-3 p-2 small">
      <Col xs={12}>{t("clientOrder.kitsOrdered")}</Col>
    </Row>
  </Form>
}
