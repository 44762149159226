import React, { useState, useRef, ChangeEvent, useMemo } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import EmployeeRequest from "../../api/models/registration/EmployeeRequest"
import EscapePhoneNumber from "../../formatters/EscapePhoneNumber"
import FormatLocalISODateString from "../../formatters/FormatLocalISODateString"
import DateOfBirth from "../Login/DateOfBirth"
import PhoneNumber from "../Login/PhoneNumber"
import { Implementation } from "../../api/models/registration/Implementation"
import Country from "../../api/models/Country"

export interface EmployeeProps {
  implementation?: Implementation
  requireEmployeeId: boolean
  onSubmit: (employeeRequest: EmployeeRequest) => void
  submitting: boolean
  countries: Country[]
}

export default function Employee(props: EmployeeProps) {
  const { t } = useTranslation()
  const { implementation, requireEmployeeId, onSubmit, submitting } = props

  const [employeeId, setEmployeeId] = useState<string>("")
  const [employeeIdValid, setEmployeeIdValid] = useState<boolean>(!requireEmployeeId)
  const [employeeIdTouched, setEmployeeIdTouched] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>("")
  const [country, setCountry] = useState<string>("us")
  const [phoneValid, setPhoneValid] = useState<boolean>(false)
  const [phoneTouched, setPhoneTouched] = useState<boolean>(false)
  const [birthdate, setBirthdate] = useState<Date | null>(null)
  const [birthdateValid, setBirthdateValid] = useState<boolean>(requireEmployeeId)
  const [birthdateTouched, setBirthdateTouched] = useState<boolean>(false)
  const [displayBirthdateError, setDisplayBirthdateError] = useState<boolean>(false)
  const [acceptTextMessages, setAcceptTextMessages] = useState<boolean>(true)

  const form = useRef<HTMLFormElement>(null)
  const validateEmployeeId = (value: string) => {
    return value !== ""
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!birthdateValid && !birthdateTouched) {
      setDisplayBirthdateError(true)
      return
    }
    const valid = employeeIdValid
    if (!employeeIdTouched) {
      setEmployeeIdTouched(true)
      const valid = validateEmployeeId(employeeId)
      setEmployeeIdValid(valid)
    }

    setPhoneTouched(true)

    if (phoneValid && birthdateValid && valid) {
      const mobileNumber = EscapePhoneNumber(phone)
      const employee = requireEmployeeId
        ? { countryAbbreviation: country, mobileNumber, employeeId, acceptTextMessages }
        : { countryAbbreviation: country, mobileNumber, dateOfBirth: FormatLocalISODateString(birthdate), acceptTextMessages }
      onSubmit(employee)
    }
  }

  const handleEmployeeIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim()
    setEmployeeId(value)
    setEmployeeIdValid(validateEmployeeId(value))
  }

  const handleEmployeeIdBlur = () => {
    setEmployeeIdTouched(true)
    setEmployeeIdValid(validateEmployeeId(employeeId))
  }

  const handlePhoneChange = (valid: boolean, value: string, country: string) => {
    setPhoneValid(valid)
    setPhone(value)
    setCountry(country)
    setPhoneTouched(true)
  }

  const handleAcceptTextMessagesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAcceptTextMessages(e.target.checked)
  }

  const handleBirthDateChange = (date: Date | null) => {
    setBirthdate(date)
    setBirthdateValid(date !== null)
    setBirthdateTouched(true)
  }

  const birthDateTitle = useMemo(() => {
    const standardName = "registration.employee.dateOfBirth"
    if (!implementation) {
      return t(standardName)
    }

    const translateTitle = `${standardName}_${implementation}`
    const implemenationTitle = t(translateTitle)
    if (implemenationTitle !== translateTitle) {
      return implemenationTitle
    }
    return t(standardName)
  }, [implementation, t])

  return <Form id="employeeForm" autoComplete="off" ref={form} onSubmit={handleFormSubmit}>
    {requireEmployeeId && <Row>
      <Col sm={6} md={5} lg={4} className="my-3">
        <Form.Label className="fw-bold text-uppercase">{t("registration.employee.enterYourEmployeeId")}</Form.Label>
        <Form.Control type="text" id="employeeId" value={employeeId} maxLength={25} onChange={handleEmployeeIdChange} onBlur={handleEmployeeIdBlur} />
        {employeeIdTouched && !employeeIdValid && <Form.Control.Feedback className="d-block" type="invalid">{t("registration.validation.fieldRequired")}</Form.Control.Feedback>}
      </Col>
    </Row>}
    <Row>
      <Col sm={6} md={5} lg={4} className={requireEmployeeId ? "mb-3" : "my-3"}>
        <Form.Group controlId="phoneNumber">
          <PhoneNumber onChange={handlePhoneChange} skipFocus={requireEmployeeId} skipFeedback countries={props.countries} />
          {phoneTouched && !phoneValid && <Form.Control.Feedback className="d-block" type="invalid">{t("login.phoneNumber.invalid")}</Form.Control.Feedback>}
        </Form.Group>
        <div>
          {t("registration.employee.whichMayBeUsed")}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group className="mb-3" controlId="acceptTextMessages" key="acceptTextMessages">
          <Form.Check id="acceptTextMessages" type="checkbox" label={t("registration.employee.acceptTextMessages")}
            checked={acceptTextMessages} onChange={handleAcceptTextMessagesChange} />
        </Form.Group>
      </Col>
    </Row>
    {!requireEmployeeId && <Row>
      <Col sm={6} md={5} lg={4} className="mb-3">
        <Form.Group controlId="dateOfBirth">
          <DateOfBirth title={birthDateTitle} onChange={handleBirthDateChange}
            onSubmit={() => phoneValid && birthdateValid && !submitting && form.current && form.current.dispatchEvent(new Event("submit"))}
          />
        </Form.Group>
        {displayBirthdateError && !birthdateValid && <Form.Control.Feedback className="d-block" type="invalid">{t("login.dateOfBirth.invalid")}</Form.Control.Feedback>}
      </Col>
    </Row>}
  </Form>
}
