import React, { useEffect, useReducer } from "react"
import { Container, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import GraphReportSection from "../../api/models/GraphReportSection"
import ResultReport from "../../api/models/ResultReport"
import TestEvent from "../../api/models/TestEvent"
import { APIError } from "../../api/ObservableFromFetch"
import { getGraphReport } from "../../api/Results"
import { useAuthStore } from "../../stores/StoreProvider"
import MoveTopButton from "../Core/MoveTopButton"
import { DatumLoader } from "../DataLoader"
import DonorInfo from "./DonorInfo"
import Footer from "./Footer"
import PhysicalSection from "./PhysicalSection"
import RangeReportDownload from "./RangeReportDownload"
import RangeSection from "./RangeSection"
import Status from "./Status"

export interface RangeReportProps {
  testEvent: TestEvent
}

export default function RangeReport(props: RangeReportProps) {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const showPhysicalFirst = authStore.domain?.showPhysicalFirst || false
  const [sectionsState, setSectionsState] = useReducer((state: DatumLoader<ResultReport<GraphReportSection>>, newState: Partial<DatumLoader<ResultReport<GraphReportSection>>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: { sections: [] } })

  useEffect(() => {
    setSectionsState({ loading: true })

    const subscription = getGraphReport(props.testEvent.testEventId, showPhysicalFirst).subscribe({
      next: (result: ResultReport<GraphReportSection>) => {
        setSectionsState({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setSectionsState({ loading: false })
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [props.testEvent.testEventId, showPhysicalFirst, t])

  const sections = sectionsState.data?.sections?.map((section) => {
    return section.physical ?
      <PhysicalSection key={section.name || ""} section={section} /> :
      <RangeSection key={section.name || ""} section={section} />
  })

  const hasResults = (sectionsState.data?.sections?.length ?? 0) > 0

  return (<>
    <Container>
      <DonorInfo />
      {sectionsState.loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      {!sectionsState.loading && <Status hasResults={hasResults} labReceived={sectionsState.data?.labReceived} />}
      {!sectionsState.loading && hasResults && <RangeReportDownload testEvent={props.testEvent} showPhysicalFirst={showPhysicalFirst} sections={sectionsState.data?.sections} />}
      {!sectionsState.loading && sections}
      <MoveTopButton />
      <Footer />
    </Container>
  </>)
}
