import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Consent from "../../../api/models/Consent"
import { saveConsent } from "../../../api/Consent"
import { APIError } from "../../../api/ObservableFromFetch"
import { toast } from "react-toastify"
import { BLUESTONE, Implementation } from "../../../api/models/registration/Implementation"
import { sanitize } from "../../../formatters/Sanitize"

export interface ConsentFormProps {
  title?: string
  consent: Consent
  implementation?: Implementation
  testEventId?: number
  onCancel: () => void
  onSubmit: () => void
  onChange?: (accepted: boolean) => void
  initialAccepted?: boolean
}

export default function ConsentForm(props: ConsentFormProps) {
  const isBluestone = props.implementation === BLUESTONE
  const { t } = useTranslation()
  const consentText = props.consent.text
  const secondaryConsentText = props.consent.secondaryText
  const initialAccepted = props.initialAccepted ?? false
  const [accepted, setAccepted] = useState(initialAccepted)
  const [acceptedBluestone, setAcceptedBluestone] = useState(!isBluestone || initialAccepted)
  const [acceptedSecondary, setAcceptedSecondary] = useState(initialAccepted)
  const acceptedAll = accepted && acceptedBluestone && (!secondaryConsentText || acceptedSecondary)

  useEffect(() => {
    props.onChange && props.onChange(accepted && acceptedBluestone && (!secondaryConsentText || acceptedSecondary))
  }, [accepted, acceptedBluestone, secondaryConsentText, acceptedSecondary])

  function handleSubmit() {
    props.testEventId ? saveConsent(props.testEventId, props.consent.consentId).subscribe({
      next: () => props.onSubmit(),
      error: (e: APIError) => toast.error(t(e.message, { data: e.data }))
    }) : props.onSubmit()
  }

  return (<>
    {props.title && <Row><Col><h2 className="text-primary">{props.title}</h2></Col></Row>}
    <Row>
      <Col>
        {!!consentText && <div dangerouslySetInnerHTML={{ __html: sanitize(consentText) }} />}
      </Col>
    </Row>
    {isBluestone && <Row className="">
      <Col>
        <Form.Check id="accept_bluestone" inline checked={acceptedBluestone} onChange={() => setAcceptedBluestone(!acceptedBluestone) }/>
        <Form.Label htmlFor="accept_bluestone" className="fw-bold text-uppercase">{t("consent.atLeast18")}</Form.Label>
      </Col>
    </Row>}
    <Row className="mb-4">
      <Col>
        <Form.Check id="accept" inline checked={accepted} onChange={() => setAccepted(!accepted) }/>
        <Form.Label htmlFor="accept" className="fw-bold text-uppercase">{t([`consent.accept_${props.implementation}`, "consent.accept"])}</Form.Label>
      </Col>
    </Row>
    {accepted && acceptedBluestone && !!secondaryConsentText && <>
    {<div dangerouslySetInnerHTML={{ __html: sanitize(secondaryConsentText) }} />}
    <Row className="mb-4">
      <Col>
        <Form.Check id="secondary" inline checked={acceptedSecondary} onChange={() => setAcceptedSecondary(!acceptedSecondary) }/>
        <Form.Label htmlFor="secondary" className="fw-bold text-uppercase">{t("consent.accept")}</Form.Label>
      </Col>
    </Row></>}
    {props.testEventId && <Row>
      <Col className="me-auto col-auto">
        <Button variant="secondary" onClick={props.onCancel}>{t("consent.back")}</Button>
      </Col>
      <Col className="col-auto">
        <Button type="submit" onClick={handleSubmit} disabled={!acceptedAll}>{t("consent.next")}</Button>
      </Col>
    </Row>}
  </>)
}
