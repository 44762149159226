import EscapePhoneNumber from "./EscapePhoneNumber"

export function formatPhone(value?: string): string {
  const phone = EscapePhoneNumber(value ?? "")

  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
  }

  return phone
}
