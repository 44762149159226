import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { Modal, Col, Row, Form, Button, Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import OrderableTest from "../../../api/models/OrderableTest"
import { APIError } from "../../../api/ObservableFromFetch"
import { confirmPaymentIntent, createPaymentIntent, PaymentIntentResponse } from "../../../api/PaymentIntent"
import FormatCurrency from "../../../formatters/FormatCurrency"
import { useStripeStore } from "../../../stores/StoreProvider"
import CheckoutForm from "./CheckoutForm"
import { CheckCircleFill } from "react-bootstrap-icons"
import { observer } from "mobx-react-lite"
import AddressForm from "./AddressForm"
import Address from "../../../api/models/Address"
import ConsentForm from "./ConsentForm"

export interface OrderTestProps {
  address?: Address
  testEventId: number
  tests: OrderableTest[]
  onClose: () => void
}

enum OrderStep {
  Cart = "cart",
  Consent = "consent",
  Address = "address",
  Checkout = "checkout",
  OrderStatus = "order",
}

export function OrderTest(props: OrderTestProps) {
  const stripeStore = useStripeStore()
  const { t } = useTranslation()
  const [step, setStep] = useState(OrderStep.Cart)
  const blankSelection = new Array(props.tests.length).fill(false)
  const [selected, setSelected] = useState<boolean[]>(blankSelection)
  const [paymentRequired, setPaymentRequired] = useState(false)
  const stripePromise = useMemo(() => stripeStore.loaded ? loadStripe(stripeStore.publishableKey) : null, [stripeStore.publishableKey, stripeStore.loaded])
  const [clientSecret, setClientSecret] = useState("")
  const appearance = {
    theme: 'stripe' as const
  }
  const options = {
    clientSecret,
    appearance,
  }

  const selectedTests = () => props.tests.filter((_, index) => selected[index])
  const selectedConsents = () => selectedTests().map(t => t.consent)
  const selectedConsent = selectedConsents().filter(c => !!c)[0]

  useEffect(() => {
    stripeStore.load()
  }, [])

  useEffect(() => {
    setSelected(new Array(props.tests.length).fill(false))
  }, [props.tests])

  useEffect(() => {
    if (step === OrderStep.Checkout) {
      const subscription = createPaymentIntent({ items: selectedTests(), testEventId: props.testEventId }).subscribe({
        next: (result: PaymentIntentResponse) => {
          if (!result.valid) {
            return
          }
          setClientSecret(result.clientSecret)
          setPaymentRequired(result.paymentRequired)
          if (!result.paymentRequired) {
            const order = confirmPaymentIntent({ items: selectedTests(), testEventId: props.testEventId }).subscribe({
              next: () => setStep(OrderStep.OrderStatus),
              error: (e: APIError) => toast.error(t(e.message, { data: e.data }))
            })
            return () => order.unsubscribe()
          }
        },
        error: (e: APIError) => toast.error(t(e.message, { data: e.data }))
      })
      return () => subscription.unsubscribe()
    }
  }, [step])

  const handleChange = (index: number) => setSelected(selected.map((item, i) => i === index ? !item : item))

  return (<>
    <Modal backdrop="static" show onHide={() => props.onClose()} dialogClassName={step === OrderStep.Consent ? "modal-xl" : ""}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Container>
        {step === OrderStep.Cart && <>
          <Row>
            <Col>{t("order.availableTests")}</Col>
          </Row>
          {props.tests && props.tests.map((test, index) => <Row key={test.testCode}>
            <Col md={1}><Form.Check id={test.testCode} checked={!!selected[index]} onChange={() => handleChange(index)} /></Col>
            <Col><Form.Label htmlFor={test.testCode}>{`${test.testCode} - ${test.description}`}</Form.Label></Col>
            <Col md={2}>{FormatCurrency(test.price)}</Col>
          </Row>)}
          <Row className="align-items-center">
            <Col className="me-auto col-auto">
              {t("order.total")}: {FormatCurrency(selectedTests().reduce((acc, item) => acc + item.price, 0))}
            </Col>
            <Col className="col-auto p-2">
              <Button disabled={selected.every(i => i === false)} onClick={() => setStep(selectedConsent ? OrderStep.Consent : OrderStep.Address)}>{t("order.purchase")}</Button>
            </Col>
          </Row>
        </>}
        {step === OrderStep.Consent && selectedConsent && <ConsentForm consent={selectedConsent} testEventId={props.testEventId} onCancel={() => { setSelected(blankSelection); setStep(OrderStep.Cart) }} onSubmit={() => setStep(OrderStep.Address)} />}
        {step === OrderStep.Address && <AddressForm address={props.address} onCancel={() => setStep(OrderStep.Cart)} testEventId={props.testEventId} onSubmit={() => setStep(OrderStep.Checkout)} />}
        {step === OrderStep.Checkout && <>
          {paymentRequired && clientSecret && stripePromise && <Elements options={options} stripe={stripePromise}>
            <CheckoutForm payment={paymentRequired} testEventId={props.testEventId} items={selectedTests()} onCancel={() => props.onClose()} onComplete={() => setStep(OrderStep.OrderStatus)} />
          </Elements>}
        </>}
        {step === OrderStep.OrderStatus && <>
          <Row className="align-items-center">
            <Col className="col-auto"><CheckCircleFill size={48} className="text-success" /></Col>
            <Col className="text-success">{t("order.success")}</Col>
          </Row>
          <Row className="mt-2">
            <Col></Col>
            <Col className="col-auto">
              <Button onClick={props.onClose}>{t("order.close")}</Button>
            </Col>
          </Row>
        </>}
        </Container>
      </Modal.Body>
    </Modal>
  </>)
}

export default observer(OrderTest)
