export default function FormatDate(date: Date | string | null | undefined, delimiter: string = "/"): string {
  if (date) {
    if (typeof date === "string") {
      if (!isNaN(Date.parse(date))) {
        date = new Date(date)
      }
    }
    if (date instanceof Date) {
      return [
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + (date.getDate())).slice(-2),
        date.getFullYear(),
      ].join(delimiter)
    }
  }
  return ""
}

export function FormatDateTime(date: Date | string | null | undefined, delimiter: string = "/"): string {
  if (date) {
    if (typeof date === "string") {
      if (!isNaN(Date.parse(date))) {
        date = new Date(date)
      }
    }
    if (date instanceof Date) {
      return [
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + (date.getDate())).slice(-2),
        date.getFullYear(),
      ].join(delimiter) + " " +
        ("0" + date.getUTCHours()).slice(-2) + ":" +
        ("0" + date.getUTCMinutes()).slice(-2) + ":" +
        ("0" + date.getUTCSeconds()).slice(-2)
    }
  }
  return ""
}
