import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { ArrowCounterclockwise } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { RangeColor } from "../Results/ChartStyles"
import AgeChart from "./AgeChart"

export interface AgeGraphProps {
  age: number
  lifeAge: number
  loading: boolean
  onReset: () => void
}

export default function AgeGraph(props: AgeGraphProps) {
  const { t } = useTranslation()

  const lifeAgeColor = props.lifeAge - props.age > 0 ? RangeColor.out : RangeColor.normal
  return (<Container className="p-0">
    <Row>
      <Col xs={6} className="p-0">
        <AgeChart age={props.age} title={t("lifeAge.yourAge")} color={RangeColor.line} loading={false} />
      </Col>
      <Col xs={6} className="p-0 position-relative">
        <AgeChart age={props.lifeAge} title={t("lifeAge.lifeAge")} color={lifeAgeColor} loading={props.loading} />
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="text-center">
        <Button variant="secondary" className="m-1" onClick={props.onReset}><ArrowCounterclockwise />{t("lifeAge.reset")}</Button>
      </Col>
    </Row>
  </Container>)
}
