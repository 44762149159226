import React, { FormEvent, useEffect, useState } from "react"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import OrderableTest from "../../../api/models/OrderableTest"
import { confirmPaymentIntent } from "../../../api/PaymentIntent"
import { APIError } from "../../../api/ObservableFromFetch"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import FormatCurrency from "../../../formatters/FormatCurrency"

export interface CheckoutFormProps {
  testEventId: number
  items: OrderableTest[]
  payment?: boolean
  onCancel?: () => void
  onComplete?: () => void
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret")

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setMessage("")

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setLoading(true)

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
      },
    })

    if (paymentIntent?.status === "succeeded") {
      setComplete(true)
      confirmPaymentIntent({ items: props.items, testEventId: props.testEventId, paymentIntentId: paymentIntent.id }).subscribe({
        next: () => props.onComplete && props.onComplete(),
        error: (e: APIError) => toast.error(t(e.message, { data: e.data }))
      })
    }

    if (error) {
      setMessage(error.message)
    }

    setLoading(false)
  }

  const amount = props.items.reduce((acc, item) => acc + item.price, 0)

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Col>{t("order.total")}:</Col>
        <Col className="col-auto">{FormatCurrency(amount)}</Col>
      </Row>
      <Row>
        <Col>
          {props.payment && <PaymentElement id="payment-element" />}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="me-auto col-auto">
          {!complete && <Button variant="secondary" onClick={props.onCancel}>{t("order.cancel")}</Button>}
        </Col>
        <Col className="col-auto">
          {!complete && <Button type="submit" disabled={loading || !stripe || !elements}>
            {t("order.purchase")}
            {loading && <Spinner animation="border" size="sm" className="mx-1" />}
          </Button>}
        </Col>
      </Row>
      {message && <Row className="mt-2"><Col className="col-auto"><div className="text-danger">{message}</div></Col></Row>}
    </Form>
  )
}
