import React, { useState, ChangeEvent, useMemo } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BLUESTONE } from "../../api/models/registration/Implementation"
import { SALIVA, SERUM, WELLNESS, WELLNESS_DBS } from "../../api/models/registration/KitType"
import { sanitize } from "../../formatters/Sanitize"
import { ShippingProps } from "./Shipping"
import ShippingInstruction from "./ShippingInstruction"

interface ActivationProps extends ShippingProps {
  onNext: () => void
  submitting: boolean
}

export default function Activation(props: ActivationProps) {
  const { t } = useTranslation()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const [written, setWritten] = useState<boolean>(false)

  const handleConfirmedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked)
  }

  const handleWrittenChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWritten(e.target.checked)
  }

  const showKansasNote = ![SERUM, WELLNESS_DBS].includes(props.businessType ?? "") && props.implementation !== BLUESTONE
  const note = useMemo(() => {
    const params = { barcode: props.originalBarcode, firstName: props.firstName, lastName: props.lastName, collectionDevice: t("registration.shipping.collectionDevice") }
    return props.businessType === SERUM
      ? t("registration.shipping.noteUnlessTheLabelSerum", params)
      : t("registration.shipping.noteUnlessTheLabel", params)
  }, [props.businessType, props.originalBarcode, props.firstName, props.lastName, t])

  const covid = ![WELLNESS, WELLNESS_DBS].includes(props.businessType ?? "")

  const confirmedLabel = useMemo(() => {
    if (props.businessType === SERUM) {
      return t("registration.shipping.appliedTheLabelSerum", { barcode: props.originalBarcode })
    }

    if (props.businessType === WELLNESS_DBS) {
      return t("registration.shipping.appliedTheLabelWDBS", { barcode: props.originalBarcode })
    }

    return t("registration.shipping.appliedTheLabel", { barcode: props.originalBarcode, collectionDevice: t("registration.shipping.collectionDevice") })
  }, [props.businessType, props.originalBarcode, t])

  const writtenLabel = useMemo(() => {
    return t("registration.shipping.writtenTheName", { firstName: props.firstName, lastName: props.lastName, collectionDevice: t("registration.shipping.collectionDevice") })
  }, [props.firstName, props.lastName, t])

  return <>
    <Row key="header">
      <Col><h2 className="text-primary">{props.title}</h2></Col>
    </Row>
    <ShippingInstruction implementation={props.implementation} businessType={props.businessType} />
    {showKansasNote && <Row>
      <Col xs={12} className="fw-bold mt-3">
        <div dangerouslySetInnerHTML={{ __html: sanitize(t("registration.shipping.ifYouLiveInTheKansas")) }} />
      </Col>
    </Row>}
    <div className="border border-primary p-3 rounded-3 my-3">
      <Row>
        <Col xs={12} className="mb-1">{t("registration.shipping.pleaseMakeSureYouCompleted")}</Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group className="mb-1 fw-bold" controlId="confirmed" key="confirmed">
            <Form.Check id="confirmed" type="checkbox" label={confirmedLabel}
              checked={confirmed} onChange={handleConfirmedChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group className="mb-3 fw-bold" controlId="written" key="written">
            <Form.Check id="written" type="checkbox" label={writtenLabel}
              checked={written} onChange={handleWrittenChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button variant="primary" type="button" disabled={!confirmed || !written || props.submitting} onClick={() => props.onNext()}>
            {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
            {t("registration.button.activate")}
          </Button>
        </Col>
      </Row>
    </div>
    <Row>
      <Col xs={12} className="fw-bold mb-3">{note}</Col>
    </Row>
    {props.businessType === SALIVA && <Row>
      <Col xs={12} className="mb-3 fst-italic">{t("registration.shipping.thisTestHasNotBeenFDAClearedOrApproved")}</Col>
    </Row>}
    {covid && <Row>
      <Col xs={12} className="mb-3 fst-italic">{t("registration.shipping.thePerformanceOfThisTest")}</Col>
    </Row>}
  </>
}