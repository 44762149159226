import OrderableTest from "./models/OrderableTest"
import { ObservableFromFetch } from "./ObservableFromFetch"

export interface PaymentIntentRequest {
  items: OrderableTest[]
  testEventId: number
  paymentIntentId?: string
}

export interface PaymentIntentResponse {
  clientSecret: string
  completed: boolean
  paymentRequired: boolean
  valid: boolean
}

export const getPublishableKey = () => ObservableFromFetch<string>("PaymentIntent")
export const createPaymentIntent = (request: PaymentIntentRequest) => ObservableFromFetch<PaymentIntentResponse>("PaymentIntent", JSON.stringify(request))
export const confirmPaymentIntent = (request: PaymentIntentRequest) => ObservableFromFetch<void>("PaymentIntent/Order", JSON.stringify(request))
