import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAuthStore } from "../stores/StoreProvider"

export default function LanguageSelector() {
  const authStore = useAuthStore()
  const [t] = useTranslation()

  return <Form.Group className="mb-3" controlId="language" as={Row}>
    <Form.Label as={Col} className="fw-bold text-uppercase pt-2">{t("login.language")}</Form.Label>
    <Col><Form.Select value={authStore.language} onChange={(e) => authStore.changeLanguage(e.target.value)}>
      <option value="en">{t("menu.language.en")}</option>
      <option value="es">{t("menu.language.es")}</option>
    </Form.Select></Col>
  </Form.Group>
}
