import React from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import ReportRange from "../../api/models/RangeDetail"
import RangeChart from "../Results/RangeChart"

interface RangeSliderProps {
  testCode: string,
  name: string
  numericResult?: number
  range: ReportRange
  selectedResult?: number
  onChange: (value: number) => void
}

function zeroRange(range: ReportRange) {
  return [range.low, range.borderlineLow, range.normal, range.borderlineHigh, range.high].every(value => value === 0)
}

export default function RangeSlider(props: RangeSliderProps) {
  const step = props.range.scaleMax > 10 ? 1 : 0.1
  const value = (props.selectedResult !== undefined) ? props.selectedResult : props.numericResult
  const lifeAgeWeightHeight = props.testCode === "T241" || (props.testCode === "T242" && zeroRange(props.range))

  return (<Container className="p-0">
    <Row>
      <Col xs={12} className="fw-bold text-uppercase">{props.name}</Col>
      <Col xs={12}>
        <RangeChart range={props.range} numericResult={props.numericResult} secondResult={props.selectedResult} lifeAge lifeAgeWeightHeight={lifeAgeWeightHeight} />
      </Col>
      <Col xs={12}>
        <Form.Control type="range" min={props.range.scaleMin} max={props.range.scaleMax} className="form-range shadow-none bg-transparent border-0 px-1 mt-n4"
          value={value} step={step}
          onChange={(e) => props.onChange(+e.target.value)} />
      </Col>
    </Row>
  </Container>)
}
