import { observer } from "mobx-react-lite"
import * as React from "react"
import FormatName from "../../formatters/FormatName"
import { useAuthStore } from "../../stores/StoreProvider"

export function DonorInfo() {
  const authStore = useAuthStore()

  return (<>
    {authStore.donorInfo && <h3 className="text-uppercase">{FormatName(authStore.donorInfo)}</h3>}
  </>)
}

export default observer(DonorInfo)
