import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Address from "../../api/models/Address"
import Orderable from "../../api/models/Orderable"
import OrderableTest from "../../api/models/OrderableTest"
import { getOrderableTests } from "../../api/OrderableTests"
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef"
import OrderTest from "./Modals/OrderTest"

export interface OrderTestCheckerProps {
  testEventId: number
}

export default function OrderTestChecker(props: OrderTestCheckerProps) {
  const [translationRef] = useTranslationWithRef()
  const [showOrderable, setShowOrderable] = useState(false)
  const [orderableTests, setOrderableTests] = useState<OrderableTest[]>([])
  const [address, setAddress] = useState<Address>()

  useEffect(() => {
    var timeout: NodeJS.Timeout
    const subscription = getOrderableTests(props.testEventId).subscribe({
      next: (result: Orderable) => {
        setOrderableTests(result.items || [])
        setAddress(result.address)
        if (result.items.length) {
          timeout = setTimeout(() => {
            if (document.activeElement instanceof HTMLElement) {
              (document.activeElement as HTMLElement).blur()
            }
            setShowOrderable(true)
          }, result.delay * 1000)
        }
      },
      error: e => toast.error(translationRef.current(e.message, { data: e.data }))
    })
    return () => {
      subscription.unsubscribe()
      clearTimeout(timeout)
    }
  }, [props.testEventId, translationRef])

  return <>
    {showOrderable &&
      <OrderTest testEventId={props.testEventId} tests={orderableTests} address={address} onClose={() => setShowOrderable(false)} />}
  </>
}
