import React from "react"
import { Modal } from "react-bootstrap"
import DescriptionFormatter from "./DescriptionFormatter"

export interface ReportInfoProps {
  show: boolean
  title: string
  description: string
  handleClose: () => void
}

export default function ReportInfo(props: ReportInfoProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      centered >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DescriptionFormatter description={props.description} />
      </Modal.Body>
    </Modal>)
}
