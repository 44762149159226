import React, { useEffect, useMemo, useReducer, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { Download } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import ResultReport from "../../api/models/ResultReport"
import TestEvent from "../../api/models/TestEvent"
import TextReportSection from "../../api/models/TextReportSection"
import { APIError } from "../../api/ObservableFromFetch"
import { downloadTextReport, getTextReport } from "../../api/Results"
import FormatDate from "../../formatters/FormatDate"
import { useAuthStore } from "../../stores/StoreProvider"
import printPdf from "../../utils/PrintPdf"
import MoveTopButton from "../Core/MoveTopButton"
import { DatumLoader } from "../DataLoader"
import CovidSection from "./CovidSection"
import DonorInfo from "./DonorInfo"
import Footer from "./Footer"
import PhysicalSection from "./PhysicalSection"
import Status from "./Status"
import TextSection from "./TextSection"

export interface TextReportProps {
  testEvent: TestEvent
}

const CovidTestCode = 'U943'

const downloadTranslations = ["yourResult", "expectedRange", "testGuide", "test", "result", "status", "low", "borderlineLow", "normal", "borderlineHigh", "high",
  "labSlipId", "name", "gender", "collected", "reported", "reportedDate", "footer", "laboratoryReport", "legend", "sid"]

export default function TextReport(props: TextReportProps) {
  const { t } = useTranslation()
  const testEventId = props.testEvent.testEventId
  const authStore = useAuthStore()
  const showPhysicalFirst = authStore.domain?.showPhysicalFirst || false
  const [sectionsState, setSectionsState] = useReducer((state: DatumLoader<ResultReport<TextReportSection>>, newState: Partial<DatumLoader<ResultReport<TextReportSection>>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: { sections: [] } })
  const [downloading, setDownloading] = useState<boolean>(false)

  useEffect(() => {
    setSectionsState({ loading: true })

    const subscription = getTextReport(testEventId, showPhysicalFirst).subscribe({
      next: (result: ResultReport<TextReportSection>) => {
        setSectionsState({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setSectionsState({ loading: false })
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [testEventId, showPhysicalFirst, t])

  useEffect(() => {
    if (!downloading) {
      return
    }

    var params = {
      id: testEventId, showPhysicalFirst,
      translations: downloadTranslations.map((key) => ({ key: `results.${key}`, value: t(`results.${key}`) }))
    }
    const subscription = downloadTextReport(params).subscribe({
      next: (result: string) => {
        printPdf(result, `LabTestResults-${FormatDate(props.testEvent.dateOfRegistration, '-')}`)
        setDownloading(false)
      },
      error: (e: APIError) => {
        setDownloading(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [downloading, testEventId, props.testEvent.dateOfRegistration, showPhysicalFirst, t])

  const clickDownload = () => {
    if (!downloading) {
      setDownloading(true)
    }
  }

  const covidReport = useMemo(() => {
    var sections = sectionsState.data?.sections || []
    return sections.length === 1
      && sections[0].details.length === 1
      && sections[0].details[0].testCode === CovidTestCode
  }, [sectionsState])

  const hasResults = (sectionsState.data?.sections?.length ?? 0) > 0

  return (
    <Container>
      <DonorInfo />
      {sectionsState.loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      {!sectionsState.loading && <Status hasResults={hasResults} labReceived={sectionsState.data?.labReceived} />}
      {!sectionsState.loading && hasResults && <>
        <Row className="justify-content-end">
          <Col className="col-auto">
            {downloading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
            <Button variant="" title={t("results.download")} onClick={clickDownload}>
              <Download size={24} className="mb-1" />
            </Button>
          </Col>
        </Row>
        {covidReport && <CovidSection section={sectionsState.data?.sections[0]!} />}
        {!covidReport && sectionsState.data?.sections?.map(section => (section.physical ?
          <PhysicalSection key={section.name || ""} section={section} /> :
          <TextSection key={section.name || ""} section={section} />))
        }
      </>}
      <MoveTopButton />
      <Footer />
    </Container>)
}
