import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import TextReportSection from "../../api/models/TextReportSection"
import CovidSectionBody from "./CovidSectionBody"

export interface CovidSectionProps {
  section: TextReportSection
}

export default function CovidSection(props: CovidSectionProps) {
  const { t } = useTranslation()
  const { name, details } = props.section
  const detail = details[0]

  const rowClass = "mx-0 gx-2 gx-md-4 text-break"
  return (<>
    <Row><h4>{name}</h4></Row>
    <Row className="d-none d-md-flex bg-dark text-light mx-0">
      <Col xs={5}>{t("results.test")}</Col>
      <Col xs={4}>{t("results.result")}</Col>
      <Col xs={3}>{t("results.expectedRange")}</Col>
    </Row>
    <Row key="covid_row" className={rowClass}>
      <Col xs={4} className="d-flex d-md-none fw-bold">{t("results.test")}</Col>
      <Col xs={8} md={5}>COVID-19 Antibody Testing<br />({detail.name})</Col>
      <Col xs={4} className="d-flex d-md-none fw-bold">{t("results.result")}</Col>
      <Col xs={8} md={4}>{detail.numericResultString} {detail.units}</Col>
      <Col xs={4} className="d-flex d-md-none fw-bold">{t("results.expectedRange")}</Col>
      <Col xs={8} md={3}>{detail.expectedRange}</Col>
    </Row>
    <Row className="my-3">
      <Col><CovidSectionBody testResultId={detail.testResultId} /></Col>
    </Row>
  </>)
}
