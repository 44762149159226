import * as React from "react";
import { useTranslation } from "react-i18next";

export enum StepType {
  Unknown = "unknown",
  Barcode = "barcode",
  Consent = "consent",
  ///Employee = 3,
  //Verification = 4,
  EmployeeID = "employeeId",
  Demographics = "demographics",
  Questions = "questions",
  Instruction = "instruction",
  Shipping = "shipping",
  Finish = "finish"
}

export interface Step {
  current?: boolean
  done?: boolean
  enabled?: boolean
  number: number
  type: StepType
}

export interface StepIndicationProps {
  steps: Step[]
  implemenation?: string
}

export default function StepIndication(props: StepIndicationProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-center">
        <div className="steps clearfix d-inline-block">
          {props.steps.filter(t => t.enabled && t.type !== StepType.Finish).map((item: Step) =>
            <div key={`step_${item.type}`} className={`step ${item.done ? "done" : item.current ? "current" : ""}`}>
              <span>{item.number}.&nbsp;{t([`registration.step.${item.type}_${props.implemenation}`, `registration.step.${item.type}`])}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
