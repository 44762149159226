import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { GraphUpArrow } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import ReportInfo from "./ReportInfo"

export interface HowToImproveProps {
  title: string
  description: string
}

export default function HowToImproveGroup(props: HowToImproveProps) {
  const { t } = useTranslation()
  const [showImproveModal, setShowImproveModal] = useState(false)

  return (<>
    <Button variant="secondary" className="m-1" onClick={() => setShowImproveModal(true)}><GraphUpArrow /> {t("results.howToImprove")}</Button>
    <ReportInfo
      show={showImproveModal}
      title={t("results.improve", { name: props.title })}
      description={props.description}
      handleClose={() => setShowImproveModal(false)} />
  </>)
}
