import React, { useEffect, useReducer, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { Subscription } from "rxjs"
import { createOrder, getProgramServices, getParticipantData } from "../../api/ClientOrder"
import OrderRequest from "../../api/models/clientorder/OrderRequest"
import Participant from "../../api/models/clientorder/Participant"
import ParticipantIdentifier from "../../api/models/clientorder/ParticipantIdentifier"
import ProgramService from "../../api/models/clientorder/ProgramService"
import State from "../../api/models/State"
import { APIError } from "../../api/ObservableFromFetch"
import { getStates } from "../../api/States"
import EscapePhoneNumber from "../../formatters/EscapePhoneNumber"
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef"
import DataLoader, { DatumLoader } from "../DataLoader"
import OrderForm from "./OrderForm"

interface OrderProps {
  participant: ParticipantIdentifier
  onNext: () => void
}

const emptyOrder: OrderRequest = {
  firstName: undefined,
  lastName: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  stateCodeId: 0,
  zip: undefined,
  countryAbbreviation: undefined,
  mobile: undefined,
  phoneCode: undefined,
  dateOfBirth: undefined,
  participantId: 0,
  programServiceId: 0,
  quantity: 0
}

export default function Order(props: OrderProps) {
  const [translationRef, t] = useTranslationWithRef()
  const [states, setStates] = useReducer((state: DataLoader<State>, newState: Partial<DataLoader<State>>) => ({ ...state, ...newState }), { loading: true, loaded: false, data: [] })
  const [programServices, setProgramServices] = useReducer((state: DataLoader<ProgramService>, newState: Partial<DataLoader<ProgramService>>) => ({ ...state, ...newState }), { loading: true, loaded: false, data: [] })
  const [participantData, setParticipantData] = useReducer((state: DatumLoader<Participant>, newState: Partial<DatumLoader<Participant>>) => ({ ...state, ...newState }), { loading: true, loaded: false })
  const loading = states.loading || programServices.loading || participantData.loading
  const loaded = states.loaded && programServices.loaded && participantData.loaded
  const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    const subscriptions = new Subscription()

    subscriptions.add(getStates().subscribe({
      next: (result: State[]) => {
        setStates({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setStates({ loading: false })
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    }))

    subscriptions.add(getParticipantData(props.participant).subscribe({
      next: (result: Participant) => {
        setParticipantData({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setParticipantData({ loading: false })
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    }))

    subscriptions.add(getProgramServices({ participantId: props.participant.participantId }).subscribe({
      next: (result: ProgramService[]) => {
        setProgramServices({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setProgramServices({ loading: false })
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    }))

    return () => subscriptions.unsubscribe()
  }, [translationRef, props.participant])

  const handleSubmit = (order: OrderRequest) => {
    order.mobile = EscapePhoneNumber(order.mobile ?? "")

    setSubmitting(true)

    createOrder(order).subscribe({
      next: () => {
        setSubmitting(false)
        props.onNext()
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  return <>
    {loading && <Spinner animation="border" role="status" size="sm" className="d-block mx-1" />}
    {loaded && <OrderForm order={{ ...emptyOrder, ...participantData.data }} states={states.data} programServices={programServices.data} onSubmit={handleSubmit} />}
    <Button variant="primary" type="submit" form="orderForm" disabled={submitting || loading} className="mt-3">
      {submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
      <span>{t("shared.button.submit")}</span>
    </Button>
  </>
}
