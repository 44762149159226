import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export interface UnableFindEmployeeIdModalProps {
  onNo: () => void
  onYes: () => void
}

export default function UnableFindEmployeeIdModal(props: UnableFindEmployeeIdModalProps) {
  const { t } = useTranslation()
  const { onNo, onYes } = props

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static" onHide={onNo}>
      <Modal.Header closeButton />
      <Modal.Body>
        {t("registration.employee.unableToFindYourEmployeeID")}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onNo} variant="secondary">{t("registration.button.no")}</Button>
        <Button onClick={onYes} variant="primary">{t("registration.button.yes")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
