export enum AppRoute {
  Landing = "/",
  Login = "/login",
  Results = "/results",
  LifeAge = "/life-age",
  OrderTesting = "/order-testing",
  FAQ = "/faq",
  Registration = "/a",
  RegistrationAlt = "/r",
  ClientOrder = "/clientorder"
}
