import * as React from "react";

export default function FAQ() {
  return (<>
    <article className="faq">
      <h1 className="h5 text-center text-primary mt-3 mb-0">AccessMyHealth&trade;</h1>
      <h5 className="text-center text-primary mb-0">FAQ</h5>

      <p className="question">I have not received a text.</p>
      <div className="answer">
        <ul>
          <li>Check that you provided a cell phone number – a landline number will NOT receive a text message but will receive a voice message if there is an answering machine.</li>
          <li>If you provided a cell phone number, enable the SMS function on your phone.</li>
          <li>Your carrier may not participate in the receipt of SMS text. Please check with your service provider.</li>
          <li>Contact your service provider for questions about your text and data service plan</li>
        </ul>
      </div>

      <p className="question">Why are my results not available?</p>
      <div className="answer">
        <ul>
          <li>There are several reasons your laboratory report may not be available on AccessMyHealth&trade;.</li>
          <li>The sponsor/insurance company does not participate in the AccessMyHealth&trade; program.</li>
          <li>The phone number you entered does not match our records.</li>
          <li>The date of birth you entered does not match our records.</li>
          <li>The sample has not been received at CRL.</li>
        </ul>
      </div>

      <p className="question">How can I change my date of birth/ gender/ spelling of my name (or other demographic info)?</p>
      <div className="answer">
        <ul>
          <li>If your sample was for an insurance application, please contact your agent or the home 
            office of the insurance company. Some insurance companies will provide us with 
            information to update our records; other insurance companies may send you a copy of your 
            laboratory report.</li>
          <li>If the sample was collected for any other reason, please contact:<br></br>
            <a href="accessmyhealth@crlcorp.com">accessmyhealth@crlcorp.com</a>.</li>
        </ul>
      </div>

      <p className="question">My phone number is an international phone number.</p>
      <div className="answer">
        <ul>
          <li>AccessMyHealth&trade; does not have the ability to call international phone numbers.</li>
        </ul>
      </div>

      <p className="question">After I enter my date of birth, nothing happens.</p>
      <div className="answer">
        <ul>
          <li>Please accept the “Terms of Use” below the birth date (you may have to scroll down) and 
            click “Submit”.</li>
        </ul>
      </div>

      <p className="question">When I click on the link in my text my page just refreshes.</p>
      <div className="answer">
        <ul>
          <li>Some browsers require a double click on the link to open.</li>
        </ul>
      </div>

      <p className="question">I have entered my phone # and date of birth and am being told a verification code has been sent. 
        Why am I not receiving my verification code?</p>
      <div className="answer">
        <ul>
          <li>If your phone number is associated with a cell phone, you will receive a text from 88792. 
            You may need to click on the text to view the six-digit verification code. </li>
          <li>If your phone number is associated with a land line, the automated system will call your 
            phone with the six-digit verification within a few seconds after you click on “Submit”. Please 
            answer the phone when it rings to hear the incoming message with the verification code.</li>
        </ul>
      </div>

      <p className="question">I received my Verification Code, why can’t I see my laboratory report when I click on the link.</p>
      <div className="answer">
        <ul>
          <li>Some browsers require a double click on the link to open.</li>
          <li>
            It is possible the PDF file is downloading somewhere on your computer or device that you 
            are not aware of. Please check your download files.
          </li>
        </ul>
      </div>

      <p className="question">I’m receiving a message stating I’m locked out because I’ve tried too many times.</p>
      <div className="answer">
        <ul>
          <li>You have attempted to access your results 5 times or more with the incorrect number. The 
            site will be locked for 15 minutes; please try again after that time.</li>
        </ul>
      </div>

      <p className="question">
        Why are my HIV results not included?
      </p>
      <div className="answer">
        <ul>
          <li>If your collection was for an insurance application, HIV results are not available on 
            AccessMyHealth&trade;</li>
        </ul>
      </div>

      <p className="question">
        Can you email or fax my results?
      </p>
      <div className="answer">
        There are multiple options to obtain your laboratory report:
        <ul>
          <li>Obtain the report through AccessMyHealth&trade;</li>
          <li>If your collection was for an insurance application, please contact your insurance agent for 
            assistance in obtaining your report from the insurance company.</li>
          <li>If your collection was for any other reason, please contact the sponsor company.</li>
          <li>Requesting a copy of your laboratory report through CRL’s Privacy Office
            <ul>
              <li className="alpha-dot">Go to CRL’s corporate website at: <a href="https://www.crlcorp.com/">www.crlcorp.com</a></li>
              <li className="alpha-dot">Click on “Notice of Privacy Practices” at the bottom of the page.</li>
              <li className="alpha-dot">Click on” Privacy Notice.”</li>
              <li className="alpha-dot">Click link for “Right to Access” under Rights Regarding Your Information</li>
              <li className="alpha-dot">Print the form</li>
              <li className="alpha-dot">Complete and follow the instructions on the form</li>
            </ul>
          </li>
        </ul>
      </div>

      <p className="question">
        How do I print my laboratory report?
      </p>
      <div className="answer">
        From the graphical report:
        <ul>
          <li>Click on “text report” on the menu bar at the top.</li>
          <li>Click on the download icon at the top right corner of the report.</li>
        </ul>
      </div>

      <p className="question">
        How long will my results be available on this site?
      </p>
      <div className="answer">
        <ul>
          <li>If your collection was for an insurance application, your laboratory report will be available 
            for 12 months from the date the sample was received at the laboratory. </li>
          <li>If your sample was collected for any other reason, your laboratory report will be available 
            for 24 months from the date the sample was received at the laboratory.</li>
        </ul>
      </div>


    </article>
  </>)
}