import { makeAutoObservable } from "mobx"
import i18n from "../i18n"
import DonorInfo from "../api/models/Donor"
import Domain from "../api/models/Domain"
import { logout as logoutFetch } from "../api/Authenticate"

export default class AuthStore {
  authToken: string
  language: string
  translationLoading: boolean = false
  donorInfo?: DonorInfo
  domain?: Domain
  notificationProcessed: boolean = false

  constructor() {
    this.authToken = sessionStorage.getItem("authToken") || ""
    this.language = sessionStorage.getItem("language") || ""
    makeAutoObservable(this)
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken
    sessionStorage.setItem("authToken", authToken)
  }

  setLanguage(language: string) {
    this.language = language
    sessionStorage.setItem("language", language)
  }

  changeLanguage(language: string) {
    this.setTranslationLoading(true)
    i18n.changeLanguage(language, () => this.setTranslationLoading(false))
  }

  setTranslationLoading(loading: boolean) {
    this.translationLoading = loading
  }

  setDonorInfo(value: DonorInfo) {
    this.donorInfo = value
  }

  setDomain(value: Domain) {
    this.domain = value
  }

  setNotificationProcessed() {
    this.notificationProcessed = true
  }

  logout() {
    this.authToken = ""
    sessionStorage.setItem("authToken", "")
    logoutFetch()
  }
}
