import * as React from "react"

interface ToggleSwitchProps {
  on?: string
  off?: string
  className?: string
  checked?: boolean
  disabled?: boolean
  onCheck(checked: boolean): void
}

export default class ToggleSwitch extends React.PureComponent<ToggleSwitchProps> {
  render() {
    const className = `switch ${ this.props.className || "" }`
    return (
      <label className={ className }>
        <input type="checkbox" checked={ this.props.checked } disabled={ this.props.disabled } onChange={ e => this.props.onCheck(e.target.checked) } />
        <span className="slider round">
          <span className="on">{ this.props.on || "On" }</span>
          <span className="off">{ this.props.off || "Off" }</span>
        </span>
      </label>
    )
  }
}
