import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { BLUESTONE, HEALTH_CONFIRM, Implementation } from "../../api/models/registration/Implementation"
import { SERUM, WELLNESS_DBS } from "../../api/models/registration/KitType"
import { AppRoute } from "../../AppRoute"
import { formatPhone } from "../../formatters/FormatPhone"
import { sanitize } from "../../formatters/Sanitize"
import { useAuthStore } from "../../stores/StoreProvider"

interface FinishProps {
  title: string
  implementation?: Implementation
  businessType?: string
  mobile?: string
  phoneCode?: string
}

export default function Finish(props: FinishProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const authStore = useAuthStore()

  const serum_wellness_dbs = [SERUM, WELLNESS_DBS].includes(props.businessType ?? "")

  const note = serum_wellness_dbs
    ? t("registration.finish.crlWillForward")
    : t("registration.finish.crlWillContactYou", { phone: formatPhone(props.mobile) })

  const retainText = props.implementation === HEALTH_CONFIRM
    ? t("registration.finish.pleaseRetainYourUniqueBarcode_healthconfirm")
    : t(serum_wellness_dbs ? "registration.finish.pleaseRetainYourUniqueBarcode" : "registration.finish.pleaseRetainTheInstructionSheet")

  const handleResultClick = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (authStore.authToken) {
      history.push(AppRoute.Results)
      return
    }
    const fullPhone = `${props.phoneCode ?? ""}${props.mobile ?? ""}`
    history.push(`${AppRoute.Login}?phone=${fullPhone}`)
  }
  const handleAnotherTestClick = () => history.replace(AppRoute.Registration)
  const handleClose = () => history.push(AppRoute.Results)

  return <>
    <Row key="header">
      <Col><h2 className="text-primary">{props.title}</h2></Col>
    </Row>
    <Row>
      <Col md={7} className="mb-3">
        <p className="mb-1">{t(props.businessType === SERUM ? "registration.finish.allDoneSerum" : "registration.finish.allDone")}</p>
        <div className="py-1" dangerouslySetInnerHTML={{ __html: sanitize(note) }} />
        <p className="fw-bold mb-1">{retainText}</p>
        {!serum_wellness_dbs && <>
          <p className="mb-3">{t(props.implementation === BLUESTONE ? "registration.finish.youMayAlsoReturn_bluestone" : "registration.finish.youMayAlsoReturn")}</p>
          <div className="text-center">
            <a href="#/" onClick={handleResultClick} className="text-primary">
              <img src={`${process.env.PUBLIC_URL}/images/Icon/results.png`} alt={t("registration.finish.results")} height={40} />
              {t("registration.finish.results")}
            </a>
          </div>
        </>}
      </Col>
      <Col md={5} className="text-center mb-3">
        <img src={`${process.env.PUBLIC_URL}/images/Finish${props.implementation ? `_${props.implementation}` : ""}.jpg`} alt={props.title} />
      </Col>
    </Row>
    <Row>
      <Col className="mb-3">
        <Button variant="primary" type="button" onClick={handleAnotherTestClick}>{t("registration.button.activateAnotherTest")}</Button>&nbsp;
        <Button variant="primary" type="button" onClick={handleClose}>{t("registration.button.close")}</Button>
      </Col>
    </Row>
  </>
}
