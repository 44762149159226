import React, { useEffect, useReducer, useRef, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { searchEmployee, verifyEmployee } from "../../api/Registration"
import Employee from "./Employee"
import EmployeeRequest from "../../api/models/registration/EmployeeRequest"
import { toast } from "react-toastify"
import BarcodeResponse from "../../api/models/registration/BarcodeResponse"
import { Implementation } from "../../api/models/registration/Implementation"
import Verification from "./Verification"
import VerifyResponse from "../../api/models/registration/VerifyResponse"
import Donor from "./Donor"
import EmployeeResponse from "../../api/models/registration/EmployeeResponse"
import DemographicsDonorData from "../../api/models/registration/DemographicsDonorData"
import UnableFindEmployeeIdModal from "./Modals/UnableFindEmployeeIdModal"
import { useHistory } from "react-router"
import { AppRoute } from "../../AppRoute"
import MessageModal, { MessageType } from "./Modals/MessageModal"
import EscapePhoneNumber from "../../formatters/EscapePhoneNumber"
import DataLoader from "../DataLoader"
import Country from "../../api/models/Country"
import { getCountries } from "../../api/Countries"
import { APIError } from "../../api/ObservableFromFetch"
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef"
import { FormatToLocalDateString } from "../../formatters/FormatLocalISODateString"

enum DemographicsStep {
  Employee,
  Verification,
  Demographics
}

interface DemographicsStepProps {
  title?: string
  onPrev: () => void
  onNext: (donor: DemographicsDonorData) => void
  implementation?: Implementation
  barcode: BarcodeResponse
  submitting: boolean
}

const MAX_EMPLOYEE_NUMBER_ATTEMPTS = 4

export default function Demographics(props: DemographicsStepProps) {
  const [transaltionRef, t] = useTranslationWithRef()
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState<DemographicsStep>(props.barcode.donorId ? DemographicsStep.Demographics : DemographicsStep.Employee)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const employeeRequestRef = useRef<EmployeeRequest | undefined>(undefined)
  const employeeResponseRef = useRef<EmployeeResponse | undefined>(undefined)
  const verifyResponseRef = useRef<VerifyResponse | undefined>(props.barcode.donorId ? ({ donorId: props.barcode.donorId!, donorHash: props.barcode.donorHash! }) : undefined)
  const attempts = useRef<number>(0)
  const [showUnableFindEmployeeId, setShowUnableFindEmployeeId] = useState<boolean>(false)
  const [showInvalidEmployeeId, setShowInvalidEmployeeId] = useState<boolean>(false)
  const employeeIdCancelled = useRef<boolean>(false)
  const [countries, setCountries] = useReducer((state: DataLoader<Country>, newState: Partial<DataLoader<Country>>) => ({ ...state, ...newState }), { loading: true, loaded: false, data: [] })

  useEffect(() => {
    const subscription = getCountries().subscribe({
      next: (result: Country[]) => {
        setCountries({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setCountries({ loading: false })
        toast.error(transaltionRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [transaltionRef])

  const handleEmployeeSubmit = (employeeRequest: EmployeeRequest) => {
    if (submitting) {
      return
    }

    setSubmitting(true)
    employeeRequestRef.current = employeeRequest

    searchEmployee(employeeRequest).subscribe({
      next: (result) => {
        setSubmitting(false)
        if (result.skipVerification) {
          if (props.barcode.requireEmployeeId && !result.employeeId) {
            attempts.current = attempts.current + 1
            if (attempts.current >= MAX_EMPLOYEE_NUMBER_ATTEMPTS) {
              return setShowUnableFindEmployeeId(true)
            }
            return setShowInvalidEmployeeId(true)
          }
          setCurrentStep(DemographicsStep.Demographics)
        } else {
          setCurrentStep(DemographicsStep.Verification)
        }
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  const handleCodeSubmit = (code: string) => {
    setSubmitting(true)

    const { countryAbbreviation, mobileNumber, dateOfBirth, employeeId } = employeeRequestRef.current!

    verifyEmployee({ countryAbbreviation, mobileNumber, dateOfBirth, employeeId, verificationCode: code }).subscribe({
      next: (result) => {
        setSubmitting(false)
        if (result) {
          verifyResponseRef.current = result
          setCurrentStep(DemographicsStep.Demographics)
        }
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  const handleCodeSkip = () => {
    setCurrentStep(DemographicsStep.Demographics)
  }

  const handleCodeResend = () => {
    if (employeeRequestRef.current) {
      handleEmployeeSubmit(employeeRequestRef.current)
    }
  }

  const handleDonorSubmit = (donor: DemographicsDonorData) => {
    if (donor.insurance?.planId === -1) {
      donor.insurance = undefined
    }
    donor.requireEmployeeId = props.barcode.requireEmployeeId
    donor.employeeIdCancelled = employeeIdCancelled.current
    donor.mobile = EscapePhoneNumber(donor.mobile ?? "")
    donor.guardianPhone = EscapePhoneNumber(donor.guardianPhone ?? "")
    if (donor.insurance) {
      donor.insurance.phone = EscapePhoneNumber(donor.insurance.phone ?? "")
    }


    props.onNext(donor)
  }

  const handleUnableFindEmployeeIdNoResponse = () => {
    history.push(AppRoute.Results)
  }

  const handleUnableFindEmployeeIdYesResponse = () => {
    setShowUnableFindEmployeeId(false)
    employeeIdCancelled.current = true
    setCurrentStep(DemographicsStep.Demographics)
  }

  const loading = countries.loading
  const processing = submitting || props.submitting

  return (
    <>
      <Row>
        <Col><h2 className="text-primary">{props.title}</h2></Col>
      </Row>
      {loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      {!loading && currentStep === DemographicsStep.Employee &&
        <Employee requireEmployeeId={props.barcode.requireEmployeeId} implementation={props.implementation}
          onSubmit={handleEmployeeSubmit} submitting={false} countries={countries.data} />}
      {!loading && currentStep === DemographicsStep.Verification && <Verification onSubmit={handleCodeSubmit} submitting={submitting} onCodeSkip={handleCodeSkip} onCodeResend={handleCodeResend} />}
      {!loading && currentStep === DemographicsStep.Demographics && <Donor onSubmit={handleDonorSubmit} submitting={submitting} countries={countries.data}
        dateOfBirth={FormatToLocalDateString(employeeRequestRef.current?.dateOfBirth)}
        mobile={employeeRequestRef.current?.mobileNumber}
        implementation={props.implementation} barcode={props.barcode} employeeIdCancelled={employeeIdCancelled.current}
        verifyResponse={verifyResponseRef.current} employeeResponse={employeeResponseRef.current} />}
      {!loading && currentStep !== DemographicsStep.Verification && <div className="px-0 my-2">
        <Button variant="secondary" onClick={() => props.onPrev && props.onPrev()}>{t("registration.button.back")}</Button>&nbsp;
        <Button variant="primary" type="submit" form={(currentStep === DemographicsStep.Employee) ? "employeeForm" : "donorForm"} disabled={processing}>
          {processing && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
          <span>{t("registration.button.next")}</span>
        </Button>
      </div>}
      {showUnableFindEmployeeId && <UnableFindEmployeeIdModal onNo={handleUnableFindEmployeeIdNoResponse} onYes={handleUnableFindEmployeeIdYesResponse} />}
      {showInvalidEmployeeId && <MessageModal onHide={() => setShowInvalidEmployeeId(false)}
        message={{ body: t("registration.employee.invalidEmployeeId"), type: MessageType.Text }} />}
    </>
  )
}
