import { makeAutoObservable } from "mobx"
import { APIError } from "../api/ObservableFromFetch"
import { getPublishableKey } from "../api/PaymentIntent"

export default class AuthStore {
  loaded: boolean = false
  loading: boolean = false
  publishableKey: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  load() {
    if (this.loaded || this.loading) {
      return
    }
    this.loading = true

    getPublishableKey().subscribe({
      next: (result: string) => this.setPublishableKey(result),
      error: (e: APIError) => {}
    })
  }

  setPublishableKey(value: string) {
    this.publishableKey = value
    this.loaded = true
    this.loading = false
  }

}
