import { makeAutoObservable } from "mobx"
import { Implementation } from "../api/models/registration/Implementation"

export default class RegistrationStore {
  implementation?: Implementation

  constructor() {
    const storage = sessionStorage.getItem("registration")
    if (storage) {
      Object.assign(this, JSON.parse(storage))
    }
    makeAutoObservable(this)
  }

  setInitial(implementation: Implementation | undefined) {
    this.implementation = implementation

    sessionStorage.setItem("registration", JSON.stringify({ implementation }))
  }
}
