import React, { useRef, useState } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export interface VerificationProps {
  onSubmit: (code: string) => void
  submitting: boolean
  onCodeSkip: () => void
  onCodeResend: () => void
}

export default function Verification(props: VerificationProps) {
  const { t } = useTranslation()

  const [code, setCode] = useState("")
  const [codeTouched, setCodeTouched] = useState(false)
  const verificationInputRef = useRef<HTMLInputElement>(null)

  const validCode = /^\d{6}$/.test(code)

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validCode) {
      props.onSubmit(code)
    }
  }

  const handleCodeResend = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    props.onCodeResend()
  }

  return <Form id="validationForm" onSubmit={handleFormSubmit}>
    <Row>
      <Col>
        <span className="fw-bold">{t("registration.verification.welcomeBack")}</span>
        <p>{t("registration.verification.verificationCodeHasBeenSent")}</p>
      </Col>
    </Row>
    <Row>
      <Col sm={6} md={5} lg={4}></Col>
      <Col className="mb-3"><a href="#" onClick={handleCodeResend}>{t("registration.verification.resendCode")}</a></Col>
    </Row>
    <Row>
      <Col sm={6} md={5} lg={4}>
        <Form.Label className="fw-bold" htmlFor="verification">{t("registration.verification.pleaseEnterVerificationCode")}</Form.Label>
      </Col>
    </Row>
    <Row>
      <Col sm={6} md={5} lg={4} className="mb-3">
        <Form.Control type="text" id="verification" ref={verificationInputRef} value={code} minLength={6} maxLength={6}
          placeholder={t("registration.verification.confirmationCode")}
          onBlur={() => setCodeTouched(true)} onChange={(e) => { setCodeTouched(true); setCode(e.target.value) }}
          autoComplete="one-time-code" className={!validCode && codeTouched ? "is-invalid" : ""} />
        {!validCode && codeTouched && <Form.Control.Feedback className="order-last" type="invalid">{t("login.verification.invalid")}</Form.Control.Feedback>}
      </Col>
      <Col className="mb-3">
        <Button variant="primary" type="submit" form="validationForm" disabled={props.submitting}>
          {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
          <span>{t("registration.button.checkCode")}</span>
        </Button>
      </Col>
    </Row>
    <Row>
      <Col sm={8} md={7} lg={6} className="mb-3">
        {t("registration.verification.youMaySkipEntering")}
      </Col>
    </Row>
    <Row>
      <Col sm={6} md={5} lg={4}></Col>
      <Col className="mb-3">
        <Button variant="primary" type="button" disabled={props.submitting} onClick={props.onCodeSkip}>{t("registration.button.skipCheck")}</Button>
      </Col>
    </Row>
  </Form>
}
