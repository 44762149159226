import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { sanitize } from "../../../formatters/Sanitize"

export default function BluestoneInstruction() {
  const { t } = useTranslation()

  return <>
    <Row>
      <Col md={8} lg={9}>
        <ul className="shipping" dangerouslySetInnerHTML={{ __html: sanitize(t("registration.shipping.instruction.bluestone1")) }} />
      </Col>
      <Col md={3} lg={2} className="mt-1 text-center">
        <img src={`${process.env.PUBLIC_URL}/images/Saliva_Ship1.png`} alt="" width="200" />
      </Col>
    </Row>
  </>
}
