import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { TFunction, useTranslation } from "react-i18next"
import ReportRange from "../../api/models/RangeDetail"
import { RangeColor, defaultFontSize, defaultFontFamily, InverseRangeColor } from './ChartStyles'

interface RangeChartProps {
  numericResult?: number
  range?: ReportRange
  secondResult?: number
  lifeAge?: boolean
  lifeAgeWeightHeight?: boolean
}

interface SeriesRow {
  name: string
  color: string
  labelColor: string
  data: { x: string, y: number[] }[]
  fixedRange: number[]
}

function adjustSeries(range: number[], decimals?: number) {
  if (decimals === undefined) {
    return range
  }

  const [bottom, top] = [...range]
  let ajustedTop = +(top - 10 ** (-decimals)).toFixed(decimals)
  if (ajustedTop <= bottom) {
    ajustedTop = top
  }
  return [bottom, ajustedTop]
}

export function mapSeries(range: ReportRange | undefined, t: TFunction<"translation", undefined>, lifeAgeWeightHeight?: boolean): SeriesRow[] {
  const series: SeriesRow[] = []

  if (!range) {
    return series
  }

  let top = range.scaleMax

  if (lifeAgeWeightHeight) {
    series.push({ name: " ", color: RangeColor.lifeAgeWeightHeight, labelColor: InverseRangeColor.lifeAgeWeightHeight, data: [{ x: " ", y: [range.scaleMin, top] }], fixedRange: [] })
    return series
  }

  if (range.high !== undefined) {
    series.push({ name: "high", color: RangeColor.out, labelColor: RangeColor.active, data: [{ x: " ", y: [range.high, top] }], fixedRange: [] })
    top = range.high
  }

  if (range.borderlineHigh !== undefined) {
    series.push({ name: "borderlineHigh", color: RangeColor.borderline, labelColor: RangeColor.active, data: [{ x: " ", y: [range.borderlineHigh, top] }], fixedRange: [] })
    top = range.borderlineHigh
  }

  if (range.normal !== undefined) {
    series.push({ name: "normal", color: RangeColor.normal, labelColor: RangeColor.active, data: [{ x: " ", y: [range.normal, top] }], fixedRange: [] })
    top = range.normal
  }

  if (range.borderlineLow !== undefined) {
    series.push({ name: "borderlineLow", color: RangeColor.borderline, labelColor: RangeColor.active, data: [{ x: " ", y: [range.borderlineLow, top] }], fixedRange: [] })
    top = range.borderlineLow
  }

  if (range.low !== undefined) {
    series.push({ name: "low", color: RangeColor.out, labelColor: RangeColor.active, data: [{ x: " ", y: [range.low, top] }], fixedRange: [] })
  }

  for (let i = 0; i < series.length; i++) {
    series[i].name = t(`results.${series[i].name}`).toUpperCase()
    const current = series[i].data[0].y
    series[i].fixedRange = i === 0 ? current : adjustSeries(current, range.decimals)
  }

  return series
}

export function rangeColor(series: SeriesRow[], result: number, outOfRangeColor: string = RangeColor.active): [string, string] {
  const range = resultRange(series, result)
  return [range?.color || outOfRangeColor, range?.labelColor || RangeColor.active]
}

export function resultRange(series: SeriesRow[], result: number) {
  let range = series.find(s => s.data[0].y[0] === result && result === s.data[0].y[1])
  if (!range) {
    range = series.find(s => s.data[0].y[0] <= result && result < s.data[0].y[1])
  }
  if (!range && series.length) {
    const lastSeria = series[0]
    if (lastSeria.data[0].y[0] <= result && result <= lastSeria.data[0].y[1]) {
      range = lastSeria
    }
  }
  return range
}

const isNumber = (val: any) => typeof val === "number" && !isNaN(val)

export default function RangeChart(props: RangeChartProps) {
  const { t } = useTranslation()
  const [series, setSeries] = useState<SeriesRow[]>([])
  const [options, setOptions] = useState<ApexOptions | undefined>(undefined)
  const [annotationTooltip, setAnnotationTooltip] = useState(false)
  const [annotationTooltipEvent, setAnnotationTooltipEvent] = useState<MouseEvent | undefined>(undefined)

  useEffect(() => {
    const onScroll: EventListener = (event: Event) => {
      setAnnotationTooltip(false)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    if (!props.range) {
      return
    }
    const series = mapSeries(props.range, t, props.lifeAgeWeightHeight)
    setSeries(series)

    const annotationValue = props.lifeAge ? (props.numericResult && resultRange(series, props.numericResult) ? props.numericResult : props.range?.scaleMin) : props.numericResult
    const annotations: XAxisAnnotations[] = [
      {
        x: annotationValue,
        opacity: 100,
        strokeDashArray: 0,
        borderWidth: 3,
        borderColor: RangeColor.annotation,
        offsetY: 0,

        label: {
          borderWidth: 0,
          offsetY: -12,
          orientation: "horizontal",
          style: {
            cssClass: "apexcharts-xaxis-annotation-label-tooltip",
            color: rangeColor(series, annotationValue || 0)[1],
            background: rangeColor(series, annotationValue || 0)[0],
            fontSize: defaultFontSize,
            fontWeight: "bold",
            padding: {
              left: 8,
              right: 8,
              top: 4,
              bottom: 10
            }
          },
          text: annotationValue?.toString(),
          mouseEnter: (_: XAxisAnnotations, e: MouseEvent) => {
            let rect = (e.target as HTMLElement).getBoundingClientRect()
            if (e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom) {
              setAnnotationTooltipEvent(e)
              setAnnotationTooltip(true)
            }
          },
          mouseLeave: (_: XAxisAnnotations, e: MouseEvent) => {
            let rect = (e.target as HTMLElement).getBoundingClientRect();
            if (e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom - 7) {
              setAnnotationTooltipEvent(e)
              setAnnotationTooltip(true)
            } else {
              setAnnotationTooltip(false)
            }
          },
        }
      }
    ]

    if (props.secondResult !== undefined && props.secondResult !== props.numericResult) {
      annotations.push({
        x: props.secondResult,
        opacity: 100,
        strokeDashArray: 0,
        borderWidth: 3,
        borderColor: RangeColor.annotation,
        offsetY: 0,

        label: {
          borderWidth: 0,
          offsetY: -12,
          orientation: "horizontal",
          style: {
            color: RangeColor.active,
            background: RangeColor.annotation,
            fontSize: defaultFontSize,
            fontWeight: "bold",
            padding: {
              left: 8,
              right: 8,
              top: 4,
              bottom: 10
            }
          },
          text: props.secondResult?.toString()
        }
      })
    }

    setOptions({
      chart: {
        type: "rangeBar",
        id: props.range?.testCode,
        toolbar: {
          show: false
        },
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false
        },

        fontFamily: defaultFontFamily
      },
      plotOptions: {
        bar: {
          horizontal: true,
          rangeBarGroupRows: true
        }
      },
      grid: {
        padding: { left: -4 },
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      xaxis: {
        min: props.range?.scaleMin,
        max: props.range?.scaleMax,
        tickAmount: 5,
        labels: {
          trim: true,
          formatter: function (value) {
            return isNumber(value) ? (+(+value).toFixed(2)).toString() : value;
          }
        }
      },
      legend: {
        show: false
      },
      stroke: {
        width: 2,
        colors: [RangeColor.active]
      },
      states: {
        hover: {
          filter: {
            type: "none"
          }
        },
        active: {
          filter: {
            type: "none"
          }
        },
      },
      fill: {
        opacity: 1
      },
      colors: [function ({ seriesIndex, w }: any) {
        return w.globals.initialSeries[seriesIndex].color;
      }],
      dataLabels: {
        enabled: true,
        formatter: function (_val, opts) {
          return opts.w.globals.initialSeries[opts.seriesIndex].name;
        },
        style: {
          colors: series.map(s => s.labelColor),
          fontWeight: "bold",
          fontFamily: defaultFontFamily
        }
      },
      tooltip: {
        enabled: !props.lifeAgeWeightHeight,
        custom: function ({ seriesIndex, w }) {
          const name = w.globals.initialSeries[seriesIndex].name
          const from = w.globals.initialSeries[seriesIndex].fixedRange[0]
          const to = w.globals.initialSeries[seriesIndex].fixedRange[1]
          return `<div class="apexcharts-tooltip-rangebar"><div> <span class="series-name">${name}</span></div><div> <span class="category"> </span> <span class="value start-value">${from}</span> <span class="separator">-</span> <span class="value end-value">${to}</span></div></div>`;
        }
      },
      annotations: {
        xaxis: annotations
      }
    })
  }, [props.range, props.numericResult, props.lifeAge, props.lifeAgeWeightHeight, props.secondResult, t])

  return (<>{options && <ReactApexChart series={series} options={options} height={132} width="100%" type="rangeBar">
  </ReactApexChart>}
    {annotationTooltip && <div className="apexcharts-tooltip apexcharts-theme-light custom-chart-annotation-tooltip" style={{ left: annotationTooltipEvent?.clientX, top: annotationTooltipEvent?.clientY }}>
      <div className="apexcharts-tooltip-rangebar">
        <div><span className="category">{t("results.yourResult")}</span></div>
      </div>
    </div>}
  </>)
}
