import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import serviceWorkerStore from "./stores/ServiceWorkerStore"

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl || ""}>
    <App />
  </BrowserRouter>,
  rootElement);

serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: Event) => {
        const serviceWorker = event?.target as ServiceWorker
        if (serviceWorker.state === "activated") {
          if (serviceWorkerStore.softReload) {
            serviceWorkerStore.reloadRequired = true
          } else {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  }
})
