import React, { useMemo } from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { getQuestions } from "../../api/Registration";
import { APIError } from "../../api/ObservableFromFetch";
import QuestionResponse from "../../api/models/registration/QuestionResponse";
import QuestionAnswer from "../../api/models/registration/QuestionAnswer";
import { Implementation } from "../../api/models/registration/Implementation";
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef";
import { sanitize } from "../../formatters/Sanitize";
import PhysicalMeasurementsForm, { DiastolicRange, HeightRange, PhysicalMeasurementsWithConfirmation, SystolicRange, WeightRange } from "./PhysicalMeasurementsForm"
import PhysicalMeasurements from "../../api/models/registration/PhysicalMeasurements";
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

interface QuestionsProps {
  title?: string
  implementation?: Implementation
  accountNumber?: string
  testCode?: string
  onNext: (answers: QuestionAnswer[], physicalMeasurements?: PhysicalMeasurements) => void
  submitting: boolean
  requireSymptomQuestions: boolean
  displayPhysicalMeasurements: boolean
}

const separator = (index: number) => <Row className="my-2" key={`separator-${index}`}>
  <Col xs={1} />
  <Col md={5} sm={6} xs={10}><hr className="my-auto" /></Col>
  <Col md={6} sm={5} xs={1} />
</Row>

interface ExtendedQuestionResponse extends QuestionResponse {
  parentId?: number
}

export default function Questions(props: QuestionsProps) {
  const [translationRef, t] = useTranslationWithRef()
  const [loading, setLoading] = useState(false)
  const [recommendation, setRecommendation] = useState("")
  const [questions, setQuestions] = useState<ExtendedQuestionResponse[]>()
  const [answers, setAnswers] = useState<QuestionAnswer[]>([])
  const [displayPhysicalMeasurementsAnswer, setDisplayPhysicalMeasurementsAnswer] = useState<boolean | undefined>(undefined)

  const { accountNumber, testCode, implementation, requireSymptomQuestions, displayPhysicalMeasurements } = props

  useEffect(() => {
    if (!requireSymptomQuestions) {
      return
    }

    setLoading(true)
    const subscription = getQuestions({ accountNumber, testCode, implementation }).subscribe({
      next: (result: QuestionResponse[]) => {
        var questions = result.map(question => {
          const match = question.description.match(/data-parent-id='(\d+)'/i)
          const parentId = match ? +match[1] : undefined
          return { ...question, parentId }
        })
        setQuestions(questions)
        setAnswers(result.map(r => { return { id: r.id, answer: "" } }))
        setLoading(false)
      },
      error: (e: APIError) => {
        setLoading(false)
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [requireSymptomQuestions, accountNumber, testCode, implementation, translationRef])

  function handleChange(e: ChangeEvent, question: QuestionResponse, on: boolean) {
    const div = document.querySelector(`div[data-id="${question.id}"]`) as HTMLElement | null
    const text = div ? on ? div.dataset.recommendationYes : div.dataset.recommendationNo : null
    setRecommendation(text ?? "")
    const showVaccine = div?.dataset.vaccine === (on ? "yes" : "no")
    const el = document.querySelector(`#question-${question.id}-option`) as HTMLInputElement | null
    if (el) {
      el.style.display = showVaccine ? "" : "none"
    }

    setAnswers(current => current.map(answer => {
      if (answer.id === question.id) {
        return { ...answer, answer: showVaccine ? el?.value ?? "" : on ? "1" : "0" }
      }
      return answer
    })
    )
  }

  function vaccineChange(e: ChangeEvent<HTMLSelectElement>, question: QuestionResponse) {
    const vaccine = e.target.value
    setAnswers(current => current.map(answer => {
      if (answer.id === question.id) {
        return { ...answer, answer: vaccine }
      }
      return answer
    })
    )
  }

  const displayQuestion = (question: ExtendedQuestionResponse) => {
    if (!question.parentId) {
      return true
    }
    var parentAnswer = answers.find(answer => answer.id === question.parentId)?.answer
    if (parentAnswer && parentAnswer !== "0") {
      return true
    }
    return false
  }

  const questionsAnswered = useMemo(() => {
    if (displayPhysicalMeasurements && displayPhysicalMeasurementsAnswer === undefined) {
      return false
    }

    return answers.every(answer => {
      if (!!answer.answer) {
        return true
      }
      var question = questions?.find(question => question.id === answer.id)
      if (!question?.parentId) {
        return false
      }
      var parentAnswer = answers.find(answer => answer.id === question?.parentId)?.answer
      return !(parentAnswer && parentAnswer !== "0")
    })
  }, [answers, questions, displayPhysicalMeasurementsAnswer, displayPhysicalMeasurements])

  const validationSchema = useMemo(() => {
    const invalidValueMessage = t("shared.validation.invalidValue")
    return Yup.object().shape({
      height: Yup.lazy((value) => (value === undefined || value === "") ? Yup.string().nullable() :
        Yup.number().typeError(invalidValueMessage).min(0, invalidValueMessage).max(100, invalidValueMessage)
          .test("height", " ", (value, context) => {
            return context.parent.heightConfirmed || ((value ?? 0) >= HeightRange[0] && (value ?? 0) <= HeightRange[1])
          })),
      weight: Yup.lazy((value) => (value === undefined || value === "") ? Yup.string().nullable() :
        Yup.number().typeError(invalidValueMessage).min(0, invalidValueMessage).max(1000, invalidValueMessage)
          .test("weight", " ", (value, context) => {
            return context.parent.weightConfirmed || ((value ?? 0) >= WeightRange[0] && (value ?? 0) <= WeightRange[1])
          })),
      waistCircumference: Yup.number().typeError(invalidValueMessage).min(0, invalidValueMessage).max(1000, invalidValueMessage),
      bloodPressure: Yup.string().matches(/^\d{1,3}\/\d{1,3}$/, invalidValueMessage)
        .test("bloodPressure", " ", (value, context) => {
          if (!value || context.parent.bloodPressureConfirmed) {
            return true
          }
          const groups = /^(\d{1,3})\/(\d{1,3})$/.exec(value ?? "")
          if (groups?.length !== 3) {
            return true
          }
          const systolic = +groups![1]
          const diastolic = +groups![2]
          if (systolic < SystolicRange[0] || systolic > SystolicRange[1]) {
            return false
          }

          if (diastolic < DiastolicRange[0] || diastolic > DiastolicRange[1]) {
            return false
          }
          return true
        })
    })
  }, [t])

  const formik = useFormik<PhysicalMeasurementsWithConfirmation>({
    initialValues: { heightConfirmed: false, weightConfirmed: false, bloodPressureConfirmed: false },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { height, weight, waistCircumference, bloodPressure } = values
      props.onNext(answers, { height, weight, waistCircumference, bloodPressure })
    }
  })

  const handlePhysicalMeasurementQuestionYes = () => {
    setDisplayPhysicalMeasurementsAnswer(true)
  }

  const handlePhysicalMeasurementQuestionNo = () => {
    setDisplayPhysicalMeasurementsAnswer(false)
    formik.resetForm()
  }

  return (
    <FormikProvider value={formik}>
      <Form id="questionsForm" onSubmit={formik.handleSubmit}>
        <Row key="header">
          <Col><h2 className="text-primary">{props.title}</h2></Col>
        </Row>
        {loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
        {requireSymptomQuestions && <Row className="my-3"><Col>{t("registration.questions.haveYouHadAnyOfTheFollowingSymptoms")}</Col></Row>}
        {!loading && questions && questions.map((question, index) => <div key={index} className={displayQuestion(question) ? "" : "d-none"}>
          {index > 0 && separator(index)}
          <Row key={`row-${question.id}`}>
            <Col lg={5} md={5} sm={6} className="mt-1">
              <div dangerouslySetInnerHTML={{ __html: sanitize(question.description) }} />
            </Col>
            <Col sm={6}>
              <Form.Check key={`question-${question.id}-yes`} inline type="radio" name={`question-${question.id}`} radioGroup={`question-${question.id}`} id={`question-${question.id}-yes`} label={t("yes")} onChange={(e) => handleChange(e, question, true)} />
              <Form.Check key={`question-${question.id}-no`} inline type="radio" name={`question-${question.id}`} radioGroup={`question-${question.id}`} id={`question-${question.id}-no`} label={t("no")} onChange={(e) => handleChange(e, question, false)} />
              <select id={`question-${question.id}-option`} name={`question-${question.id}-option`} required={false} style={{ display: "none" }} onChange={(e) => vaccineChange(e, question)}>
                <option disabled={true} value="">--- Please select vaccine ---</option>
                <option value="Pfizer-BioNTech">Pfizer-BioNTech</option>
                <option value="Moderna">Moderna</option>
                <option value="Janssen (Johnson &amp; Johnson)">Janssen (Johnson &amp; Johnson)</option>
                <option value="Other">Other</option>
              </select>
            </Col>
          </Row>
        </div>)}
        {displayPhysicalMeasurements && <Row className="mt-3">
          <Col sm={6} md={5} >{t("registration.measurements.question")}</Col>
          <Col>
            <Form.Check inline type="radio" name="question-physical-measurements" id="question-physical-measurements-yes"
              label={t("yes")} onChange={handlePhysicalMeasurementQuestionYes} />
            <Form.Check inline type="radio" name="question-physical-measurements" id="question-physical-measurements-no"
              label={t("no")} onChange={handlePhysicalMeasurementQuestionNo} />
          </Col>
        </Row>}
        {displayPhysicalMeasurementsAnswer && <PhysicalMeasurementsForm />}

        <div className="px-0 my-2">
          <Button variant="primary" type="submit" form="questionsForm" disabled={!questionsAnswered || loading || props.submitting} >
            {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
            <span>{t("registration.button.next")}</span>
          </Button>
        </div>
        <Modal show={!!recommendation} variant="info">
          <Modal.Body>{recommendation}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setRecommendation("")}>OK</Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </FormikProvider>
  )
}
