import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { sanitize } from "../../../formatters/Sanitize"

export default function SerumInstruction() {
  const { t } = useTranslation()

  return <>
    <Row>
      <Col xs={12}>
        <ul className="shipping" dangerouslySetInnerHTML={{ __html: sanitize(t("registration.shipping.instruction.serum1")) }} />
      </Col>
    </Row>
  </>
}
