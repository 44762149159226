import React, { Suspense, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import Layout from './components/Layout';
import Login from './components/Login';
import LifeAge from './components/LifeAge';
import OrderTesting from './components/OrderTesting';
import Results from './components/Results';
import { AppRoute } from './AppRoute';
import { StoreProvider, useAuthStore } from './stores/StoreProvider';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import LoadingOverlay from './components/LoadingOverlay';
import Registration from './components/Registration';
import ClientOrder from './components/ClientOrder';
import "./App.scss";
import FAQ from './components/FAQ';
import serviceWorkerStore from "./stores/ServiceWorkerStore"

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "colored"
})

export function App() {
  const authStore = useAuthStore()
  const isAuthenticated = !!authStore.authToken
  const useLifeAgeCalculator = authStore.domain?.useLifeAgeCalculator ?? false
  const useClientOrder = authStore.domain?.useClientOrder ?? false
  const location = useLocation()

  useLayoutEffect(() => {
    if (serviceWorkerStore.reloadRequired) {
      window.location.reload()
    }
  }, [location])

  let collection = document.getElementsByTagName("iframe");

  Array.from(collection).forEach(function (element) {
      element.removeAttribute("allowtransparency");
      element.removeAttribute("frameborder");
      element.removeAttribute("scrolling");
  });

  return (
    <Layout loading={authStore.translationLoading}>
      <Switch>
        {useClientOrder && <Route exact path={AppRoute.Landing} component={ClientOrder} />}
        <Route exact path={AppRoute.Login} component={Login} />
        <Route exact path={AppRoute.Registration} component={Registration} key={location.key} />
        <Route exact path={AppRoute.RegistrationAlt} component={Registration} key={location.key} />
        <Route exact path={AppRoute.ClientOrder} component={ClientOrder} key={location.key} />
        {isAuthenticated && <Route path={AppRoute.Results} component={Results} />}
        {isAuthenticated && useLifeAgeCalculator && <Route path={AppRoute.LifeAge} component={LifeAge} />}
        {isAuthenticated && <Route path={AppRoute.OrderTesting} component={OrderTesting} />}
        <Route path={AppRoute.FAQ} component={FAQ} />
        <Route path="*">
          <Redirect to={isAuthenticated ? AppRoute.Results : AppRoute.Login} />
        </Route>
      </Switch>
    </Layout>
  )
}

const AppWithStore = observer(App);

export default function WrappedApp() {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <StoreProvider>
        <AppWithStore />
      </StoreProvider>
    </Suspense>
  );
}