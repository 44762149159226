import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useReducer, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import FormContext from 'react-bootstrap/FormContext'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries'
import { toast } from 'react-toastify'
import { getCountries } from "../../api/Countries"
import Country from "../../api/models/Country"
import { APIError } from '../../api/ObservableFromFetch'
import { useTranslationWithRef } from '../../hooks/useTranslationWithRef'
import EscapePhoneNumber from '../../formatters/EscapePhoneNumber'

export interface PhoneNumberHandle {
  setPhone: (phone: string, country: string) => void
}

const RequiredPhoneNumberLength = 10

interface PhoneNumberProps {
  disableAreaCodeValidation?: boolean
  initialPhone?: string
  onChange: (valid: boolean, value: string, country: string) => void
  skipFocus?: boolean
  skipFeedback?: boolean
  title?: string
  disabled?: boolean
  countries?: Country[]
  fieldId?: string
}

interface CountriesState {
  loading: boolean
  loaded: boolean
  countries: Country[]
  countriesData?: CountryData[]
}

function mapCountryData(countries: Country[]): CountryData[] {
  const all = AllCountries.getCountries()
  const getAreaCodes = (country: Country) => all.find(c => c.iso2 === country.abbreviation.toLowerCase())?.areaCodes
  return countries.map(country => [country.name, country.abbreviation.toLowerCase(), country.phoneCode, country.abbreviation.toLowerCase() === "us" ? 0 : 1, getAreaCodes(country)] as CountryData)
}

export function PhoneNumber(props: PhoneNumberProps, forwardedRef: React.Ref<PhoneNumberHandle> | undefined) {
  const [transaltionRef, t] = useTranslationWithRef()
  const [phone, setPhone] = useState<string>("")
  const [phoneValid, setPhoneValid] = useState(false)
  const [phoneTouched, setPhoneTouched] = useState(false)
  const [countriesState, setCountriesState] = useReducer((state: CountriesState, newState: Partial<CountriesState>) => ({ ...state, ...newState }), { loading: true, loaded: false, countries: [] })

  const { controlId } = useContext(FormContext);

  useImperativeHandle(forwardedRef, () => ({
    setPhone(phone: string, country: string) {
      setPhoneValid(true)
      setPhone(phone)
      setPhoneTouched(true)
      props.onChange(true, phone, country)
    }
  }), [props])

  const intlTelInputRefCallback = useCallback((ref: IntlTelInput | null) => {
    if (!ref) {
      return
    }
    setTimeout(() => {
      if (props.initialPhone) {
        const formattedPhoneNumber = ref.formatNumber(props.initialPhone)
        ref.updateFlagFromNumber(formattedPhoneNumber, true)
        ref.updateValFromNumber(formattedPhoneNumber, true, true)
      }

      if (!props.skipFocus) {
        ref.tel?.focus()
      }
    }, 100)
  }, [props.initialPhone, props.skipFocus])

  useEffect(() => {
    if (props.countries) {
      setCountriesState({ loading: false, loaded: true, countries: props.countries, countriesData: mapCountryData(props.countries) })
      return
    }

    const subscription = getCountries().subscribe({
      next: (result: Country[]) => {
        setCountriesState({ loading: false, loaded: true, countries: result, countriesData: mapCountryData(result) })
      },
      error: (e: APIError) => {
        setCountriesState({ loading: false })
        toast.error(transaltionRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [transaltionRef, props.countries])

  const changeHandler = (valid: boolean, value: string, selectedCountryData: CountryData) => {
    if (props.disableAreaCodeValidation) {
      valid = EscapePhoneNumber(value).length === RequiredPhoneNumberLength
    }
    setPhoneValid(valid)
    setPhone(value)
    setPhoneTouched(true)
    props.onChange(valid, value, selectedCountryData.iso2 || "")
  }

  const inputFieldId = controlId || (props.fieldId ?? "phoneNumber")
  return (
    <>
      <Form.Label className="fw-bold text-uppercase" htmlFor={controlId ? undefined : inputFieldId}>{props.title || t("login.phoneNumber.title")}</Form.Label>
      {countriesState.loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      {countriesState.loaded && <IntlTelInput
        ref={intlTelInputRefCallback}
        telInputProps={{ maxLength: 25 }}
        value={phone}
        containerClassName={`intl-tel-input d-block ${phoneTouched && !phoneValid ? "is-invalid" : ""}`}
        countriesData={countriesState.countriesData}
        preferredCountries={["US"]}
        autoPlaceholder={false}
        nationalMode
        format
        inputClassName={`form-control ${phoneTouched && !phoneValid ? "is-invalid" : ""}`}
        onPhoneNumberBlur={changeHandler}
        onPhoneNumberChange={changeHandler}
        disabled={props.disabled ?? false}
        fieldId={inputFieldId}
        fieldName={inputFieldId}
      />}
      {phoneTouched && !phoneValid && !props.skipFeedback && <Form.Control.Feedback className="order-last" type="invalid">{t("login.phoneNumber.invalid")}</Form.Control.Feedback>}
    </>
  )
}

export default forwardRef<PhoneNumberHandle, PhoneNumberProps>(PhoneNumber)
