import React from "react"
import { Col, Row } from "react-bootstrap"
import GraphReportSection from "../../api/models/GraphReportSection"
import TextReportSection from "../../api/models/TextReportSection"

export interface RangeSectionProps {
  section: GraphReportSection | TextReportSection
}

export default function PhysicalSection(props: RangeSectionProps) {
  const { name, details } = props.section

  return (<>
    <Row><h4>{name}</h4></Row>
    <Row className="mb-3">
      {details.map((detail, index) =>
        <Col xs={12} md={6} lg={4} key={index} className="mb-3">
          <span className="float-start fw-bold text-uppercase">{detail.name}:</span>
          <span className="float-end">{detail.numericResultString} {detail.units}</span>
        </Col>)}
    </Row>
  </>)
}
