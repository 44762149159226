import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import TextReportSection from "../../api/models/TextReportSection"
import ShowMoreGroup from "./ShowMoreGroup"

export interface TextSectionProps {
  section: TextReportSection
}

export default function TextSection(props: TextSectionProps) {
  const { t } = useTranslation()
  const { name, details, suppressed } = props.section

  const rowClass = "gx-2 gx-md-3 text-break"
  return (<>
    <Row><h4>{name}</h4></Row>
    <Row className="d-none d-md-flex bg-dark text-light text-uppercase gx-md-3">
      {suppressed ?
        <>
          <Col xs={3}>{t("results.test")}</Col>
          <Col xs={3}>{t("results.yourResult")}</Col>
          <Col xs={3}>{t("results.status")}</Col>
          <Col xs={3}>{t("results.expectedRange")}</Col>
        </> :
        <>
          <Col xs={3}>{t("results.test")}</Col>
          <Col xs={4} className="px-0">
            <Row className="m-0 gx-2 gx-md-3">
              <Col xs={4}>{t("results.yourResult")}</Col>
              <Col xs={3}>{t("results.status")}</Col>
              <Col xs={5}>{t("results.expectedRange")}</Col>
            </Row>
          </Col>
          <Col xs={5}>{t("results.testGuide")}</Col>
        </>}
    </Row>
    {details.map((detail, index) => (
      <Row key={index} className={index & 1 ? `bg-info ${rowClass}` : rowClass}>
        {suppressed ?
          <>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.test")}</Col>
            <Col xs={8} md={3}>{detail.name}</Col>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.yourResult")}</Col>
            <Col xs={8} md={3}>{detail.numericResultString} {detail.units}</Col>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.status")}</Col>
            <Col xs={8} md={3}>{detail.rangeName}</Col>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.expectedRange")}</Col>
            <Col xs={8} md={3}>{detail.expectedRange}</Col>
          </> :
          <>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.test")}</Col>
            <Col xs={8} md={3}>{detail.name}</Col>
            <Col xs={12} md={4} className="px-0">
              <Row className="m-0 gx-2 gx-md-3">
                <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.yourResult")}</Col>
                <Col xs={8} md={4}>{detail.numericResultString} {detail.units}</Col>
                <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.status")}</Col>
                <Col xs={8} md={3}>{detail.rangeName}</Col>
                <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.expectedRange")}</Col>
                <Col xs={8} md={5}>{detail.expectedRange}</Col>
              </Row>
            </Col>
            <Col xs={4} className="d-flex d-md-none fw-bold text-uppercase">{t("results.testGuide")}</Col>
            <Col xs={8} md={5}>
              {detail.shortDescription}
              {detail.longDescription && <div><ShowMoreGroup title={detail.name} description={detail.longDescription} /></div>}
            </Col>
          </>}
      </Row>))}
    <Row className="mb-3"></Row>
  </>)
}
