import React from "react"
import { observer } from "mobx-react-lite"
import { useAuthStore } from "../../stores/StoreProvider"
import { sanitize } from "../../formatters/Sanitize"

export function Notification(props: {skip: boolean}) {
  const authStore = useAuthStore()
  const show = !props.skip && authStore.domain?.notifications?.length && authStore.domain?.notifications?.length > 0 && !authStore.notificationProcessed

  return (<>
    {!!show && authStore.domain?.notifications?.map((n, i) => <div key={i} className="mb-1 alert alert-warning alert-dismissible fade show" dangerouslySetInnerHTML={{ __html: sanitize(n ?? "") }} />)}
  </>)
}

export default observer(Notification)
