import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { verifyParticipant } from "../../api/ClientOrder";
import ParticipantIdentifier from "../../api/models/clientorder/ParticipantIdentifier";
import Program from "../../api/models/clientorder/Program";
import VerificationResponse from "../../api/models/clientorder/VerificationResponse";
import { APIError } from "../../api/ObservableFromFetch";
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef";

interface VerificationProps {
  participantId: number
  program: Program
  onNext: (participant: ParticipantIdentifier) => void
}

export default function Verification(props: VerificationProps) {
  const [, t] = useTranslationWithRef()
  const [code, setCode] = useState("")
  const [codeTouched, setCodeTouched] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const form = useRef<HTMLFormElement>(null)
  const verificationInputRef = useRef<HTMLInputElement>(null)
  const validCode = /^\d{6}$/.test(code)

  useEffect(() => {
    verificationInputRef.current?.focus()
  }, [])

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)
    verify()
  }

  const verify = () => {
    verifyParticipant({
      participantId: props.participantId,
      verificationCode: code,
    }).subscribe({
      next: (result: VerificationResponse) => {
        setSubmitting(false)
        props.onNext && props.onNext(result)
      },
      error: (e: APIError) => {
        toast.error(t(e.message, { data: e.data }))
        setSubmitting(false)
      }
    })
  }

  return <div className="d-flex flex-column justify-content-center align-items-center min-vh-60">
    <Form onSubmit={handleFormSubmit} ref={form}>
      <Card>
        <Card.Body>
          <Form.Group className="mb-3" controlId="verification">
            <p>{t(props.program.validateMobile ? "login.verification.sent_mobile" : "login.verification.sent_email")}</p>
            <Form.Label className="fw-bold text-uppercase">{t("login.verification.title")}</Form.Label>
            <Form.Control type="text" ref={verificationInputRef} value={code} minLength={6} maxLength={6}
              onBlur={() => setCodeTouched(true)} onChange={(e) => { setCodeTouched(true); setCode(e.target.value) }}
              autoComplete="one-time-code" className={!validCode && codeTouched ? "is-invalid" : ""} />
            {!validCode && codeTouched && <Form.Control.Feedback className="order-last" type="invalid">{t("login.verification.invalid")}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Button type="submit" className="mx-1" disabled={!validCode || submitting}>{t("login.save")}</Button>
          </Form.Group>
        </Card.Body>
      </Card>
    </Form>
  </div>
}
