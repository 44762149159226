import React, { useEffect, useReducer, useState } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import Order from "./ClientOrder/Order"
import { toast } from "react-toastify"
import { Subscription } from "rxjs"
import { getProgram } from "../api/ClientOrder"
import Program from "../api/models/clientorder/Program"
import { APIError } from "../api/ObservableFromFetch"
import { useTranslationWithRef } from "../hooks/useTranslationWithRef"
import Participant from "./ClientOrder/Participant"
import { AppRoute } from "../AppRoute"
import { useHistory } from "react-router"
import Verification from "./ClientOrder/Verification"
import { DatumLoader } from "./DataLoader"
import ParticipantResponse from "../api/models/clientorder/ParticipantResponse"
import ParticipantIdentifier from "../api/models/clientorder/ParticipantIdentifier"

enum OrderStep {
  ProgramInactive,
  Participant,
  ParticipantInactive,
  Verification,
  Order,
  //Finish,
}

export default function ClientOrder() {
  const history = useHistory()

  const [step, setStep] = useState<OrderStep>(OrderStep.ProgramInactive)
  const [participant, setParticipant] = useState<ParticipantIdentifier>()
  const [translationRef, t] = useTranslationWithRef()
  const [program, setProgram] = useReducer((state: DatumLoader<Program>, newState: Partial<DatumLoader<Program>>) => ({ ...state, ...newState }), { loading: true, loaded: false, data: undefined })
  const [message, setMessage] = useState<string | undefined>()

  useEffect(() => {
    const subscriptions = new Subscription()

    subscriptions.add(getProgram({ host: window.location.hostname }).subscribe({
      next: (result: Program) => {
        if (!result.programId) {
          setMessage(result.message || translationRef.current("clientOrder.programInactive"))
          setProgram({ loading: false })
          setStep(OrderStep.ProgramInactive)
        } else {
          setProgram({ loading: false, loaded: true, data: result })
          setStep(OrderStep.Participant)
        }
      },
      error: (e: APIError) => {
        setProgram({ loading: false })
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    }))

    return () => subscriptions.unsubscribe()
  }, [translationRef])

  const handleParticipantSubmit = (participant: ParticipantResponse) => {
    if (!participant?.participantId || !program.data) {
      return
    }
    setParticipant(participant)
    if (program.data.validateEmail || program.data.validateMobile || !participant.participantHash) {
      setStep(OrderStep.Verification)
    } else {
      setStep(OrderStep.Order)
    }
  }

  const handleOrderNext = () => {
    toast.success(t("clientOrder.registered"))
    history.push(AppRoute.ClientOrder)
  }

  const handleParticipantVerification = (participant: ParticipantIdentifier) => {
    setParticipant(participant)
    setStep(OrderStep.Order)
  }

  return <Container>
    <Row>
      <Col sm={12} className="my-3">
        {program.loading && <Spinner animation="border" role="status" size="sm" className="d-block mx-1" />}
        {step === OrderStep.ProgramInactive && <>{message}</>}
        {step === OrderStep.Participant && !!program.data && <Participant program={program.data} onSubmit={handleParticipantSubmit} />}
        {step === OrderStep.ParticipantInactive && <>{message}</>}
        {step === OrderStep.Verification && participant?.participantId
          && <Verification onNext={handleParticipantVerification} participantId={participant.participantId} program={program.data!} />}
        {step === OrderStep.Order && participant && <Order participant={participant} onNext={handleOrderNext} />}
      </Col>
    </Row>
  </Container>
}
