import React, { useCallback, useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { GetCoverLetter, SetCoverLetter } from "../api/CoverLetter"
import TestEvent from "../api/models/TestEvent"
import { SubHeader } from "./Layout/SubHeader"
import RangeReport from "./Results/RangeReport"
import ReportSelection, { Mode } from "./Results/ReportSelection"
import TextReport from "./Results/TextReport"
import HistoryReport from "./Results/HistoryReport"
import { Document, DocumentProps, Page, pdfjs } from "react-pdf"
import { setViewed } from "../api/TestEvent"
import OrderTestChecker from "./Results/OrderTestChecker"
import { Printer } from "react-bootstrap-icons"
import printPdf from "../utils/PrintPdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
type LoadCallback = Required<DocumentProps>['onLoadSuccess']

export default function Results() {
  const { t } = useTranslation()
  const [reportMode, setReportMode] = useState(Mode.Interactive)
  const [selectedTestEvent, setSelectedTestEvent] = useState<TestEvent>()
  const [showCoverLetter, setShowCoverLetter] = useState(false)
  const [pdfContent, setPdfContent] = useState("")
  const [viewedCoverLetter, setViewedCoverLetter] = useState(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const onDocumentLoadSuccess: LoadCallback = useCallback((pdf) => {
    setPageCount(pdf.numPages)
  }, [])

  useEffect(() => {
    if (selectedTestEvent && !selectedTestEvent?.disableCoverSheet) {
      const subscription = GetCoverLetter(selectedTestEvent.testEventId).subscribe({
        next: (result: string) => {
          if (result.length > 0) {
            setPdfContent(result)
            setShowCoverLetter(true)
          }
        },
        error: e => toast.error(t(e.message, { data: e.data }))
      })
      return () => subscription.unsubscribe()
    }
  }, [selectedTestEvent])

  useEffect(() => {
    if (selectedTestEvent && viewedCoverLetter && !showCoverLetter) {
      const subscription = SetCoverLetter(selectedTestEvent.testEventId).subscribe({
        next: () => setViewedCoverLetter(false)
      })
      return () => subscription.unsubscribe()
    }
  }, [selectedTestEvent, viewedCoverLetter, showCoverLetter])

  useEffect(() => {
    if (selectedTestEvent && !selectedTestEvent.viewed) {
      setViewed(selectedTestEvent.testEventId).subscribe({
        next: () => { selectedTestEvent.viewed = true },
        error: e => toast.error(t(e.message, { data: e.data }))
      })
    }
  }, [selectedTestEvent])
  
  return (<>
    <SubHeader>
      <ReportSelection mode={reportMode} testEvent={selectedTestEvent} onChangeReportMode={setReportMode}
        onChangeTestEvent={(e) => { setSelectedTestEvent(e); setViewedCoverLetter(false) }} />
    </SubHeader>
    {reportMode === Mode.Interactive && selectedTestEvent && <RangeReport testEvent={selectedTestEvent} />}
    {reportMode === Mode.Interpretive && selectedTestEvent && <TextReport testEvent={selectedTestEvent} />}
    {/*showCoverLetter && <CoverLetter content={pdfContent} onHide={() => setShowCoverLetter(false)} />*/}
    {reportMode === Mode.History && selectedTestEvent && <HistoryReport />}
    {showCoverLetter && <Modal show size="lg" fullscreen="lg-down" backdrop="static" onHide={() => setShowCoverLetter(false)} animation={false}>
      <Modal.Header>
        <Button variant="secondary" size="sm" onClick={() => printPdf(pdfContent, "CoverLetter")}><Printer />{t("print")}</Button>
        <Form.Check inline id="viewedCoverLetter" type="checkbox" className="fw-bold ms-3 h5" label={t("dontShowAgain")} checked={viewedCoverLetter}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setViewedCoverLetter(e.target.checked)} />    
        <Button className="btn-secondary" onClick={() => setShowCoverLetter(false)}>{t("registration.button.close")}</Button>   
      </Modal.Header>
      <Modal.Body className="p-0">
        <Document file={`data:application/pdf;base64,${pdfContent}`} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(pageCount), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} className="text-center" width={798} />
          ))}
        </Document>
      </Modal.Body>
    </Modal>}
    {selectedTestEvent && <OrderTestChecker testEventId={selectedTestEvent.testEventId} />}
  </>)
}
