import * as React from "react";
import { NavMenu } from "../Layout/NavMenu";
import Logo from "../../assets/images/logo.png"
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { AppRoute } from "../../AppRoute";
import { useRegistrationStore } from "../../stores/StoreProvider";
import { Link } from "react-router-dom";

interface HeaderProps {
  banner?: string
  logo?: string
  nav?: boolean
  showLinks?: boolean
}

export function Header(props: HeaderProps/*React.PropsWithChildren<HeaderProps>*/) {
  const { t } = useTranslation()
  const registrationStore = useRegistrationStore()
  const implementation = registrationStore.implementation

  return (
    <header>
      <div className="brand-header" style={ props?.banner ? { backgroundImage: `url("${props?.banner}")` } : {}}>
        <Container>
          <Row>
            <Col lg={3} md={4} className="logo col-auto me-auto"><img src={props.logo || Logo} alt={t("title")} className="logo" /></Col>
            {props.showLinks && <Col md={8} className="logo col-auto p-0">
              <Row className="justify-content-center m-0 pt-2">
              <Link to={AppRoute.Results} className="px-1 col-auto text-primary text-center text-decoration-none" title={t([`header.home_${implementation}`, "header.home"]).trim()}>
                <img src={implementation ? `/images/Icon/home_${implementation}.png` : "/images/Icon/home.png"} alt={t("header.home")} className="menu-item my-2 my-md-0 my-lg-2" />
                <span className="d-none d-md-block d-lg-inline">{t([`header.home_${implementation}`, "header.home"]).trim()}</span>
              </Link>              
              <Link to={AppRoute.Results} className="px-1 col-auto text-primary text-center text-decoration-none" title={t("header.results")}>
                <img src="/images/Icon/results.png" alt={t("header.results")} className="menu-item my-2 my-md-0 my-lg-2" />
                <span className="d-none d-md-block d-lg-inline">{t("header.results")}</span>
              </Link>
              </Row>
            </Col>}
          </Row>
        </Container>
      </div>
      {<NavMenu {...props} />}
      {/* {props.children} */}
    </header>
  )
}
