import React, { createContext, ReactElement, ReactNode } from "react"
import AuthStore from "./AuthStore"
import RegistrationStore from "./RegistrationStore"
import rootStore, { RootStore } from "./RootStore"
import StripeStore from "./StripeStore"

export const StoreContext = createContext<RootStore>(rootStore)

interface StoreProviderProps {
  children: ReactNode
}

export function StoreProvider({ children }: StoreProviderProps): ReactElement  {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
}

export function useAuthStore(): AuthStore {
  return React.useContext(StoreContext).authStore
}

export function useStripeStore(): StripeStore {
  return React.useContext(StoreContext).stripeStore
}

export function useRegistrationStore(): RegistrationStore {
  return React.useContext(StoreContext).registrationStore
}
