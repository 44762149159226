import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from 'react-i18next'
import { BirthdayInput } from '../BirthdayInput'
import en from "date-fns/locale/en-US"
import es from "date-fns/locale/es"
import { useAuthStore } from '../../stores/StoreProvider';
registerLocale("en", en)
registerLocale("es", es)

interface DateOfBirthProps {
  onChange: (value: Date | null) => void
  onSubmit?: () => void
  title?: string
  skipFeedback?: boolean
  initialDate?: Date
}

export default function DateOfBirth(props: DateOfBirthProps) {
  const { t } = useTranslation()
  const [birthdate, setBirthdate] = useState<Date | null>(props.initialDate || null)
  const [birthdateValid, setBirthdateValid] = useState(false)
  const [birthdateTouched, setBirthdateTouched] = useState(false)
  const authStore = useAuthStore()

  var date = new Date()
  date.setFullYear( date.getFullYear() - 40 )

  return (
    <>
      <Form.Label className="fw-bold text-uppercase">{props.title || t("login.dateOfBirth.title")}</Form.Label>
      <DatePicker
        locale={authStore.language}
        selected={birthdate || props.initialDate}
        onChange={date => {
          setBirthdate(date)
          setBirthdateTouched(true)
          setBirthdateValid(date !== null)
          props.onChange(date)
        }}
        onBlur={() => {
          setBirthdateTouched(true)
        }}
        maxDate={new Date()}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className="form-control"
        wrapperClassName={birthdateTouched && !birthdateValid ? "is-invalid" : ""}
        customInput={<BirthdayInput />}
        onKeyDown={(e) => { if (e.key === "Enter" && props.onSubmit) { props.onSubmit() } }}
        openToDate={date}
      />
      {birthdateTouched && !birthdateValid && !props.skipFeedback && <Form.Control.Feedback className="order-last" type="invalid">{t("login.dateOfBirth.invalid")}</Form.Control.Feedback>}
    </>
  )
}
