import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import AccountMessage from "../../../api/models/registration/AccountMessage"
import { sanitize } from "../../../formatters/Sanitize"

export interface AccountMessageModalProps {
  accountMessage: AccountMessage
  onHide: () => void
}

export default function AccountMessageModal(props: AccountMessageModalProps) {
  const { t } = useTranslation()
  const { accountMessage, onHide } = props

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        {accountMessage.title && <Modal.Title>{accountMessage.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: sanitize(accountMessage.body ?? "") }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("registration.button.close")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
