import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { sanitize } from "../../../formatters/Sanitize"

export interface AccountCustomMessageProps {
  message: string
  onNext: () => void
}

export default function AccountCustomMessage(props: AccountCustomMessageProps) {
  const { t } = useTranslation()

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static">
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: sanitize(props.message) }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onNext}>{t("registration.button.next")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
