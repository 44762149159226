import { useFormikContext } from "formik"
import React, { useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import PhysicalMeasurements from "../../api/models/registration/PhysicalMeasurements"

export const HeightRange = [48, 96]
export const WeightRange = [80, 450]
export const SystolicRange = [0, 139]
export const DiastolicRange = [61, 89]

export type PhysicalMeasurementsWithConfirmation = PhysicalMeasurements & {
  heightConfirmed: boolean
  weightConfirmed: boolean
  bloodPressureConfirmed: boolean
}

export default function PhysicalMeasurementsForm() {
  const { t } = useTranslation()
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext<PhysicalMeasurementsWithConfirmation>()
  const [displayHeigthConfirmed, setDisplayHeigthConfirmed] = useState<boolean>(false)
  const [displayWeigthConfirmed, setDisplayWeigthConfirmed] = useState<boolean>(false)
  const [displayBloodPressureConfirmed, setDisplayBloodPressureConfirmed] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState<string[]>([])

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var value = e.target.value
    if (value === "") {
      setFieldValue(e.target.id, "")
      return
    }
    if (/^[0-9]*$/.test(value)) {
      setFieldValue(e.target.id, +value)
    }
    setFieldValue(`${e.target.id}Confirmed`, false)
  }

  const handleBloodPressureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var value = e.target.value
    if (/^[0-9/]*$/.test(value)) {
      setFieldValue(e.target.id, value)
    }
    setFieldValue("bloodPressureConfirmed", false)
  }

  const formattedValue = (value?: number) => {
    if (value === 0) {
      return value
    }
    return value || ""
  }

  const validateHeight = (value: string) => {
    let displayConfirm = false
    if (value !== "") {
      const intValue = +value
      if (intValue < HeightRange[0] || intValue > HeightRange[1]) {
        displayConfirm = true
      }
    }
    if (displayConfirm && !displayHeigthConfirmed) {
      setFieldValue("heightConfirmed", false)
      setConfirmationMessage([t("registration.measurements.heightConfirmation", { from: HeightRange[0], to: HeightRange[1] })])
      setShowConfirmation(true)
    }
    setDisplayHeigthConfirmed(displayConfirm)
  }

  const handleHeightBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.id, true)
    validateHeight(e.target.value ?? "")
  }

  const validateWeight = (value: string) => {
    let displayConfirm = false
    if (value !== "") {
      const intValue = +value
      if (intValue < WeightRange[0] || intValue > WeightRange[1]) {
        displayConfirm = true
      }
    }
    if (displayConfirm && !displayWeigthConfirmed) {
      setFieldValue("weightConfirmed", false)
      setConfirmationMessage([t("registration.measurements.weightConfirmation", { from: WeightRange[0], to: WeightRange[1] })])
      setShowConfirmation(true)
    }
    setDisplayWeigthConfirmed(displayConfirm)
  }

  const handleWeightBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFieldTouched(e.target.id, true)
    validateWeight(e.target.value ?? "")
  }

  const validateBloodPressure = (value: string) => {
    setFieldTouched("bloodPressure", true)
    let displayConfirm = false
    const messages: string[] = []
    if (value !== "") {
      const groups = /^(\d{1,3})\/(\d{1,3})$/.exec(value ?? "")
      if (groups && groups.length === 3) {
        const systolic = +groups![1]
        const diastolic = +groups![2]

        if (systolic < SystolicRange[0] || systolic > SystolicRange[1]) {
          displayConfirm = true
          messages.push(t("registration.measurements.systolicConfirmation", { from: SystolicRange[0], to: SystolicRange[1] }))
        }

        if (diastolic < DiastolicRange[0] || diastolic > DiastolicRange[1]) {
          displayConfirm = true
          messages.push(t("registration.measurements.diastolicConfirmation", { from: DiastolicRange[0], to: DiastolicRange[1] }))
        }
      }
    }
    if (displayConfirm && !displayBloodPressureConfirmed) {
      setFieldValue("bloodPressureConfirmed", false)
      setConfirmationMessage(messages)
      setShowConfirmation(true)
    }
    setDisplayBloodPressureConfirmed(displayConfirm)
  }

  const handleBloodPressureBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validateBloodPressure(e.target.value ?? "")
    setFieldTouched("bloodPressure", true)
  }

  const handleHeightKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      validateHeight(values.height?.toString() ?? "")
    }
  }

  const handleWeightKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      validateWeight(values.weight?.toString() ?? "")
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      validateBloodPressure(values.bloodPressure ?? "")
    }
  }

  return <>
    <h5 className="my-3">{t("registration.measurements.title")}</h5>
    <Form.Group as={Row} className="my-3" controlId="height">
      <Col xs={6} md={5} lg={4} >
        <Form.Label className="col-form-label">{t("registration.measurements.height")}</Form.Label></Col>
      <Col className="col-auto" >
        <Form.Control type="text" inputMode="numeric" value={formattedValue(values.height)} maxLength={2}
          onChange={handleNumberChange} onBlur={handleHeightBlur} onKeyDown={handleHeightKeyDown}
          isInvalid={!!errors.height && touched.height} />
        <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
        {displayHeigthConfirmed && <Form.Check label={t("registration.measurements.heightConfirmed")}
          checked={values.heightConfirmed}
          className=""
          id="heightConfirmed"
          type="checkbox"
          onBlur={handleBlur} onChange={handleChange} />}
      </Col>
    </Form.Group>
    <Form.Group as={Row} className="my-3" controlId="weight">
      <Col xs={6} md={5} lg={4} >
        <Form.Label className="col-form-label">{t("registration.measurements.weight")}</Form.Label></Col>
      <Col className="col-auto" >
        <Form.Control type="text" inputMode="numeric" value={formattedValue(values.weight)} maxLength={3}
          onChange={handleNumberChange} onBlur={handleWeightBlur} onKeyDown={handleWeightKeyDown}
          isInvalid={!!errors.weight && touched.weight} />
        <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
        {displayWeigthConfirmed && <Form.Check label={t("registration.measurements.weightConfirmed")}
          checked={values.weightConfirmed}
          className=""
          id="weightConfirmed"
          type="checkbox"
          onBlur={handleBlur} onChange={handleChange} />}
      </Col>
    </Form.Group>
    <Form.Group as={Row} className="my-3" controlId="waistCircumference">
      <Col xs={6} md={5} lg={4} >
        <Form.Label className="col-form-label">{t("registration.measurements.waistCircumference")}</Form.Label></Col>
      <Col className="col-auto" >
        <Form.Control type="text" inputMode="numeric" value={formattedValue(values.waistCircumference)} maxLength={3} onChange={handleNumberChange} onBlur={handleBlur}
          isInvalid={!!errors.waistCircumference && touched.waistCircumference} />
        <Form.Control.Feedback type="invalid">{errors.waistCircumference}</Form.Control.Feedback>
      </Col>
    </Form.Group>
    <Form.Group as={Row} className="my-3" controlId="bloodPressure">
      <Col xs={6} md={5} lg={4} >
        <Form.Label className="col-form-label">{t("registration.measurements.bloodPressure")}</Form.Label></Col>
      <Col className="col-auto" >
        <Form.Control type="text" value={values.bloodPressure || ""} maxLength={10} onChange={handleBloodPressureChange} onBlur={handleBloodPressureBlur}
          isInvalid={!!errors.bloodPressure && touched.bloodPressure} onKeyDown={handleKeyDown} />
        <Form.Control.Feedback type="invalid">{errors.bloodPressure}</Form.Control.Feedback>
        {displayBloodPressureConfirmed && <Form.Check label={t("registration.measurements.bloodPressureConfirmed")}
          checked={values.bloodPressureConfirmed}
          className=""
          id="bloodPressureConfirmed"
          type="checkbox"
          onBlur={handleBlur} onChange={handleChange} />}
      </Col>
    </Form.Group>
    <Modal show={showConfirmation} centered size="lg">
      <Modal.Body>{confirmationMessage.map((message, index) => <p key={index}>{message}</p>)}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowConfirmation(false)}>{t("registration.button.ok")}</Button>
      </Modal.Footer>
    </Modal>
  </>
}
