import * as React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { CalendarDate } from "react-bootstrap-icons";
import InputMask from 'react-input-mask';

interface BirthdayInputProps {
  required?: boolean
  isInvalid?: boolean
  placeholder?: string
  value?: string
  onClick?: () => void
  onChange?: () => void
}

export class BirthdayInput extends React.Component<BirthdayInputProps> {
  render() {
    return (
      <InputGroup>
        <InputGroup.Text>
          <CalendarDate size={24} onClick={this.props.onClick} />
        </InputGroup.Text>
        <InputMask mask="99/99/9999" {...this.props}>
          <Form.Control type="text" />
        </InputMask>
      </InputGroup>
    )
  }
}
