import React, { useEffect, useReducer, useState } from "react"
import { Col, Collapse, Container, Form, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { delay, of, switchMap } from "rxjs"
import { calculate, getResults } from "../api/LifeAge"
import LifeAgeDetail from "../api/models/LifeAgeDetail"
import LifeAgeResults from "../api/models/LifeAgeResults"
import LifeAgeScore from "../api/models/LifeAgeScore"
import { APIError } from "../api/ObservableFromFetch"
import DataLoader from "./DataLoader"
import AgeGraph from "./LifeAge/AgeGraph"
import RangeSlider from "./LifeAge/RangeSlider"
import ScoresPopover from "./LifeAge/ScoresPopover"
import ValuesSection from "./LifeAge/ValuesSection"
import DonorInfo from "./Results/DonorInfo"
import ToggleSwitch from "./ToggleSwitch"
import ReportRange from "../api/models/RangeDetail"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"

interface SelectedResult {
  [key: string]: number
}

function mapSelectedResults(details: LifeAgeDetail[]) {
  const results: SelectedResult = {}
  details.forEach(detail => results[detail.testCode] = detail.numericResult || 0)
  return results
}
function getRange(range: ReportRange, score?: LifeAgeScore): ReportRange {
  if (!score) {
    return range
  }
  const recalculatedRanges = score?.recalculatedRanges || []
  const hasRecalculated = recalculatedRanges?.length > 0
  if (!hasRecalculated) {
    return range
  }
  const recalculatedRange = recalculatedRanges.find(item => item.testCode === range.testCode)
  if (recalculatedRange) {
    return recalculatedRange
  }

  return range
}

export default function LifeAge() {
  const { t } = useTranslation()
  const [details, setDetailsState] = useReducer((state: DataLoader<LifeAgeDetail>, newState: Partial<DataLoader<LifeAgeDetail>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: [] })
  const [age, setAge] = useState<number>(0)
  const [lifeAge, setLifeAge] = useState<LifeAgeScore>()
  const [calculating, setCalculating] = useState<boolean>(false)
  const [startCalculate, setStartCalculate] = useState<boolean>(false)
  const [smoker, setSmoker] = useState<boolean>(false)
  const [pregnant, setPregnant] = useState<boolean>(false)
  const [selectedResults, setSelectedResults] = useState<SelectedResult>({})
  const [initialSmoker, setInitialSmoker] = useState<boolean>(false)
  const [collapsed, setCollapsed] = useState<boolean>(false)

  useEffect(() => {
    setDetailsState({ loading: true })

    const subscription = getResults().subscribe({
      next: (result: LifeAgeResults) => {
        setAge(result.age)
        setLifeAge(result.lifeAge)
        setSmoker(result.smoker)
        setPregnant(result.pregnant)
        setInitialSmoker(result.smoker)
        setSelectedResults(mapSelectedResults(result.details))
        setDetailsState({ loading: false, loaded: true, data: result.details })
      },
      error: (e: APIError) => {
        setDetailsState({ loading: false })
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!startCalculate) {
      return
    }
    setCalculating(true)
    const subscription = of({ selectedResults, smoker }).pipe(delay(500),
      switchMap((params) => calculate({
        smoker: params.smoker,
        pregnant: pregnant,
        details: Object.entries(params.selectedResults).map(entry => {
          const [key, value] = entry
          return { testCode: key, numericResult: value }
        })
      })))
      .subscribe({
        next: (result) => {
          setCalculating(false)
          setLifeAge(result)
        },
        error: (e: APIError) => {
          setCalculating(false)
          toast.error(t(e.message, { data: e.data }))
        }
      })
    return () => subscription.unsubscribe()
  }, [startCalculate, selectedResults, smoker])

  const setResultCallback = (testCode: string) => (value: number) => {
    const newSelectedResults = { ...selectedResults }
    newSelectedResults[testCode] = value
    setSelectedResults(newSelectedResults)
    setStartCalculate(true)
  }

  const checkSmoker = (checked: boolean) => {
    setSmoker(checked)
    setStartCalculate(true)
  }

  const reset = () => {
    setSelectedResults(mapSelectedResults(details.data))
    setSmoker(initialSmoker)
  }

  const ageDifference = (lifeAge?.age || age) - age
  const sign = ageDifference > 0 ? "+" : ""

  return (<Container>
    <DonorInfo />
    <Row className="justify-content-end">
      <Col className="col-auto mx-2">
        {!collapsed && <ChevronUp onClick={() => setCollapsed(true)} />}
        {collapsed && <ChevronDown onClick={() => setCollapsed(false)} />}
      </Col>
    </Row>
    <Collapse in={!collapsed}>
      <div>
        <p>{t("lifeAge.description")}</p>
        <p>{t("lifeAge.description_note")}</p>
      </div>
    </Collapse>
    {details.loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
    {!details.loading && <>
      <Row className="sticky-top bg-white">
        <Col xs={12} lg={8}>
          <AgeGraph age={age} lifeAge={lifeAge?.age || age} loading={calculating} onReset={reset} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="mb-3">
          <Row><Form.Label className="fw-bold text-uppercase">{t("lifeAge.smoker")}</Form.Label></Row>
          <ToggleSwitch checked={smoker} onCheck={checkSmoker} on={t("yes")} off={t("no")} />
        </Col>
        <Col xs={6} lg={2} className="pt-4 mb-3">
          {lifeAge?.subScores ? <ScoresPopover values={lifeAge?.subScores} /> : <span className="fw-bold text-uppercase form-label">{t("lifeAge.ageDifference")}:</span>}
        </Col>
        <Col xs={6} lg={6} className="mb-3 align-self-end">
          <span className="text-warning fs-2">{calculating ? "--" : `${sign}${ageDifference.toFixed(1)}`}</span>
        </Col>
      </Row>
      {lifeAge?.values && <ValuesSection values={lifeAge?.values} />}
      <Row className="mb-3">
        {details.data.filter(detail => detail.range).map((detail, index) => {
          const range = getRange(detail.range!, lifeAge)
          return (
            <Col key={index} xs={12} lg={8} className="mb-3">
              <RangeSlider key={index} range={range!}
                numericResult={detail.numericResult}
                testCode={detail.testCode}
                name={detail.name}
                selectedResult={selectedResults[detail.testCode]}
                onChange={setResultCallback(detail.testCode)} />
            </Col>)
        })}
      </Row>
    </>}
  </Container>)
}
