import ApiUrl from "./ApiUrl"
import BuildFetchRequest from "./BuildFetchRequest"
import AuthenticateRequest from "./models/AuthenticateRequest"
import TermsOfUseRequest from "./models/TermsOfUseRequest"
import VerificationRequest from "./models/VerificationRequest"
import VerificationResponse from "./models/VerificationResponse"
import { ObservableFromFetch } from "./ObservableFromFetch"

export const AuthenticateLogin = (params: AuthenticateRequest) => ObservableFromFetch<boolean>("Authenticate/Login", JSON.stringify(params))
export const AuthenticateVerify = (params: VerificationRequest) => ObservableFromFetch<VerificationResponse>("Authenticate/Verify", JSON.stringify(params), 'post', { withCredentials: true })
export const authenticateTermsOfUse = (params: TermsOfUseRequest) => ObservableFromFetch<boolean>("Authenticate/TermsOfUse", JSON.stringify(params))
export const logout = () => {
  const init = BuildFetchRequest({ method: 'get', withCredentials: true })

  fetch(`${ApiUrl()}Authenticate/Logout`, init).catch(() => { })
}
