import { Buffer } from "buffer"

export default function printPdf(data: string, fileName: string) {
  const buff = Buffer.from(data, "base64")
  const fileData = new Blob([buff], { type: "application/pdf" })
  const fileURL = URL.createObjectURL(fileData)

  const link = document.createElement("a")
  link.style.display = "none"
  link.href = fileURL
  link.setAttribute("download", fileName)
  if (typeof link.download === undefined) {
    link.setAttribute("target", "_blank")
  }
  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    document.body.removeChild(link)
    URL.revokeObjectURL(fileURL)
  }, 200)
}
