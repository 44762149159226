import { useCallback } from "react"
import serviceWorkerStore from "../stores/ServiceWorkerStore"

const UPDATE_DELAY = 5 * 60 * 1000 // 5 min

export const useServiceWorkerUpdate = (callback: () => void, deps: React.DependencyList) => {
  return useCallback(() => {
    //skip update until delay
    if ((Date.now() - serviceWorkerStore.initializationTimestamp) < UPDATE_DELAY) {
      return callback()
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    const sw = navigator.serviceWorker
    if (!sw) {
      return callback()
    }

    sw.getRegistration(swUrl).then(r => {
      if (!r) {
        return callback()
      }
      serviceWorkerStore.softReload = true
      r.update()
        .then(() => callback())
        .catch(() => callback())
    })
  }, [deps])
}
