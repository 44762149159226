import React from "react"
import { TestResultId } from "../../api/models/TestResultId"

export interface CovidSectionBodyProps {
  testResultId: number
}

export default function CovidSectionBody(props: CovidSectionBodyProps) {

  const negativeBody = () => {
    return <>
      <p>
        This test is intended for use as an aid in identifying individuals with an antibody response to SARS-CoV-2, indicating recent or prior infection. This sample did not contain detectable SARS-CoV-2 antibodies. This negative result does not rule out SARS-CoV-2 infection. Correlation with other clinical and laboratory findings is recommended. Serologic results should not be used as the sole basis to diagnose or exclude recent SARS-CoV-2 infection.
      </p>
      <p>
        This test targets antibodies that are directed against the region of the novel coronavirus known as the spike protein. Many vaccines aim to induce an antibody response to the SARS-CoV-2 spike protein. Vaccination could result in a positive antibody test result. Please consult with your healthcare provider.
      </p>
      <p>
        This test has been authorized by the FDA under an Emergency Use Authorization (EUA) for use by authorized laboratories. This test has been authorized only for detecting the presence of antibodies against SARS-CoV-2, not for any other viruses or pathogens. This test is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under Section 564(b)(1) of the Act, 21 U.S.C. § 360bbb-3(b)(1), unless the authorization is terminated or revoked sooner. This test has not been FDA cleared or approved.
      </p>
      <p className="fst-italic fw-bold mb-0">Still Have Questions?</p>
      <p className="mb-0">Contact the Cynergy Care coordination team to schedule a session with a physician to discuss your results.</p>
      <p className="mb-0">Monday - Friday / 8:00am – 6:00pm CST, Phone: (844) 730-8820</p>
      <p className="mb-0">Ordering Physician: Dr. Murray Lappe</p>
    </>
  }

  const positiveBody = () => {
    return <>
      <p>
        This test is intended for use as an aid in identifying individuals with an antibody response to SARS-CoV-2, indicating recent or prior infection. Your results suggest recent or prior infection with SARS-CoV-2. Correlation with other clinical and laboratory findings is recommended. Serologic results should not be used as the sole basis to diagnose or exclude recent SARS-CoV-2 infection.
      </p>
      <p>
        This test targets antibodies that are directed against the region of the novel coronavirus known as the spike protein. Many vaccines aim to induce an antibody response to the SARS-CoV-2 spike protein.  Vaccination could result in a positive antibody test result. Please consult with your healthcare provider.
      </p>
      <p>
        This test should not be used to detect evidence of an active SARS-CoV-2 infection. If active infection is suspected, contact your physician for medical follow up.
      </p>
      <p>
        This test has not been FDA cleared or approved. This test has been authorized by the FDA under an Emergency Use Authorization (EUA) for use by authorized laboratories. This test has been authorized only for detecting the presence of antibodies against SARS-CoV-2, not for any other viruses or pathogens. This test is only authorized for the duration of the declaration that circumstances exist justifying the authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of COVID-19 under Section 564(b)(1) of the Act, 21 U.S.C. § 360bbb-3(b)(1), unless the authorization is terminated or revoked sooner.
      </p>
      <p className="fst-italic fw-bold mb-0">Still Have Questions?</p>
      <p className="mb-0">Contact the Cynergy Care coordination team to schedule a session with a physician to discuss your results.</p>
      <p className="mb-0">Monday - Friday / 8:00am – 6:00pm CST, Phone: (844) 730-8820</p>
      <p className="mb-0">Ordering Physician: Dr. Murray Lappe</p>
    </>
  }

  const quantityNotSufficientBody = () => {
    return <>
      <p>CRL was unable to obtain a Covid-19 Antibody result on your specimen because there was not enough blood to perform the test.</p>
      <p>If a credit card was used to order the testing, CRL will issue a full refund to that card.</p>
      <p>We apologize for any inconvenience.</p>
    </>
  }

  return <>
    {(() => {
      switch (props.testResultId) {
        case TestResultId.Pos:
        case TestResultId.Positive:
          return positiveBody()
        case TestResultId.Neg:
        case TestResultId.Negative:
          return negativeBody()
        case TestResultId.QuantityNotSufficient:
          return quantityNotSufficientBody()
        default:
          return undefined
      }
    })()}
  </>
}
