import * as React from "react"
import { useReducer, useState } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { saveAddress } from "../../../api/Address"
import Address from "../../../api/models/Address"
import State from "../../../api/models/State"
import { APIError } from "../../../api/ObservableFromFetch"
import { getStates } from "../../../api/States"
import { useTranslationWithRef } from "../../../hooks/useTranslationWithRef"
import DataLoader from "../../DataLoader"

export interface AddressProps {
  address?: Address
  testEventId: number
  onCancel: () => void
  onSubmit: () => void
}

export default function AddressForm(props: AddressProps) {
  const [translationRef, t] = useTranslationWithRef()
  const emptyAddress: Address = { address1: "", city: "", state: "", zip: "" }
  const [address, setAddress] = useState<Address>(props.address || emptyAddress)
  const [states, setStates] = useReducer((state: DataLoader<State>, newState: Partial<DataLoader<State>>) => ({ ...state, ...newState }), { loading: false, loaded: false, data: [] })
  const [touched, setTouched] = useState<string[]>([])
  const setField = (field: keyof Address, value: string) => {
    setAddress({ ...address, [field]: value })
    setTouched([...touched, field])
  }

  React.useEffect(() => {
    setStates({ loading: true })

    const subscription = getStates().subscribe({
      next: (result: State[]) => {
        setStates({ loading: false, loaded: true, data: result })
      },
      error: (e: APIError) => {
        setStates({ loading: false })
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [translationRef])

  function handleSubmit() {
    address && saveAddress(props.testEventId, address).subscribe({
      next: () => props.onSubmit(),
      error: (e: APIError) => toast.error(t(e.message, { data: e.data }))
    })
  }

  const validationClassName = (value: string, field: keyof Address) => touched.includes(field) ? value ? "valid" : "is-invalid" : ""
  const formValid = address.address1 && address.city && address.state && address.zip

  return (<>
    <Row className="mb-2">
      <Col>{t("order.address.title")}</Col>
    </Row>
    <Form.Label htmlFor="Address1">{t("order.address.address1")}</Form.Label>
    <Form.Control required type="text" value={address.address1} onBlur={() => setTouched([...touched, "address1"])} onChange={e => setField("address1", e.target.value)} className={validationClassName(address.address1, "address1")} />
    <Form.Label htmlFor="Address2">{t("order.address.address2")}</Form.Label>
    <Form.Control type="text" value={address.address2} onBlur={() => setTouched([...touched, "address2"])} onChange={e => setField("address2", e.target.value)} />
    <Form.Label htmlFor="City">{t("order.address.city")}</Form.Label>
    <Form.Control required type="text" value={address.city} onBlur={() => setTouched([...touched, "city"])} onChange={e => setField("city", e.target.value)} className={validationClassName(address.city, "city")} />
    <Row>
      <Col md={9}>
        <Form.Label htmlFor="State">{t("order.address.state")}</Form.Label>
        <Form.Select required aria-label={t("order.address.state")} value={address.state} onBlur={() => setTouched([...touched, "state"])} onChange={e => setField("state", e.target.value)} className={validationClassName(address.state, "state")}>
          {states.data.map(state => <option key={state.code} value={state.code}>{state.name}</option>)}
        </Form.Select>
      </Col>
      <Col md={3} className="ps-0">
        <Form.Label htmlFor="Zip">{t("order.address.zip")}</Form.Label>
        <Form.Control required maxLength={5} type="text" defaultValue={address.zip} onBlur={() => setTouched([...touched, "zip"])} onChange={e => setField("zip", e.target.value)} className={validationClassName(address.zip, "zip")} />
      </Col>
    </Row>
    <Row className="mt-2">
      <Col className="me-auto col-auto">
        <Button variant="secondary" onClick={props.onCancel}>{t("order.cancel")}</Button>
      </Col>
      <Col className="col-auto">
        <Button type="submit" onClick={handleSubmit} disabled={!formValid}>{t("order.purchase")}</Button>
      </Col>
    </Row>
  </>)
}