import * as React from "react";
import ReactPlayer from 'react-player'

export interface PlayerProps {
  fileName?: string
}

export default function Player(props: PlayerProps) {
  return (
    <>
      {props.fileName && <ReactPlayer stopOnUnmount controls url={`${window.location.origin}${props.fileName}`} width="25em" height="auto" />}
    </>
  )
}
