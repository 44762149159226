import React, { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import TestHistory from '../../api/models/TestHistory'
import { RangeColor, defaultFontFamily, defaultFontSize } from './ChartStyles'
import { mapSeries, rangeColor, resultRange } from './RangeChart'
import { useTranslation } from 'react-i18next'

interface HistoryChartProps {
  history: TestHistory
}

export default function HistoryChart(props: HistoryChartProps) {
  const { t } = useTranslation()

  const series = useMemo(() => {
    let prev = ""
    let suffix = ""
    const data = props.history.details.map(detail => {
      if (prev === detail.dateOfRegistration) {
        suffix = suffix + " "
      } else {
        prev = detail.dateOfRegistration
        suffix = ""
      }
      return ({ x: `${detail.dateOfRegistration}${suffix}`, y: detail.numericResult })
    })

    return [{
      data: [
        { x: "", y: null }, ...data, { x: " ", y: null }]
    }]
  }, [props.history.details])

  const mappedRange = useMemo(() => mapSeries(props.history.range, t), [props.history.range, t])

  const options: ApexOptions = useMemo((): ApexOptions => ({
    chart: {
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      fontFamily: defaultFontFamily,
      background: RangeColor.lightBackground
    },

    states: {
      hover: {
        filter: {
          type: "none"
        }
      },
      active: {
        filter: {
          type: "none"
        }
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true,
      custom: ({ series, dataPointIndex }) => {
        const numericResult = series[0][dataPointIndex]
        if (numericResult === null) {
          return ""
        }
        const range = resultRange(mappedRange, numericResult || 0)
        if (!range) {
          return ""
        }
        const borders = range.fixedRange
        const name = range.name
        const from = borders[0]
        const to = borders[1]
        return `<div class="apexcharts-tooltip-rangebar"><div><span class="series-name series-name-${range.name.toLowerCase()}">${name} (${from} - ${to})</span></div></div>`;
      },
      marker: {
        show: false
      }
    },
    colors: [RangeColor.line],
    dataLabels: {
      enabled: true,
      background: {
        opacity: 1,
        dropShadow: {
          enabled: true
        }
      },
      style: {
        fontSize: "16",
        colors: [({ series, dataPointIndex }: { series: number[][], dataPointIndex: number }) => {
          const numericResult = series[0][dataPointIndex]
          if (props.history.range) {
            return rangeColor(mappedRange, numericResult || 0, RangeColor.out)[0]
          }
          return RangeColor.annotation
        }]
      }
    },
    yaxis: {
      min: props.history.range?.scaleMin,
      max: props.history.range?.scaleMax,
      tickAmount: props.history.range ? 5 : undefined,
      labels: {
        style: {
          fontSize: defaultFontSize
        }
      }
    },
    xaxis: {
      type: "category",
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      },
      labels: {
        rotate: -30,
        rotateAlways: props.history.details.length > 7
      },
      axisTicks: {
        show: false
      }
    },
    markers: {
      size: 0,
      showNullDataPoints: false,
      hover: {
        size: 0
      }
    }
  }), [props.history, mappedRange])

  return (<>
    <p className="fw-bold text-uppercase mb-2">{props.history.name}</p>
    <ReactApexChart series={series} options={options} height={320} type="line"></ReactApexChart>
  </>)
}
