import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../AppRoute";
import { Link } from "react-router-dom"

export function Footer() {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <Container>
        <Row className="p-3">
          <Col sm={12} md={6} className="p-0">
            &copy; {currentYear} Clinical Reference Laboratory, Inc.
          </Col>
          <Col sm={12} md={6} className="p-0">
            <div className="float-md-end">
              <Link className="mx-1 me-3" to={AppRoute.ClientOrder}>{t("footer.clientOrder")}</Link>
              <Link className="mx-1 me-3" to={AppRoute.RegistrationAlt}>{t("footer.registration")}</Link>
              <Link className="mx-1 me-3" to={AppRoute.Results}>{t("footer.results")}</Link>
              <a className="mx-1 me-3" href={AppRoute.FAQ} target="_blank">FAQ</a>
              <a className="mx-1 me-3" href="https://www.crlcorp.com/notice-of-privacy-practices/" target="_blank" rel="noopener noreferrer">{t("footer.privacyPolicy")}</a>
              <a className="mx-1" href="https://www.crlcorp.com/terms-of-use/" target="_blank" rel="noopener noreferrer">{t("footer.termsOfUse")}</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
