import React from "react"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { useTranslation } from "react-i18next"

export interface AgeChartProps {
  title: string
  age: number
  color: string
  loading: boolean
}

const defaultFontFamily = 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

export default function AgeChart(props: AgeChartProps) {
  const { t } = useTranslation()

  const options: ApexOptions = {
    chart: {
      selection: {
        enabled: false
      },
      fontFamily: defaultFontFamily
    },
    title: {
      text: props.title,
      align: 'center'
    },
    colors: [props.color],
    states: {
      hover: {
        filter: {
          type: "none"
        }
      },
      active: {
        filter: {
          type: "none"
        }
      },
    },
    fill: {
      opacity: 1
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%'
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            offsetY: 10
          },
          total: {
            show: true,
            label: props.age.toString(),
            color: props.loading ? "#dee2e6" : props.color,
            fontSize: "32",
            formatter: function () {
              return props.loading ? t("lifeAge.calculate") : t("lifeAge.years")
            }
          }
        }
      }
    }
  }
  return (<ReactApexChart series={[props.age]} options={options} height={240} type="radialBar"></ReactApexChart>)
}
