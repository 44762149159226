import React, { Fragment } from "react"
import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import LifeAgeValue from "../../api/models/LifeAgeValue"

interface ScoresPopoverProps {
  values: LifeAgeValue[]
}

export default function ScoresPopover({ values }: ScoresPopoverProps) {
  const { t } = useTranslation()

  return (<OverlayTrigger trigger="click" placement="bottom" rootClose overlay={
    <Popover className="popover-under-sticky">
      <Popover.Header as="h3">{t("lifeAge.scores")}</Popover.Header>
      <Popover.Body>
        <Row>
          {values?.map(item => <Fragment key={item.name}>
            <Col xs={4} className="fw-bold">{item.name}:</Col>
            <Col xs={2} className="text-end ps-0">{item.value}</Col>
          </Fragment>)}
        </Row>
      </Popover.Body>
    </Popover>}>
    <Button className="text-decoration-none fw-bold text-uppercase form-label" variant="">{t("lifeAge.ageDifference")}:</Button>
  </OverlayTrigger>)
}
