export function ApiHostName(): string {
  //if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //  return "https://localhost:44323";
  //}

  return (`${window.location.protocol}//${ window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}${process.env.PUBLIC_URL}`)  
}

export default function ApiUrl(): string {
  return `${ApiHostName()}/api/`
}
