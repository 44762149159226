import { useFormik } from "formik"
import * as Yup from 'yup'
import React, { useMemo, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import DonorData from "../../api/models/registration/DonorData"
import { BLUESTONE, Implementation } from "../../api/models/registration/Implementation"
import DemographicsConfigurationResponse from "../../api/models/registration/DemographicsConfigurationResponse"
import MessageModal, { Message, MessageType } from "./Modals/MessageModal"
import PhoneNumber, { PhoneNumberHandle } from "../Login/PhoneNumber"
import DateOfBirth from "../Login/DateOfBirth"
import FormatLocalISODateString from "../../formatters/FormatLocalISODateString"
import { ConvertToDate } from "../../formatters/ConvertToDate"
import { BarcodeType } from "../../api/models/registration/BarcodeType"
import { AnyObject } from "yup/lib/types"
import { InsuranceRelationshipId } from "../../api/models/InsuranceRelationshipId"
import DemographicsDonorData from "../../api/models/registration/DemographicsDonorData"
import Country from "../../api/models/Country"

interface DonorFormProps {
  implementation?: Implementation
  verified: boolean
  donorData: DonorData
  configuration: DemographicsConfigurationResponse
  displayEmployeeId: boolean
  requireEmployeeId: boolean
  employeeIdLabel?: string
  disableEmployeeId: boolean
  needToSpecifyEmployeeType: boolean
  barcodeType: BarcodeType
  displayInsuranceSection: boolean
  onSubmit: (donor: DemographicsDonorData) => void
  countries: Country[]
}

function getAge(dateOfBirth: string) {
  const today = new Date()
  const date = new Date(dateOfBirth)
  let age = today.getFullYear() - date.getFullYear()
  var monthDiff = today.getMonth() - date.getMonth()
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
    age--
  }
  return age
}

function child(dateOfBirth: string) {
  if (!dateOfBirth) {
    return false
  }
  return getAge(dateOfBirth) < 18
}

function guardianRequiredTest(value: string | number | boolean | null | undefined, context: Yup.TestContext<AnyObject>) {
  const required = context.parent.underGuardianCare || child(context.parent.dateOfBirth)
  return !required || (required && !!value)
}

function guardianRequiredPhone(value: string | number | boolean | null | undefined, context: Yup.TestContext<AnyObject>) {
  const required = context.parent.underGuardianCare || child(context.parent.dateOfBirth)
  return !required || (required && context.parent.guardianPhoneValid && !!value)
}

function insuranceInfoRequired(value: string | number | null | undefined, context: Yup.TestContext<AnyObject>) {
  if (![InsuranceRelationshipId.Spouse, InsuranceRelationshipId.Child, InsuranceRelationshipId.Other].includes(context.parent.relationshipId || 0)) {
    return true
  }

  return !!value
}

function relationshipRequires(relId: number) {
  if ([InsuranceRelationshipId.Spouse, InsuranceRelationshipId.Child, InsuranceRelationshipId.Other].includes(relId || 0)) {
    return true
  }
  return false
}

export default function DonorForm(props: DonorFormProps) {
  const { t } = useTranslation()
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const message = useRef<Message | undefined>(undefined)
  const phoneRef = useRef<PhoneNumberHandle>(null)
  const initialInsurancePhone = useRef<string>(props.donorData.insurance?.phone ?? "")

  const { implementation } = props

  const bluestone = useMemo(() => props.implementation === BLUESTONE, [props.implementation])
  const wellness = props.barcodeType === BarcodeType.Wellness

  const validationSchema = useMemo(() => {
    const fieldRequiredMessage = t("registration.validation.fieldRequired")
    return Yup.object().shape({
      firstName: Yup.string().nullable(true).required(fieldRequiredMessage),
      lastName: Yup.string().nullable(true).required(fieldRequiredMessage),
      employeeId: props.displayEmployeeId && props.requireEmployeeId && !props.disableEmployeeId
        ? (bluestone
          ? Yup.string().nullable(true).required(fieldRequiredMessage).matches(/^(e|E|c|C)\d{6}$/, t("registration.validation.bluestoneEmployeeId"))
          : Yup.string().nullable(true).required(fieldRequiredMessage))
        : Yup.string().nullable(true),
      address1: Yup.string().nullable(true).required(fieldRequiredMessage),
      address2: Yup.string().nullable(true),
      city: Yup.string().nullable(true).required(fieldRequiredMessage),
      stateCodeId: Yup.number().test("stateCodeId", fieldRequiredMessage, (value) => (value ?? 0) > 0),
      zip: Yup.string().nullable(true).required(fieldRequiredMessage)
        .min(5, t("registration.validation.minLength", { count: 5 }))
        .matches(/^\d{5}([-]?\d{4})?$/, t("registration.validation.zipCode")),
      yesNoSelection: props.configuration.yesNoOptions ? Yup.string().nullable(true).required(fieldRequiredMessage) : Yup.string().nullable(true),
      mobile: Yup.string().nullable(true).test("mobile", t("login.phoneNumber.invalid"), (value, context) => context.parent.phoneValid && !!value),
      email: Yup.string().nullable(true).matches(/^([a-zA-Z0-9_\-.`']+)@([a-zA-Z0-9_\-.`']+)\.([a-zA-Z]{2,5})$/, t("registration.validation.email")),
      dateOfBirth: Yup.string().nullable(true).required(fieldRequiredMessage),
      genderId: Yup.number().test("genderId", fieldRequiredMessage, (value) => (value ?? 0) > 0),
      genderIdentityId: bluestone ? Yup.number().test("genderIdentityId", fieldRequiredMessage, (value) => (value ?? 0) > 0) : Yup.number().nullable(true),
      sexualOrientationId: bluestone ? Yup.number().test("sexualOrientationId", fieldRequiredMessage, (value) => (value ?? 0) > 0) : Yup.number().nullable(true),
      raceId: !wellness ? Yup.number().test("raceId", fieldRequiredMessage, (value) => (value ?? 0) > 0) : Yup.number().nullable(true),
      ethnicityId: !wellness ? Yup.number().test("ethnicityId", fieldRequiredMessage, (value) => (value ?? 0) > 0) : Yup.number().nullable(true),
      guardianFirstName: Yup.string().nullable(true).test("guardianFirstName", fieldRequiredMessage, (value, context) => guardianRequiredTest(value, context)),
      guardianLastName: Yup.string().nullable(true).test("guardianLastName", fieldRequiredMessage, (value, context) => guardianRequiredTest(value, context)),
      guardianRelationshipId: Yup.number().test("guardianRelationshipId", fieldRequiredMessage, (value, context) => guardianRequiredTest(value, context)),
      guardianPhone: Yup.string().nullable(true).test("guardianPhone", t("login.phoneNumber.invalid"), (value, context) => guardianRequiredPhone(value, context)),
      performedByAdult: Yup.boolean().nullable(true).test("performedByAdult", t("registration.demographics.guardianOrOtherAdultMustPerformThisCollection"), (value, context) => guardianRequiredTest(value, context)),
      insurance: Yup.object().shape({
        planId: props.displayInsuranceSection ? Yup.number().nullable(true).test("planId", fieldRequiredMessage, (value) => (value ?? -1) >= 0) : Yup.number().nullable(true),
        otherPlanName: props.displayInsuranceSection ? Yup.string().nullable(true)
          .test("otherPlanName", fieldRequiredMessage, (value, context) => context.parent.planId > 0 || !!value) : Yup.string().nullable(true),
        policyGroup: props.displayInsuranceSection ? Yup.string().nullable(true)
          .test("policyGroup", fieldRequiredMessage, (value, context) => context.parent.planId > 0 || !!value) : Yup.string().nullable(true),
        policyIdNumber: props.displayInsuranceSection ? Yup.string().nullable(true).required(fieldRequiredMessage) : Yup.string().nullable(true),
        name: props.displayInsuranceSection ? Yup.string().nullable(true)
          .test("name", fieldRequiredMessage, (value, context) => insuranceInfoRequired(value, context)) : Yup.string().nullable(true),
        address: props.displayInsuranceSection ? Yup.string().nullable(true)
          .test("address", fieldRequiredMessage, (value, context) => insuranceInfoRequired(value, context)) : Yup.string().nullable(true),
        city: props.displayInsuranceSection ? Yup.string().nullable(true)
          .test("city", fieldRequiredMessage, (value, context) => insuranceInfoRequired(value, context)) : Yup.string().nullable(true),
        stateCodeId: props.displayInsuranceSection ? Yup.number().nullable(true).when("relationshipId", {
          is: (relId: number) => relationshipRequires(relId),
          then: Yup.number().test("stateCodeId", fieldRequiredMessage, (value) => (value ?? 0) > 0)
        })
          : Yup.number().nullable(true),
        zip: props.displayInsuranceSection ? Yup.string().nullable(true).when("relationshipId", {
          is: (relId: number) => relationshipRequires(relId),
          then: Yup.string().required(fieldRequiredMessage)
            .min(5, t("registration.validation.minLength", { count: 5 }))
            .matches(/^\d{5}([-]?\d{4})?$/, t("registration.validation.zipCode"))
        })
          : Yup.string().nullable(true),
        phone: props.displayInsuranceSection ? Yup.string().nullable(true).when("relationshipId", {
          is: (relId: number) => relationshipRequires(relId),
          then: Yup.string().test("phone", t("login.phoneNumber.invalid"), (value, context) => (context as any).from[1].value.insurancePhoneValid && !!value)
        })
          : Yup.string().nullable(true)
      })
    })
  }, [t, props.configuration.yesNoOptions, props.displayEmployeeId, props.disableEmployeeId, props.displayInsuranceSection, bluestone, wellness, props.requireEmployeeId])

  const formik = useFormik<DemographicsDonorData & {
    phoneValid: boolean, guardianPhoneValid: boolean, insurancePhoneValid: boolean
  }>({
    initialValues: {
      ...props.donorData, phoneValid: true, guardianPhoneValid: true, insurancePhoneValid: true,
      yesNoSelection: "",
      isEmployee: "0",
      underGuardianCare: false,
      performedByAdult: false,
      requireEmployeeId: false,
      employeeIdCancelled: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => { props.onSubmit(values) }
  })

  const handleBluestoneDescription = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    message.current = { body: t("registration.demographics.homeAddressesAreRequired"), type: MessageType.Text }
    setShowMessage(true)
  }

  const handleBluestoneInsuranceDescription = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    message.current = { body: t("registration.demographics.effectiveFeb"), type: MessageType.Html }
    setShowMessage(true)
  }

  const handlePhoneChange = (valid: boolean, mobileNumber: string, country: string) => {
    formik.setFieldValue("phoneValid", valid)
    formik.setFieldTouched("phoneValid", true, true).then(
      () => {
        formik.setFieldValue("mobile", mobileNumber)
        formik.setFieldTouched("mobile", true, true)
        formik.setFieldValue("countryAbbreviation", country)
      }
    )
  }

  const handleBirthDateChange = (date: Date | null) => {
    formik.setFieldValue("dateOfBirth", FormatLocalISODateString(date)).then(() => {
      formik.setFieldTouched("dateOfBirth", true, true)
    })
  }

  const handleGuardianPhoneChange = (valid: boolean, mobileNumber: string, country: string) => {
    formik.setFieldValue("guardianPhoneValid", valid)
    formik.setFieldTouched("guardianPhoneValid", true, true).then(
      () => {
        formik.setFieldValue("guardianPhone", mobileNumber)
        formik.setFieldTouched("guardianPhone", true, true)
        formik.setFieldValue("guardianCountryAbbreviation", country)
      }
    )
    if (phoneRef.current && valid) {
      phoneRef.current.setPhone(mobileNumber, country)
    }
  }

  const handleInsurancePhoneChange = (valid: boolean, mobileNumber: string, country: string) => {
    formik.setFieldValue("insurancePhoneValid", valid)
    formik.setFieldTouched("insurancePhoneValid", true, true).then(
      () => {
        formik.setFieldValue("insurance.phone", mobileNumber)
        formik.setFieldTouched("insurance.phone", true, true)
        formik.setFieldValue("insurance.countryAbbreviation", country)
      }
    )
  }

  const handleInsurancePlanName = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const planId = +e.target.value
    formik.setFieldValue("insurance.planId", planId)

    if (planId === 0 && props.donorData.insurance?.useInsuranceOtherForPrePopulation) {
      formik.setFieldValue("insurance.policyGroup", props.donorData.insurance?.policyGroup || "")
      return
    }

    const selectedPlan = planId > 0 ? insurancePlans.find(plan => plan.planId === planId) : undefined
    formik.setFieldValue("insurance.policyGroup", selectedPlan?.insuranceGroup || "")
  }

  const handleRelationshipChange = (value: number, checked: boolean) => {
    formik.setFieldValue("insurance.relationshipId", checked ? value : InsuranceRelationshipId.Undefined)

    if ([InsuranceRelationshipId.Spouse, InsuranceRelationshipId.Child, InsuranceRelationshipId.Other].includes(value || 0)) {
      formik.setFieldValue("performedByAdult", true)
    } else {
      formik.setFieldValue("insurance.name", "")
      formik.setFieldValue("insurance.address", "")
      formik.setFieldValue("insurance.city", "")
      formik.setFieldValue("insurance.stateCodeId", 0)
      formik.setFieldValue("insurance.zip", "")
      initialInsurancePhone.current = ""
    }
  }

  const handleUnderGuardianCareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var checked = e.target.checked
    formik.setFieldValue("underGuardianCare", checked)
    formik.setFieldValue("performedByAdult", checked)
  }

  const guardianRequired = formik.values.underGuardianCare || child(formik.values.dateOfBirth ?? "")
  const insuranceErrors = formik.errors.insurance as any
  const insuranceTouched = formik.touched.insurance as any

  const races = useMemo(() => {
    if (!bluestone) {
      return props.configuration.races
    }
    const unknownIndex = props.configuration.races.findIndex((race) => race.description === "Unknown/undetermined")
    if (unknownIndex > 0) {
      props.configuration.races[unknownIndex].description = "Prefer Not to State"
    }
    return props.configuration.races
  }, [props.configuration.races, bluestone])

  const ethnicities = useMemo(() => {
    if (!bluestone) {
      return props.configuration.ethnicities
    }
    const unknownIndex = props.configuration.ethnicities.findIndex((race) => race.description === "Unknown")
    if (unknownIndex > 0) {
      props.configuration.ethnicities[unknownIndex].description = "Prefer Not to State"
    }
    return props.configuration.ethnicities
  }, [props.configuration.ethnicities, bluestone])

  const insurancePlans = useMemo(() => {
    if (props.configuration.insurancePlans.length === 0) {
      return props.configuration.insurancePlans
    }
    props.configuration.insurancePlans[0].planId = -1

    const otherIndex = props.configuration.insurancePlans.findIndex((plan) => plan.planId === 0)
    if (otherIndex === -1) {
      props.configuration.insurancePlans.push({ planId: 0, name: "Other" })
    }
    return props.configuration.insurancePlans
  }, [props.configuration.insurancePlans])

  return <Form id="donorForm" onSubmit={formik.handleSubmit}>
    {(props.verified || bluestone) && <Row>
      <Col xs={12} lg={8} className="text-end">
        <h4>{t("registration.demographics.pleaseConfirmYourCurrentAddressAndEmail")}</h4>
      </Col>
    </Row>}
    {bluestone && <Row>
      <Col xs={12} lg={8} className="mt-1">
        We collect information to help us identify potential inequality (or disparities) in access at a population level. This information is also
        required for result reporting to the State of California and the County Department of Public Health to help track and trend the impact
        of COVID-19 on different communities.<br /><br />
        <h4>{t("registration.demographics.pleaseFillAllSectionsBelow")}</h4>
        You may select "prefer not to state"" and there are absolutely no negative consequences for choosing that answer. You must answer
        these questions to complete your registration<br />
      </Col>
    </Row>}
    {props.configuration.yesNoOptions && <Row>
      <Col xs={12} className="mt-1">
        <Form.Group controlId="yesNoSelection">
          <Form.Check type="radio" name="yesNoSelection" label={props.configuration.yesNoOptions?.yesText} value="1"
            checked={formik.values.yesNoSelection === "1"} onChange={formik.handleChange}
            isInvalid={!!formik.errors.yesNoSelection && formik.touched.yesNoSelection} />
          <Form.Check type="radio" name="yesNoSelection" label={props.configuration.yesNoOptions?.noText} value="0"
            checked={formik.values.yesNoSelection === "0"} onChange={formik.handleChange} />
          <Form.Control.Feedback type="invalid" className="d-block">{formik.errors.yesNoSelection}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>}
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.firstName")}</Form.Label>
        <Form.Control type="text" name="firstName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName || ""}
          maxLength={20}
          isInvalid={!!formik.errors.firstName && formik.touched.firstName} />
        <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.lastName")}</Form.Label>
        <Form.Control type="text" name="lastName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName || ""}
          maxLength={25}
          isInvalid={!!formik.errors.lastName && formik.touched.lastName} />
        <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
      </Col>
    </Row>
    {props.displayEmployeeId && <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{props.employeeIdLabel ? props.employeeIdLabel : t("registration.demographics.employeeId")}</Form.Label>
        <Form.Control type="text" name="employeeId"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.employeeId || ""}
          maxLength={implementation === BLUESTONE ? 7 : 25} disabled={props.disableEmployeeId}
          isInvalid={!!formik.errors.employeeId && formik.touched.employeeId} />
        <Form.Control.Feedback type="invalid">{formik.errors.employeeId}</Form.Control.Feedback>
      </Col>
      {props.needToSpecifyEmployeeType && <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold d-none d-sm-block">&nbsp;</Form.Label>
        <Row className="p-0 mt-1">
          <Col xs={5}>
            <Form.Check type="radio" name="isEmployee" className="fw-bold" label={t("registration.demographics.notEmployee")} value="1"
              checked={formik.values.isEmployee === "1"} onChange={formik.handleChange} />
          </Col>
          <Col xs={5}>
            <Form.Check type="radio" name="isEmployee" className="fw-bold" label={t("registration.demographics.employee")} value="0"
              checked={formik.values.isEmployee === "0"} onChange={formik.handleChange} />
          </Col>
        </Row>
      </Col>}
    </Row>}
    {bluestone && <Row>
      <Col className="mt-3">
        <a href="#/" onClick={handleBluestoneDescription}>Why are we asking for this?</a>
      </Col>
    </Row>}
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.addressLine1")}</Form.Label>
        <Form.Control type="text" name="address1"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address1 || ""}
          maxLength={60}
          isInvalid={!!formik.errors.address1 && formik.touched.address1} />
        <Form.Control.Feedback type="invalid">{formik.errors.address1}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.addressLine2")}</Form.Label>
        <Form.Control type="text" name="address2"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address2 || ""}
          maxLength={60}
          isInvalid={!!formik.errors.address2 && formik.touched.address2} />
        <Form.Control.Feedback type="invalid">{formik.errors.address2}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} lg={8} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.city")}</Form.Label>
        <Form.Control type="text" name="city"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city || ""}
          maxLength={40}
          isInvalid={!!formik.errors.city && formik.touched.city} />
        <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.state")}</Form.Label>
        <Form.Select name="stateCodeId" value={formik.values.stateCodeId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("stateCodeId", +e.target.value)}
          isInvalid={!!formik.errors.stateCodeId && formik.touched.stateCodeId} >
          {props.configuration.states.map(state => <option key={state.id} value={state.id}>{state.name}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.stateCodeId}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.zipCode")}</Form.Label>
        <Form.Control type="text" name="zip"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zip || ""}
          maxLength={10} minLength={5}
          isInvalid={!!formik.errors.zip && formik.touched.zip} />
        <Form.Control.Feedback type="invalid">{formik.errors.zip}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <PhoneNumber initialPhone={`${props.donorData.phoneCode ?? ""}${props.donorData.mobile ?? ""}`}
          ref={phoneRef} countries={props.countries}
          onChange={handlePhoneChange} skipFocus skipFeedback disabled={guardianRequired} />
        {!!formik.errors.mobile && formik.touched.mobile && <Form.Control.Feedback className="d-block" type="invalid">{formik.errors.mobile}</Form.Control.Feedback>}
        <div>
          {t("registration.employee.whichMayBeUsed")}
        </div>
        <Form.Group className="mt-1" controlId="acceptTextMessages" key="acceptTextMessages">
          <Form.Check name="acceptTextMessages" type="checkbox" label={t("registration.employee.acceptTextMessages")}
            checked={formik.values.acceptTextMessages} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        </Form.Group>
        <Form.Group className="mt-1" controlId="underGuardianCare" key="underGuardianCare">
          <Form.Check name="underGuardianCare" type="checkbox" label={t("registration.demographics.underGuardianCare")}
            checked={formik.values.underGuardianCare} onChange={handleUnderGuardianCareChange} onBlur={formik.handleBlur} />
        </Form.Group>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <Form.Label className="fw-bold">{t("registration.demographics.email")}</Form.Label>
        <Form.Control type="email" name="email"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email || ""}
          maxLength={250}
          isInvalid={!!formik.errors.email && formik.touched.email} />
        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <Form.Group controlId="dateOfBirth">
          <DateOfBirth title={t([`registration.employee.dateOfBirth_${implementation}`, "registration.employee.dateOfBirth"])}
            initialDate={ConvertToDate(props.donorData.dateOfBirth)} onChange={handleBirthDateChange} />
        </Form.Group>
        {!!formik.errors.dateOfBirth && formik.touched.dateOfBirth &&
          <Form.Control.Feedback className="d-block" type="invalid">{t("login.dateOfBirth.invalid")}</Form.Control.Feedback>}
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-2">
        <Form.Label className="col-form-label fw-bold">{bluestone ? t("registration.demographics.gender_bluestone") : t("registration.demographics.gender")}</Form.Label>
        <Form.Select name="genderId" value={formik.values.genderId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("genderId", +e.target.value)}
          isInvalid={!!formik.errors.genderId && formik.touched.genderId} >
          {props.configuration.genders.map(gender => <option key={gender.genderId} value={gender.genderId}>{gender.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.genderId}</Form.Control.Feedback>
      </Col>
    </Row>
    {guardianRequired && <Row>
      <Col className="d-block invalid-feedback mt-1">{t("registration.demographics.guardianOrOtherAdultMustPerformThisCollection")}</Col>
    </Row>}
    {bluestone && <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.genderIdentity")}</Form.Label>&nbsp;<span>*</span>
        <Form.Select name="genderIdentityId" value={formik.values.genderIdentityId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("genderIdentityId", +e.target.value)}
          isInvalid={!!formik.errors.genderIdentityId && formik.touched.genderIdentityId} >
          {props.configuration.genderIdentities.map(genderIdentity => <option key={genderIdentity.genderIdentityId} value={genderIdentity.genderIdentityId}>{genderIdentity.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.genderIdentityId}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.sexualOrientation")}</Form.Label>&nbsp;<span>*</span>
        <Form.Select name="sexualOrientationId" value={formik.values.sexualOrientationId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("sexualOrientationId", +e.target.value)}
          isInvalid={!!formik.errors.sexualOrientationId && formik.touched.sexualOrientationId} >
          {props.configuration.sexualOrientations.map(orientation => <option key={orientation.sexualOrientationId} value={orientation.sexualOrientationId}>{orientation.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.sexualOrientationId}</Form.Control.Feedback>
      </Col>
    </Row>}
    {!wellness && <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.race")}</Form.Label>&nbsp;<span>*</span>
        <Form.Select name="raceId" value={formik.values.raceId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("raceId", +e.target.value)}
          isInvalid={!!formik.errors.raceId && formik.touched.raceId} >
          {races.map(race => <option key={race.raceId} value={race.raceId}>{race.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.raceId}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.ethnicity")}</Form.Label>&nbsp;<span>*</span>
        <Form.Select name="ethnicityId" value={formik.values.ethnicityId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("ethnicityId", +e.target.value)}
          isInvalid={!!formik.errors.ethnicityId && formik.touched.ethnicityId} >
          {ethnicities.map(ethnicity => <option key={ethnicity.ethnicityId} value={ethnicity.ethnicityId}>{ethnicity.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.ethnicityId}</Form.Control.Feedback>
      </Col>
    </Row>}
    {guardianRequired && <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.guardianFirstName")}</Form.Label>
        <Form.Control type="text" name="guardianFirstName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.guardianFirstName || ""}
          maxLength={128}
          isInvalid={!!formik.errors.guardianFirstName && formik.touched.guardianFirstName} />
        <Form.Control.Feedback type="invalid">{formik.errors.guardianFirstName}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.guardianLastName")}</Form.Label>
        <Form.Control type="text" name="guardianLastName"
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.guardianLastName || ""}
          maxLength={128}
          isInvalid={!!formik.errors.guardianLastName && formik.touched.guardianLastName} />
        <Form.Control.Feedback type="invalid">{formik.errors.guardianLastName}</Form.Control.Feedback>
      </Col>
    </Row>}
    {guardianRequired && <Row>
      <Col sm={12} md={6} lg={4} className="mt-1">
        <Form.Label className="col-form-label fw-bold">{t("registration.demographics.guardianRelationship")}</Form.Label>
        <Form.Select name="guardianRelationshipId" value={formik.values.guardianRelationshipId}
          onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("guardianRelationshipId", +e.target.value)}
          isInvalid={!!formik.errors.guardianRelationshipId && formik.touched.guardianRelationshipId} >
          {props.configuration.relationships.map(relationship => <option key={relationship.relationshipId} value={relationship.relationshipId}>{relationship.description}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{formik.errors.guardianRelationshipId}</Form.Control.Feedback>
      </Col>
      <Col sm={12} md={6} lg={4} className="mt-2">
        <PhoneNumber fieldId="guardianPhone" initialPhone={`${props.donorData.guardianPhone ? (props.donorData.guardianPhoneCode ?? "") : ""}${props.donorData.guardianPhone ?? ""}`}
          title={t("registration.demographics.guardianPhone")} countries={props.countries}
          onChange={handleGuardianPhoneChange} skipFocus skipFeedback />
        {!!formik.errors.guardianPhone && formik.touched.guardianPhone && <Form.Control.Feedback className="d-block" type="invalid">{formik.errors.guardianPhone}</Form.Control.Feedback>}
      </Col>
    </Row>}
    {props.displayInsuranceSection && <>
      {bluestone && <Row>
        <Col className="mt-3">
          <a href="#/" onClick={handleBluestoneInsuranceDescription}>Why are we asking for this?</a>
        </Col>
      </Row>}
      <h4>{t("registration.demographics.insuranceInformation")}</h4>
      <Row>
        <Col sm={12} md={6} lg={4} className="mt-1">
          <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insurancePlanName")}</Form.Label>
          <Form.Select name="insurance.planId" value={formik.values.insurance?.planId}
            onBlur={formik.handleBlur} onChange={handleInsurancePlanName}
            isInvalid={!!insuranceErrors?.planId && insuranceTouched?.planId} >
            {insurancePlans.map(plan => <option key={plan.planId} value={plan.planId}>{plan.name}</option>)}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{insuranceErrors?.planId}</Form.Control.Feedback>
        </Col>
        {formik.values.insurance?.planId === 0 && <Col sm={12} md={6} lg={4} className="mt-1">
          <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insuranceOtherPlanName")}</Form.Label>
          <Form.Control type="text" name="insurance.otherPlanName"
            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.otherPlanName || ""}
            maxLength={50}
            isInvalid={!!insuranceErrors?.otherPlanName && insuranceTouched?.otherPlanName} />
          <Form.Control.Feedback type="invalid">{insuranceErrors?.otherPlanName}</Form.Control.Feedback>
        </Col>}
        <Col sm={12} md={6} lg={4} className="mt-1">
          <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insurancePolicyGroup")}</Form.Label>
          <Form.Control type="text" name="insurance.policyGroup"
            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.policyGroup || ""}
            maxLength={30} disabled={(formik.values.insurance?.planId ?? 0) > 0}
            isInvalid={!!insuranceErrors?.policyGroup && insuranceTouched?.policyGroup} />
          <Form.Control.Feedback type="invalid">{insuranceErrors?.policyGroup}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={4} className="mt-1">
          <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insurancePolicyIdNumber")}</Form.Label>
          <Form.Control type="text" name="insurance.policyIdNumber"
            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.policyIdNumber || ""}
            maxLength={30}
            isInvalid={!!insuranceErrors?.policyIdNumber && insuranceTouched?.policyIdNumber} />
          <Form.Control.Feedback type="invalid">{insuranceErrors?.policyIdNumber}</Form.Control.Feedback>
        </Col>
        <Col sm={12} md={6} lg={4} className="mt-1">
          <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insuranceYourRelationship")}</Form.Label>
          <Row>
            <Col xs={3}>
              <Form.Check type="checkbox" name="insurance.relationshipId_self" label={t("registration.demographics.self")}
                checked={formik.values.insurance?.relationshipId === InsuranceRelationshipId.Self}
                onChange={(e) => { handleRelationshipChange(InsuranceRelationshipId.Self, e.target.checked) }} />
            </Col>
            <Col xs={3}>
              <Form.Check type="checkbox" name="insurance.relationshipId_self" label={t("registration.demographics.spouse")}
                checked={formik.values.insurance?.relationshipId === InsuranceRelationshipId.Spouse}
                onChange={(e) => { handleRelationshipChange(InsuranceRelationshipId.Spouse, e.target.checked) }} />
            </Col>
            <Col xs={3}>
              <Form.Check type="checkbox" name="insurance.relationshipId_self" label={t("registration.demographics.child")}
                checked={formik.values.insurance?.relationshipId === InsuranceRelationshipId.Child}
                onChange={(e) => { handleRelationshipChange(InsuranceRelationshipId.Child, e.target.checked) }} />
            </Col>
            <Col xs={3}>
              <Form.Check type="checkbox" name="insurance.relationshipId_self" label={t("registration.demographics.other")}
                checked={formik.values.insurance?.relationshipId === InsuranceRelationshipId.Other}
                onChange={(e) => { handleRelationshipChange(InsuranceRelationshipId.Other, e.target.checked) }} />
            </Col>
          </Row>
        </Col>
      </Row>
      {[InsuranceRelationshipId.Spouse, InsuranceRelationshipId.Child, InsuranceRelationshipId.Other].includes(formik.values.insurance?.relationshipId || 0) && <>
        <Row>
          <Col sm={12} lg={8} className="mt-1">
            <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insuranceName")}</Form.Label>
            <Form.Control type="text" name="insurance.name"
              onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.name || ""}
              maxLength={50}
              isInvalid={!!insuranceErrors?.name && insuranceTouched?.name} />
            <Form.Control.Feedback type="invalid">{insuranceErrors?.name}</Form.Control.Feedback>
          </Col>
          <Col sm={12} lg={8} className="mt-1">
            <Form.Label className="col-form-label fw-bold">{t("registration.demographics.insuranceAddress")}</Form.Label>
            <Form.Control type="text" name="insurance.address"
              onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.address || ""}
              maxLength={60}
              isInvalid={!!insuranceErrors?.address && insuranceTouched?.address} />
            <Form.Control.Feedback type="invalid">{insuranceErrors?.address}</Form.Control.Feedback>
          </Col>
          <Col sm={12} lg={8} className="mt-1">
            <Form.Label className="col-form-label fw-bold">{t("registration.demographics.city")}</Form.Label>
            <Form.Control type="text" name="insurance.city"
              onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.city || ""}
              maxLength={40}
              isInvalid={!!insuranceErrors?.city && insuranceTouched?.city} />
            <Form.Control.Feedback type="invalid">{insuranceErrors?.city}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={4} className="mt-1">
            <Form.Label className="col-form-label fw-bold">{t("registration.demographics.state")}</Form.Label>
            <Form.Select name="insurance.stateCodeId" value={formik.values.insurance?.stateCodeId || 0}
              onBlur={formik.handleBlur} onChange={(e) => formik.setFieldValue("insurance.stateCodeId", +e.target.value)}
              isInvalid={!!insuranceErrors?.stateCodeId && insuranceTouched?.stateCodeId} >
              {props.configuration.states.map(state => <option key={state.id} value={state.id}>{state.name}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{insuranceErrors?.stateCodeId}</Form.Control.Feedback>
          </Col>
          <Col sm={12} md={6} lg={4} className="mt-1">
            <Form.Label className="col-form-label fw-bold">{t("registration.demographics.zipCode")}</Form.Label>
            <Form.Control type="text" name="insurance.zip"
              onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.insurance?.zip || ""}
              maxLength={10} minLength={5}
              isInvalid={!!insuranceErrors?.zip && insuranceTouched?.zip} ></Form.Control>
            <Form.Control.Feedback type="invalid">{insuranceErrors?.zip}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={4} className="mt-2">
            <PhoneNumber fieldId="insurancePhone" initialPhone={initialInsurancePhone.current}
              title={t("registration.demographics.insurancePhone")} countries={props.countries}
              onChange={handleInsurancePhoneChange} skipFocus skipFeedback />
            {!!insuranceErrors?.phone && insuranceTouched?.phone && <Form.Control.Feedback className="d-block" type="invalid">{insuranceErrors?.phone}</Form.Control.Feedback>}
          </Col>
        </Row>
      </>}
    </>}
    {guardianRequired && <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <Form.Check type="checkbox" id="performedByAdult" label={t("registration.demographics.collectionPerformedByAdult")}
          checked={formik.values.performedByAdult}
          onChange={formik.handleChange} />
        {!!formik.errors.performedByAdult && <Form.Control.Feedback className="d-block" type="invalid">{formik.errors.performedByAdult}</Form.Control.Feedback>}
      </Col>
    </Row>}
    {!wellness && <Row>
      <Col sm={12} md={6} lg={4} className="mt-3">
        <span>*</span>&nbsp;{t("registration.demographics.stateRequiredInformation")}
      </Col>
    </Row>}
    {showMessage && message.current && <MessageModal message={message.current} onHide={() => setShowMessage(false)} />}
  </Form >
}
