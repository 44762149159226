import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { getConsent } from "../../api/Registration";
import Consent from "../../api/models/Consent";
import { APIError } from "../../api/ObservableFromFetch";
import ConsentForm from "../Results/Modals/ConsentForm";
import BarcodeResponse from "../../api/models/registration/BarcodeResponse";
import { Implementation } from "../../api/models/registration/Implementation";
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef";

interface ConsentStepProps {
  title?: string
  onPrev: () => void
  onNext: (consentId: number) => void
  barcode: BarcodeResponse
  implementation?: Implementation
  initialAccepted: boolean
}

export default function ConsentStep(props: ConsentStepProps) {
  const [translationRef, t] = useTranslationWithRef()
  const [accepted, setAccepted] = useState(false)
  const [loading, setLoading] = useState(true)
  const [consent, setConsent] = useState<Consent | undefined>()
  const barcode = props.barcode

  useEffect(() => {
    const subscription = getConsent({ accountNumber: barcode.account, consentTypeID: barcode.barcodeType, testCode: barcode.testCode }).subscribe({
      next: (result: Consent) => {
        setConsent(result)
        setLoading(false)
      },
      error: (e: APIError) => {
        setLoading(false)
        toast.error(translationRef.current(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [barcode, translationRef])

  const handleNext = () => {
    props.onNext(consent?.consentId ?? 0)
  }

  return (
    <>
      <Row>
        <Col><h2 className="text-primary">{props.title}</h2></Col>
      </Row>
      {loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      {!loading && consent && <ConsentForm consent={consent} implementation={props.implementation}
        initialAccepted={props.initialAccepted}
        onCancel={() => { }} onSubmit={() => { }} onChange={(accepted) => setAccepted(accepted)} />}
      <div className="px-0 my-2">
        <Button variant="secondary" onClick={() => props.onPrev && props.onPrev()}>{t("registration.button.back")}</Button>&nbsp;
        <Button variant="primary" type="submit" disabled={!accepted} onClick={handleNext}>{t("registration.button.next")}</Button>
      </div>
    </>
  )
}
