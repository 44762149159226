export function ConvertToDate(value?: string) {
  if (!value) {
    return undefined
  }
  const date = new Date(value)
  if (isFinite(+date)) {
    return date
  }

  return undefined
}