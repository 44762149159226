import React from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import PostponeResult from "../../../api/models/registration/PostponeResult"
import { ConvertToDate } from "../../../formatters/ConvertToDate"
import FormatDate, { FormatDateTime } from "../../../formatters/FormatDate"

export interface PostponeProps {
  onHide: () => void
  postpone: PostponeResult
}

export default function Postpone(props: PostponeProps) {
  const { t } = useTranslation()
  const postpone = props.postpone

  const resultDate = ConvertToDate(postpone?.resultDate)
  const eligibleDate = resultDate ? FormatDate(new Date(resultDate.setDate(resultDate.getDate() + 90))) : ""

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static" onHide={props.onHide}>
      <Modal.Body>
        <h3>{t("registration.postpone.title")}</h3>
        <p className="fw-bold">{t("registration.postpone.message", { date: eligibleDate })}</p>
        <p className="fw-bold">Most Recent Test Result</p>
        <Row>
          <Col xs={12} md={6} className="fw-bold">
            {`${postpone.lastName},${postpone.firstName}`}
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={3}>{t("registration.postpone.currentTestStatus")}</Col>
          <Col xs={7} sm={9}>Completed</Col>
        </Row>
        <Row>
          <Col xs={5} sm={3}>{t("registration.postpone.result")}</Col>
          <Col xs={7} sm={9}>{postpone.resultDescription}</Col>
        </Row>
        <Row>
          <Col xs={5} sm={3}>{t("registration.postpone.collectionDate")}</Col>
          <Col xs={7} sm={9}>{FormatDateTime(postpone.dateOfRegistration)}</Col>
        </Row>
        <Row>
          <Col xs={5} sm={3}>{t("registration.postpone.reportedTime")}</Col>
          <Col xs={7} sm={9}>{FormatDateTime(postpone.resultDate)}</Col>
        </Row>
        <Row>
          <Col xs={12} className="small mt-3">
            <div className="bg-info">
              <b>You are automatically postponed from testing at an LA County Employee Testing Site for 90 days.</b><br />
              This is because during the 90-day period, ongoing viral shedding is likely to cause a positive test even after
              symptoms have resolved and you are no longer infectious. If you return to test at an LA County Employee
              Testing Site before the 90-day period expires, you will be turned away from testing.
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("registration.button.close")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
