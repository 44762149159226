import { BrowserMultiFormatReader } from "@zxing/library"

export class BarcodeScanner {
  private onSuccess: (value: string) => void
  private onCameraNotAvailable: () => void
  private cancelButtonName: string | undefined

  private cameraBlock: HTMLElement | undefined = undefined
  private cameraView: HTMLVideoElement | undefined = undefined
  private cameraCanvas: HTMLCanvasElement | undefined = undefined
  private cameraSensor: HTMLCanvasElement | undefined = undefined
  private cameraCancelButton: HTMLElement | undefined = undefined
  private cameraHintsContainer: HTMLElement | undefined = undefined
  private scanline: HTMLElement | undefined = undefined
  private currentlyScanning: boolean = false
  private updateInterval: number | undefined = undefined
  private scanInterval: number | undefined = undefined

  public constructor(onSuccess: (value: string) => void, onCameraNotAvailable: () => void, cancelButtonName?: string) {
    this.onSuccess = onSuccess
    this.onCameraNotAvailable = onCameraNotAvailable
    this.cancelButtonName = cancelButtonName
    this.createBasicLayout()
  }

  private createBasicLayout() {
    this.cameraBlock = document.createElement("div")
    this.cameraBlock.className = "barcode-scanner"
    this.cameraBlock.style.visibility = "hidden"
    document.body.appendChild(this.cameraBlock)

    this.cameraView = document.createElement("video");
    this.cameraView.autoplay = true
    this.cameraView.muted = true
    this.cameraView.setAttribute("muted", "true")
    const asAny = (this.cameraView as any)
    asAny.playsInline = true
    this.cameraBlock.appendChild(this.cameraView)

    this.cameraCanvas = document.createElement("canvas")
    this.cameraCanvas.className = "box"
    this.cameraBlock.appendChild(this.cameraCanvas)

    this.cameraSensor = document.createElement("canvas")
    this.cameraSensor.className = "sensor"
    this.cameraBlock.appendChild(this.cameraSensor)

    this.cameraCancelButton = document.createElement("button")
    this.cameraCancelButton.className = "cancel"
    this.cameraCancelButton.innerText = this.cancelButtonName || "Cancel"
    this.cameraCancelButton.onclick = this.cameraStop.bind(this)
    this.cameraBlock.appendChild(this.cameraCancelButton)

    this.cameraHintsContainer = document.createElement("div")
    this.cameraHintsContainer.className = "barcode-scanner-hints"
    this.cameraBlock.appendChild(this.cameraHintsContainer)
  }

  cameraStart() {
    //browser or security not capable
    if (navigator.mediaDevices == null) {
      this.onCameraNotAvailable()
      return false;
    }

    this.currentlyScanning = true

    //"constraints" determines which camera(s) to use
    //currenly only looking for rear camera on phones/devices
    //can be expanded in the future
    const constraints = { video: { facingMode: "environment" }, audio: false }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.cameraBlock!.style.visibility = "visible"
        this.cameraView!.srcObject = stream
        this.updateInterval = window.setInterval(this.updateCanvas.bind(this), 10);
        this.scanInterval = window.setInterval(this.scanVideoForBarcode.bind(this), 100);
        this.createHintObjects();
      })
      .catch(() => {
        this.onCameraNotAvailable()
      })
  }

  private createHintObjects() {
    //red line in center of screen
    this.scanline = document.createElement("div")
    this.scanline.className = "scanline"
    this.cameraHintsContainer!.appendChild(this.scanline);
  }

  private updateCanvas() {
    //draw on the canvas, since we can't rely on the actual video feed on ios devices
    this.cameraCanvas!.width = this.cameraView!.videoWidth
    this.cameraCanvas!.height = this.cameraView!.videoHeight
    this.cameraCanvas!.getContext("2d")!.drawImage(this.cameraView!, 0, 0);
  }

  private scanVideoForBarcode() {
    this.cameraSensor!.width = this.cameraView!.videoWidth
    this.cameraSensor!.height = this.cameraView!.videoHeight
    this.cameraSensor!.getContext("2d")!.drawImage(this.cameraView!, 0, 0);

    if (!this.currentlyScanning) {
      return
    }

    const codeReader = new BrowserMultiFormatReader();
    codeReader.decodeFromImage(undefined, this.cameraSensor!.toDataURL("image/webp"))
      .then(result => {
        this.scanSuccess(result.getText())
      })
      .catch((e) => {
      })
  }

  private scanSuccess(result: string) {
    this.onSuccess(result)
    this.cameraStop()
  }

  cameraStop() {
    if (!this.cameraView) {
      return
    }

    //clear interval
    if (this.updateInterval) { clearInterval(this.updateInterval) }
    if (this.scanInterval) { clearInterval(this.scanInterval) }

    //shut down video feed from camera
    if (this.cameraView.srcObject !== null) {
      const tracks = (this.cameraView!.srcObject as MediaStream).getTracks()
      tracks.forEach(function (track) {
        track.stop()
      })
    }

    this.cameraView.srcObject = null

    //hide camera interface
    this.cameraBlock!.style.visibility = "hidden"
    this.currentlyScanning = false

    //remove hint objects in case a different set gets used next time (not likely but possible)
    this.removeHintObjects()
  }

  removeLayout() {
    this.cameraStop()

    if (this.cameraBlock) {
      document.body.removeChild(this.cameraBlock)
    }
  }

  private removeHintObjects() {
    if (!this.scanline) {
      return
    }

    this.cameraHintsContainer!.removeChild(this.scanline)
    this.scanline = undefined
  }
}
