import GraphReportDownloadInput from "./models/GraphReportDownloadInput"
import GraphReportSection from "./models/GraphReportSection"
import ResultReport from "./models/ResultReport"
import TextReportDownloadInput from "./models/TextReportDownloadInput"
import TextReportSection from "./models/TextReportSection"
import { ObservableFromFetch } from "./ObservableFromFetch"

export const getGraphReport = (id: number, showPhysicalFirst: boolean) => ObservableFromFetch<ResultReport<GraphReportSection>>(`Results/GraphReport?id=${id}&&showPhysicalFirst=${showPhysicalFirst}`)

export const getTextReport = (id: number, showPhysicalFirst: boolean) => ObservableFromFetch<ResultReport<TextReportSection>>(`Results/TextReport?id=${id}&&showPhysicalFirst=${showPhysicalFirst}`)

export const downloadTextReport = (params: TextReportDownloadInput) => ObservableFromFetch<string>("Results/DownloadTextReport", JSON.stringify(params))
export const downloadGraphReport = (params: GraphReportDownloadInput) => ObservableFromFetch<string>("Results/DownloadGraphReport", JSON.stringify(params))
