import React from "react"
import { useTranslation } from "react-i18next"
import { Col, Row } from "react-bootstrap"
import GraphReportDetail from "../../api/models/GraphReportDetail"
import ShowMoreGroup from "./ShowMoreGroup"
import HowToImproveGroup from "./HowToImproveGroup"
import RangeChart from "./RangeChart"

interface RangeGraphProps {
  detail: GraphReportDetail
}

export default function RangeGraph(props: RangeGraphProps) {
  const { t } = useTranslation()
  const { detail } = props

  return (
    <>
      <Row>
        <Col xs={12} md={6} className="fw-bold text-uppercase mb-2">{detail.name}</Col>
        <Col xs={12} md={6} className="text-xs-left text-md-end">
          {detail.longDescription && <ShowMoreGroup title={detail.name} description={detail.longDescription} />}
          {detail.howToImprove && <HowToImproveGroup title={detail.name} description={detail.howToImprove} />}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}><span className="mx-3">{`${t("results.result")}: ${props.detail.numericResultString || props.detail.numericResult?.toString() || ""} ${props.detail.units || ""}`}</span></Col>
      </Row>
      {props.detail.displayGraph && <Row>
        <Col><RangeChart range={detail.range} numericResult={detail.numericResult} /></Col>
      </Row>}
    </>
  )
}
