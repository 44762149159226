import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { sanitize } from "../../../formatters/Sanitize"

export interface ExpiredBarcodeProps {
  onHide: () => void
}

export default function ExpiredBarcode(props: ExpiredBarcodeProps) {
  const { t } = useTranslation()

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static" onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("registration.barcode.expired.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: sanitize(t("registration.barcode.expired.body", { link: "https://order.crlcorp.com/" })) }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("registration.button.ok")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
