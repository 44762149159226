import * as React from "react";
import { Container } from "react-bootstrap";

export function SubHeader(props: React.PropsWithChildren<{}>) {
  return (
    <div className="sub-header">
      <Container>
        {props.children}
      </Container>
    </div>
  )
}
