import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { sanitize } from "../../../formatters/Sanitize"

export enum MessageType {
  Text,
  Html
}

export interface Message {
  body: string,
  type: MessageType
}


interface MessageModalProps {
  message: Message
  onHide: () => void
}

export default function MessageModal(props: MessageModalProps) {
  const { t } = useTranslation()
  const { message, onHide } = props

  return (<>
    <Modal show size="lg" fullscreen="sm-down" centered backdrop="static" onHide={onHide}>
      <Modal.Body>
        {message.type === MessageType.Html ? <div dangerouslySetInnerHTML={{ __html: sanitize(message.body) }} /> : message.body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t("registration.button.close")}</Button>
      </Modal.Footer>
    </Modal>
  </>)
}
