import React, { useMemo, useRef } from "react"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { Col, Form, Row } from "react-bootstrap"
import ParticipantRequest from "../../api/models/clientorder/ParticipantRequest"
import { useTranslation } from "react-i18next"
import Program from "../../api/models/clientorder/Program"
import DateOfBirth from "../Login/DateOfBirth"
import FormatLocalISODateString from "../../formatters/FormatLocalISODateString"
import PhoneNumber from "../Login/PhoneNumber"

interface ParticipantFormProps {
  program: Program
  request: ParticipantRequest
  onSubmit?: (request: ParticipantRequest) => void
}

export default function ParticipantForm(props: ParticipantFormProps) {
  const { t } = useTranslation()

  const validationSchema = useMemo(() => {
    const fieldRequiredMessage = t("shared.validation.fieldRequired")
    return Yup.object().shape({
      lastName: Yup.string().nullable(true).required(fieldRequiredMessage),
      dateOfBirth: Yup.date().when("showDOB", {
        is: () => props.program.requireDOB,
        then: Yup.date().required(fieldRequiredMessage)
      }),
      email: Yup.string().email().when("showEmail", {
        is: () => props.program.requireEmail,
        then: Yup.string().required(fieldRequiredMessage)
      }),
      mobile: Yup.string().when("showMobile", {
        is: () => props.program.requireMobile,
        then: Yup.string().nullable(true).test("mobile", t("shared.validation.invalidPhoneNumber"), (value, context) => context.parent.phoneValid && !!value)
      }),
      referenceID: Yup.string().when("requireReferenceID", {
        is: () => props.program.requireReferenceID,
        then: Yup.string().required(fieldRequiredMessage)
      }),
      offerCode: Yup.string().when("requireOfferCode", {
        is: () => props.program.requireParticipantOfferCode || props.program.requireProgramOfferCode,
        then: Yup.string().required(fieldRequiredMessage)
      })
    })
  }, [t, props.program])

  const form = useRef<HTMLFormElement>(null)

  const formik = useFormik<ParticipantRequest & { phoneValid: boolean }>({
    initialValues: { ...props.request, phoneValid: true },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmit && props.onSubmit(values)
    }
  })

  const handleBirthDateChange = (date: Date | null) => {
    formik.setFieldValue("dateOfBirth", FormatLocalISODateString(date)).then(() => {
      formik.setFieldTouched("dateOfBirth", true, true)
    })
  }

  const handlePhoneChange = (valid: boolean, mobileNumber: string, country: string) => {
    formik.setFieldValue("phoneValid", valid)
    formik.setFieldTouched("phoneValid", true, true).then(
      () => {
        formik.setFieldValue("mobile", mobileNumber)
        formik.setFieldTouched("mobile", true, true)
        formik.setFieldValue("countryAbbreviation", country)
      }
    )
  }

  return <Form id="participantForm" ref={form} onSubmit={formik.handleSubmit}>
    <Form.Group className="mb-3" controlId="lastName" as={Row}>
      <Col>
        <Form.Label className="fw-bold text-uppercase pt-2">{t(`clientOrder.lastName`)}</Form.Label>
        <Form.Control type="text" name="lastName" maxLength={25}
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName || ""}
          isInvalid={!!formik.errors.lastName && formik.touched.lastName} />
        <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
      </Col>
    </Form.Group>
    {props.program.requireDOB && <Form.Group className="mb-3" controlId="dateOfBirth" as={Row}>
      <Col>
        <DateOfBirth onChange={handleBirthDateChange} initialDate={formik.values.dateOfBirth} title={t(`clientOrder.dateOfBirth`)}
          onSubmit={() => form.current && form.current.dispatchEvent(new Event("submit"))} skipFeedback />
        {!!formik.errors.dateOfBirth && formik.touched.dateOfBirth &&
          <Form.Control.Feedback className="d-block" type="invalid">{t("shared.validation.invalidDate")}</Form.Control.Feedback>}
      </Col>
    </Form.Group>}
    {props.program.requireEmail && <Form.Group className="mb-3" controlId="email" as={Row}>
      <Col>
        <Form.Label className="fw-bold text-uppercase pt-2">{t(`clientOrder.email`)}</Form.Label>
        <Form.Control type="text" name="email" maxLength={100}
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email || ""}
          isInvalid={!!formik.errors.email && formik.touched.email} />
        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
      </Col>
    </Form.Group>}
    {props.program.requireMobile && <Form.Group className="mb-3" controlId="mobile" as={Row}>
      <Col>
        <PhoneNumber
          title={t("clientOrder.mobilePhoneNumber")}
          onChange={handlePhoneChange} skipFocus skipFeedback />
        {!!formik.errors.mobile && formik.touched.mobile
          && <Form.Control.Feedback className="d-block" type="invalid">{formik.errors.mobile}</Form.Control.Feedback>}
      </Col>
    </Form.Group>}
    {props.program.requireReferenceID && <Form.Group className="mb-3" controlId="referenceID" as={Row}>
      <Col>
        <Form.Label className="fw-bold text-uppercase pt-2">{props.program.referenceIdLabel || t(`clientOrder.referenceID`)}</Form.Label>
        <Form.Control type="text" name="referenceID" maxLength={50}
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.referenceID || ""}
          isInvalid={!!formik.errors.referenceID && formik.touched.referenceID} />
        <Form.Control.Feedback type="invalid">{formik.errors.referenceID}</Form.Control.Feedback>
      </Col>
    </Form.Group>}
    {(props.program.requireParticipantOfferCode || props.program.requireProgramOfferCode) && <Form.Group className="mb-3" controlId="offerCode" as={Row}>
      <Col>
        <Form.Label className="fw-bold text-uppercase pt-2">{t(`clientOrder.offerCode`)}</Form.Label>
        <Form.Control type="text" name="offerCode" maxLength={50}
          onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.offerCode || ""}
          isInvalid={!!formik.errors.offerCode && formik.touched.offerCode} />
        <Form.Control.Feedback type="invalid">{formik.errors.offerCode}</Form.Control.Feedback>
      </Col>
    </Form.Group>}
  </Form>
}
