import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useIdleTimer } from 'react-idle-timer'
import FormatMilliseconds from "../formatters/FormatMilliseconds"
import { useAuthStore } from "../stores/StoreProvider"

const TIMEOUT: number = 1000 * 60 * 8
const PROMPT_TIMEOUT: number = 1000 * 60 * 2
const TOTAL_TIMEOUT = TIMEOUT + PROMPT_TIMEOUT

export default function IdleTimer() {
  const authStore = useAuthStore()
  const { t } = useTranslation()
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [remainingTime, setRemainingTime] = useState<number>(0)
  const started = useRef(Date.now())
  const promptTimer = useRef<number | undefined>(undefined)
  const promptStarted = useRef<number | undefined>(undefined)

  useEffect(() => () => destroyPromptTimer(), [promptTimer])

  const destroyPromptTimer = () => {
    if (promptTimer.current !== undefined) {
      window.clearTimeout(promptTimer.current)
      promptTimer.current = undefined
      promptStarted.current = undefined
    }
  }

  const onPrompt = () => {
    destroyPromptTimer()
    promptTimer.current = window.setTimeout(onIdle, PROMPT_TIMEOUT)
    promptStarted.current = Date.now()
    setShowPrompt(true)
  }

  const onIdle = useCallback(() => {
    setShowPrompt(false)
    authStore.logout()
  }, [authStore])

  const onAction = (e?: Event) => {
    if (e?.type === "visibilitychange") {
      const diff = Date.now() - started.current
      if (diff < TOTAL_TIMEOUT && diff > TIMEOUT) {
        pause()
        onPrompt()
        return
      }
      if (diff > TOTAL_TIMEOUT) {
        pause()
        onIdle()
        return
      }
      reset()
    }
    if (showPrompt) return

    started.current = Date.now()
  }

  const { reset, pause } = useIdleTimer({
    onIdle: onPrompt,
    onAction,
    timeout: TIMEOUT,
    stopOnIdle: true
  })

  const onContinue = () => {
    destroyPromptTimer()
    started.current = Date.now()
    reset()
    setShowPrompt(false)
  }

  const processRemainingTime = useCallback(() => {
    if (!promptStarted.current) {
      return
    }
    const diff = Date.now() - promptStarted.current
    const remainingTime = PROMPT_TIMEOUT - diff
    if (remainingTime < 0) {
      onIdle()
      return
    }
    setRemainingTime(remainingTime)
  }, [promptStarted, onIdle])

  useEffect(() => {
    if (!showPrompt) {
      return
    }

    processRemainingTime()

    const interval = window.setInterval(() => {
      processRemainingTime()
    }, 500)

    return () => window.clearInterval(interval)
  }, [showPrompt, processRemainingTime])

  return <>
    <Modal backdrop="static" show={showPrompt} centered>
      <Modal.Header>
        <Modal.Title>{t("idlePrompt.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("idlePrompt.line1")}</p>
        <p>{t("idlePrompt.line2", { duration: FormatMilliseconds(remainingTime) })}</p>
        <p>{t("idlePrompt.line3")} <b>{t("idlePrompt.button")}</b>.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onContinue}>{t("idlePrompt.button")}</Button>
      </Modal.Footer>
    </Modal>
  </>
}