import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Implementation } from "../../api/models/registration/Implementation"
import { activate } from "../../api/Registration"
import Activation from "./Activation"

export interface ShippingProps {
  title: string
  implementation?: Implementation
  businessType?: string
  originalBarcode: string
  firstName?: string
  lastName?: string
  testEventId: number
  testEventHash: string
  onNext: () => void
}

export default function Shipping(props: ShippingProps) {
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleActivationNext = () => {
    setSubmitting(true)

    activate({ testEventId: props.testEventId, testEventHash: props.testEventHash, implementation: props.implementation }).subscribe({
      next: () => {
        setSubmitting(false)
        props.onNext()
      },
      error: e => {
        setSubmitting(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  return <>
    <Activation {...props} onNext={handleActivationNext} submitting={submitting} />
  </>
}