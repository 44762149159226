import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { Download } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import GraphReportDownloadInput from "../../api/models/GraphReportDownloadInput"
import TestEvent from "../../api/models/TestEvent"
import { APIError } from "../../api/ObservableFromFetch"
import { downloadGraphReport } from "../../api/Results"
import FormatDate from "../../formatters/FormatDate"
import printPdf from "../../utils/PrintPdf"
import ApexCharts from "apexcharts"
import GraphReportSection from "../../api/models/GraphReportSection"
import GraphReportImage from "../../api/models/GraphReportImage"
import GraphReportDetail from "../../api/models/GraphReportDetail"
import { Subscription } from "rxjs"

const downloadTranslations = ["result", "laboratoryReport", "labSlipId", "sid", "name", "gender", "collected", "reportedDate"]

export interface RangeReportDownloadProps {
  testEvent: TestEvent
  showPhysicalFirst: boolean
  sections?: GraphReportSection[]
}

export default function RangeReportDownload({ testEvent, showPhysicalFirst, sections }: RangeReportDownloadProps) {
  const { t } = useTranslation()
  const [downloading, setDownloading] = useState<boolean>(false)
  const subsriptionRef = useRef<Subscription | undefined>(undefined)
  const downloadContainer = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    return () => subsriptionRef.current?.unsubscribe()
  }, [subsriptionRef])

  const generateImages = async () => {
    const parent = downloadContainer.current

    const details = sections?.reduce((result: GraphReportDetail[], current: GraphReportSection) => {
      if (!current.physical) {
        result.push(...current.details)
      }
      return result
    }, []) || []

    const images: GraphReportImage[] = []
    for (let index = 0; index < details.length; index++) {
      const testCode = details[index].testCode
      const chart = ApexCharts.getChartByID(testCode)
      if (chart) {
        const opts = (chart as any).opts as ApexCharts.ApexOptions
        const chartOpts = { ...opts.chart, animations: { enabled: false } }
        const doc = document.createElement("div")

        parent.appendChild(doc)
        const ac = new ApexCharts(doc, { ...opts, chart: chartOpts })
        await ac.render()

        var image = await ac.dataURI({ scale: 2 }) as { imgURI: string }
        if (image?.imgURI) {
          images.push({ testCode: testCode, dataUrl: image.imgURI })
        }

        parent.removeChild(doc)
      }
    }

    return images
  }

  const clickDownload = async () => {
    if (downloading) {
      return
    }
    setDownloading(true)

    const images = await generateImages();
    var params: GraphReportDownloadInput = {
      id: testEvent.testEventId, showPhysicalFirst, images,
      translations: downloadTranslations.map((key) => ({ key: `results.${key}`, value: t(`results.${key}`) }))
    }

    if (subsriptionRef.current) {
      subsriptionRef.current.unsubscribe()
    }
    subsriptionRef.current = downloadGraphReport(params).subscribe({
      next: (result: string) => {
        setDownloading(false)
        printPdf(result, `GraphLabTestResults-${FormatDate(testEvent.dateOfRegistration, '-')}`)
      },
      error: (e: APIError) => {
        setDownloading(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
  }

  return <Row className="justify-content-end">
    <Col className="col-auto">
      {downloading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      <Button variant="" title={t("results.download")} onClick={clickDownload}>
        <Download size={24} className="mb-1" />
      </Button>
      <div ref={downloadContainer} className="download-container"></div>
    </Col>
  </Row>
}
