import * as React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../AppRoute";
import rootStore from "../../stores/RootStore"
import { useAuthStore } from "../../stores/StoreProvider";
import { useLocation } from 'react-router-dom'

interface NavMenuProps {
  nav?: boolean
}

export function NavMenu(props: NavMenuProps) {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const location = useLocation();
  const { pathname } = location;

  const useLifeAgeCalculator = authStore.domain?.useLifeAgeCalculator ?? false
  const allowKitRegistration = authStore.domain?.allowKitRegistration ?? false
  const allowOrderTesting = authStore.domain?.allowOrderTesting ?? false

  const handleLogout = () => {
    rootStore.authStore.logout()
    return true
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container className="justify-content-end">
        {props.nav && <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse role="" id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              {allowKitRegistration && <Nav.Link as={Link} className="text-end" active={pathname === AppRoute.Registration} to={AppRoute.Registration}>{t("menu.registration")}</Nav.Link>}
              <Nav.Link as={Link} className="text-end" active={pathname === AppRoute.Results} to={AppRoute.Results}>{t("menu.results")}</Nav.Link>
              {useLifeAgeCalculator && <Nav.Link as={Link} className="text-end" active={pathname === AppRoute.LifeAge} to={AppRoute.LifeAge}>{t("menu.lifeAge")}</Nav.Link>}
              {allowOrderTesting && <Nav.Link as={Link} className="text-end" active={pathname === AppRoute.OrderTesting} to={AppRoute.OrderTesting}>{t("menu.orderTesting")}</Nav.Link>}

              <Dropdown as={Nav.Item} align="end">
                <Dropdown.Toggle as={Nav.Link} className="text-end">{t("login.language")}</Dropdown.Toggle>
                <Dropdown.Menu variant="dark">
                  <Dropdown.Item className="text-end" onClick={() => authStore.changeLanguage("en")}>{t("menu.language.en")}</Dropdown.Item>
                  <Dropdown.Item className="text-end" onClick={() => authStore.changeLanguage("es")}>{t("menu.language.es")}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link as={Link} className="text-end" to={AppRoute.Login} onClick={handleLogout}>{t("menu.logout")}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>}
      </Container>
    </Navbar>
  )
}
