import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { InfoCircle } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import ReportInfo from "./ReportInfo"

export interface ShowMoreGroupProps {
  title: string
  description: string
}

export default function ShowMoreGroup(props: ShowMoreGroupProps) {
  const { t } = useTranslation()
  const [showMoreModal, setShowMoreModal] = useState(false)
  
  return (<>
    <Button variant="secondary" className="m-1" onClick={() => setShowMoreModal(true)}><InfoCircle /> {t("results.clickForMore")}</Button>
    <ReportInfo
      show={showMoreModal}
      title={t("results.moreFor", { name: props.title })}
      description={props.description}
      handleClose={() => setShowMoreModal(false)} />
  </>)
}
