import React, { useEffect, useRef } from "react"
import { TFunction, useTranslation } from "react-i18next"

export const useTranslationWithRef = (): [React.MutableRefObject<TFunction<"translation", undefined>>, TFunction<"translation", undefined>] => {
  const { t } = useTranslation()
  const ref = useRef(t)

  useEffect(() => {
    ref.current = t
  }, [t])

  return [ref, t]
}
